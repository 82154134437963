<form [formGroup]="repassesService.search" (ngSubmit)="submit()">
  <mat-grid-list
    cols="26"
    rowHeight="150px"
    gutterSize="15"
    style="width: 100%"
  >
    <mat-grid-tile colspan="2">
      <div class="full-width">
        <select-icao
          [externalControl]="repassesService.search.controls.sigla_icao"
        />
      </div>
    </mat-grid-tile>

    <mat-grid-tile colspan="3">
      <mat-form-field class="full-width" style="position: absolute; top: 0">
        <mat-label>Ident. do vôo</mat-label>

        <input
          data-cy="search-identificacao-input"
          type="text"
          matInput
          formControlName="identificacao"
        />
      </mat-form-field>

      <mat-form-field class="full-width" style="position: absolute; top: 70px">
        <mat-label>Matrícula</mat-label>

        <input
          data-cy="search-matricula-input"
          type="text"
          matInput
          formControlName="matricula"
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="5">
      <simple-datepicker
        style="position: absolute; top: 0"
        label="Operação desde"
        class="full-width"
        [blockPastDays]="false"
        [externalControl]="repassesService.search.controls.operacao_desde"
      />

      <simple-datepicker
        style="position: absolute; top: 70px"
        label="Operação até"
        class="full-width"
        [blockPastDays]="false"
        [externalControl]="repassesService.search.controls.operacao_ate"
      />
    </mat-grid-tile>

    <mat-grid-tile colspan="5">
      <simple-datepicker
        style="position: absolute; top: 0"
        label="Data de referência de"
        class="full-width"
        [blockPastDays]="false"
        [externalControl]="repassesService.search.controls.data_referencia_de"
      />

      <simple-datepicker
        style="position: absolute; top: 70px"
        label="Data de referência até"
        class="full-width"
        [blockPastDays]="false"
        [externalControl]="repassesService.search.controls.data_referencia_ate"
      />
    </mat-grid-tile>

    <mat-grid-tile colspan="11">
      <mat-radio-group
        style="position: absolute; top: -8px; left: 0;"
        class="full-width"
        color="primary"

      >
        <mat-radio-button
          style="position: absolute; top: 0; left: 0"
          value="Todos os movimentos"
          (change)="changeStatusRepasse('todos')"
        >
          Todos os movimentos
        </mat-radio-button>

        <mat-radio-button
          style="position: absolute; top: 40px; left: 0"
          value="Repasses sem baixa"
          (change)="changeStatusRepasse('sem_baixa')"
        >
          Repasses sem baixa
        </mat-radio-button>

        <mat-radio-button
          style="position: absolute; top: 0; left: 190px"
          value="Sem repasse"
          (change)="changeStatusRepasse('sem_repasse')"
        >
          Sem repasse
        </mat-radio-button>

        <mat-radio-button
          style="position: absolute; top: 40px; left: 190px"
          value="Repasses baixados"
          (change)="changeStatusRepasse('baixados')"
        >
          Repasses baixados
        </mat-radio-button>
      </mat-radio-group>

      <table class="full-width" style="position: absolute; top: 67px; ">
        <tr>
          <td style="width: 150px !important">
            <mat-form-field style="width: 350px">
              <mat-label>Código de repasse específico</mat-label>
              <input
                data-cy="search-identificacao-input"
                type="text"
                matInput
                formControlName="cod_repasse"
                maxlength="7"
                (input)="onlyNumbers($event)"
              />
            </mat-form-field>
          </td>
        </tr>
      </table>

      <table class="full-width" style="position: absolute; left: 400px; top: 0">
        <tr>
          <td style="width: 150px !important">
            <submit-button
            style="position: absolute; top: 20px"
            label="Buscar"
            icon="search"
          />
          </td>
        </tr>
        <tr>
          <td style="width: 150px !important">
            <clear-filter-button
            style="position: absolute; top: 95px"
            (click)="clearSearch()"
          />
          </td>
        </tr>
      </table>
    </mat-grid-tile>
  </mat-grid-list>
</form>
