import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {DatArrecadacaoTarifasPdf} from "../../pdfs/dat-arrecadacao-tarifas-pdf/dat-arrecadacao-tarifas-pdf.component";
import {IconButton} from "../../buttons/icon-button/icon-button.component";
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardContent} from "@angular/material/card";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {MatIcon} from "@angular/material/icon";
import {MatPaginator} from "@angular/material/paginator";
import {GeneralService} from "../../../services/general-service";
import {formatDateAndTime, toBrazilianDate} from "../../../services/date-service";
import {MatCheckbox} from "@angular/material/checkbox";
import MovesService from "../../../services/moves-service";
import {paddingZeros} from "../../../services/data-transform";
import {SelectMoveCheckboxComponent} from "../../checkboxes/select-move-checkbox/select-move-checkbox.component";
import {UsersService} from "../../../services/users-service";

@Component({
  selector: 'grus-table',
  standalone: true,
  imports: [
    DatArrecadacaoTarifasPdf,
    IconButton,
    MatButton,
    MatCard,
    MatCardContent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatNoDataRow,
    MatCheckbox,
    SelectMoveCheckboxComponent
  ],
  templateUrl: './grus-table.component.html',
  styleUrl: '../moves-table/moves-table.component.scss'
})
export class GrusTableComponent implements AfterViewInit {
  protected dataSource = new MatTableDataSource<{}>(this.movesServices.list);

  protected hideCheckbox = false;

  protected displayedColumns: string[] = [
    'icao',
    'identificacao',
    'dados_da_aeronave',
    'dados_arr',
    'dados_dep',
    'repasse',
    'actions',
  ];

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected generalService: GeneralService,
    protected usersService: UsersService,
    public movesServices: MovesService,
  ) {
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  protected formatDate(date: string) {
    return formatDateAndTime(date);
  }

  protected readonly paddingZeros = paddingZeros;
  protected readonly toBrazilianDate = toBrazilianDate;

  protected selectedMovesChanged(refresh: boolean) {
    if (refresh) {
      this.hideCheckbox = true;

      setTimeout(() => {
        this.hideCheckbox = false;
      }, 1);
    }
  }
}
