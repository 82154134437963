import { Component, OnInit } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatButton } from "@angular/material/button";
import { MatDialog, MatDialogActions } from "@angular/material/dialog";
import { MatInput } from "@angular/material/input";

import { LocalesService } from "../../../services/locales-service";
import { GeneralService } from "../../../services/general-service";
import { SimpleDatepickerComponent } from "../../datepickers/simple-datepicker.component";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import { toISO8601 } from "../../../services/date-service";
import { LocaleTaxesService } from "../../../services/locale-taxes-service";
import { numbersAndCommas } from "../../../services/data-transform";
import { MatCheckbox } from "@angular/material/checkbox";
import {
  CancellationConfirmationDialog
} from "../../dialogs/cancellation-confirmation-dialog/cancellation-confirmation-dialog.component";
import { MatIcon } from '@angular/material/icon';
import { SharedModule } from '../../../shared/modules/shared.module';

@Component({
  selector: 'locale-tax-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatGridList,
    MatGridTile,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    SimpleDatepickerComponent,
    SubmitButtonComponent,
    MatButton,
    MatDialogActions,
    MatInput,
    MatCheckbox,
    MatIcon,
    SharedModule
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './locale-tax-form.component.html',
  styleUrl: './locale-tax-form.component.scss'
})

export class LocaleTaxFormComponent implements OnInit {
  saving = false;

  constructor(
    protected generalService: GeneralService,
    private localeService: LocalesService,
    protected localeTaxesService: LocaleTaxesService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.localeTaxesService.data.patchValue({
      id_localidade: this.localeService.locale.value.id_localidade,
    })
  }

  protected numbersAndCommas() {
    const { value } = this.localeTaxesService.data.controls.percentual_taxa;

    this.localeTaxesService.data.patchValue({ percentual_taxa: numbersAndCommas(value || '') });
  }

  protected async trySubmitting() {
    if (this.localeService.locale.value.id_localidade) {
      await this.submit();
    } else {
      if (
        this.localeTaxesService.data.status === 'VALID'
        && this.localeTaxesService.data.value.data_vigencia
      ) {
        if (this.localeTaxesService.data.value.propagar_taxa) {
          this.openCancelDialog();
        } else {
          await this.sendData();
        }
      }
    }
  }

  private async sendData() {
    try {
      if (this.localeTaxesService.data.value.data_vigencia) {
        // locale already created
        if (this.localeService.locale.value.id_localidade) {
          // check if locale tax exists at the same date...
          if (await this.localeTaxesService.taxValidityExistsInLocale({
            id: this.localeTaxesService.data.value.id || 0,
            id_localidade: this.localeTaxesService.data.value.id_localidade || 0,
            percentual_taxa: this.localeTaxesService.data.value.percentual_taxa || '',
            data_vigencia: toISO8601(this.localeTaxesService.data.value.data_vigencia),
          })) {
            this.generalService.notify('A taxa / vigência já foi cadastrada para esta localidade', 'negative');
          } else if (this.localeTaxesService.data.value.id) {
            await this.localeTaxesService.update();
            this.localeTaxesService.clearForm();
          } else {
            await this.localeTaxesService.create();
          }
        } else {
          // locale is going to be created...
          this.saving = true;

          this.localeTaxesService.storeTaxesBeforeCreatingLocale();

          setTimeout(() => {
            this.saving = false;
          }, 500);
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  protected async submit() {
    try {
      this.saving = true;

      if (
        this.localeTaxesService.data.status === 'VALID'
        && this.localeTaxesService.data.value.data_vigencia
      ) {
        if (this.localeTaxesService.data.value.propagar_taxa) {
          this.openCancelDialog();
        } else {
          await this.sendData();
        }
      } else {
        this.saving = false;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.saving = false;
      }, 500);
    }
  }

  openCancelDialog(): void {
    const dialogRef = this.dialog.open(CancellationConfirmationDialog, {
      data: {
        title: 'Propagar taxa / vigência',
        question: 'Tem certeza que deseja propagar?',
        warning: 'A taxa / vigência será aplicada para a todas as localidades.',
      },
    })

    dialogRef.afterClosed().subscribe(async (propagate: boolean) => {
      if (propagate) {
        await this.sendData();
      }
    })
  }
}
