import { Directive, ElementRef, HostListener } from '@angular/core';
import {onlyNumbers} from "../../services/data-transform";

@Directive({
  selector: '[mask-time]',
})
export class MaskTimeDirective {
  constructor(private el: ElementRef) {}

  // allow numbers and ":"
  @HostListener('input', ['$event'])
  onKeyup($event: any) {
    const colonIndex = this.el.nativeElement.value.indexOf(":");

    let value: string = onlyNumbers(this.el.nativeElement.value);

    if (colonIndex !== -1) {
      const numbersAfterColon = value.substring(colonIndex, value.length);

      value = `${value.substring(0, colonIndex)}:${numbersAfterColon.substring(0, 2)}`;
    } else if (value.length === 4)  {
      value = `${value.slice(0, 2)}:${value.slice(2, value.length)}`;
    }

    this.el.nativeElement.value = value;
  }
}
