import { Injectable } from '@angular/core';

import { UsersService } from './users-service';

@Injectable()
export class FuncionalidadesService {
  constructor(private _userService: UsersService) {}

  get funcionalidades() {
    return this._userService.user?.funcionalidades ?? [];
  }

  public canAccess(featureId: number, readOnly = true) {
    if (this.funcionalidades.length <= 0) return false;

    return this.funcionalidades.some(
      (f) => f.id == featureId && (readOnly || f.read_only == readOnly)
    );
  }
}
