import { Component } from '@angular/core';
import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import MovesService from "../../../services/moves-service";
import { GeneralService } from "../../../services/general-service";
import { CreateGruDialogComponent } from "../../dialogs/create-gru-dialog/create-gru-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { LocalesService } from "../../../services/locales-service";
import { GeneralRemoveDialog } from "../../dialogs/general-remove-dialog/general-remove-dialog.component";
import { paddingZeros } from "../../../services/data-transform";
import { RepassesService } from "../../../services/repasses-service";
import { LocaleTaxesService } from "../../../services/locale-taxes-service";

@Component({
  selector: 'grus-actions-buttons-bar',
  standalone: true,
  imports: [
    MatGridList,
    MatGridTile,
    MatButton,
    MatIcon,
    MatProgressSpinner,
  ],
  templateUrl: './grus-actions-buttons-bar.component.html',
  styleUrl: './grus-actions-buttons-bar.component.scss'
})
export class GrusActionsButtonsBarComponent {
  protected generatingGRU = false;
  protected removingGRU = false;

  constructor(
    protected generalService: GeneralService,
    protected localesServices: LocalesService,
    protected localeTaxesServices: LocaleTaxesService,
    protected movesService: MovesService,
    protected grusService: RepassesService,
    public dialog: MatDialog,
  ) {
  }

  protected startRemovingGRU() {
    try {
      this.removingGRU = true;

      if (this.movesService.selectedMoves.length) {
        if (this.movesService.hasMoreThanOneRepass()) {
          this.generalService.notify(
            'Só é possível excluir um repasse de cada vez – reveja sua seleção.',
            'negative',
          );

          this.removingGRU = false;
        } else if (this.movesService.notRepassedMoves()) {
          this.generalService.notify(
            'Há movimento(s) selecionado(s) não repassados – reveja sua seleção.',
            'negative',
          );

          this.removingGRU = false;
        } else {
          const codRepasse = this.movesService.getRemoveCodRepasse();

          this.openRemoveDialog(codRepasse);

          this.removingGRU = false;
        }
      } else {
        this.generalService.notify(
          'Selecione um repasse para exclusão.',
          'negative',
        );

        this.removingGRU = false;
      }
    } catch (error) {
      this.generalService.notify(
        'Ocorreu um erro ao remover o repasse',
        'negative',
      );
    }
  }

  protected async startGeneratingGRU() {
    try {
      this.generatingGRU = true;

      if (this.movesService.selectedMoves.length) {
        const localeICAO = this.movesService.getLocaleFromSelectedMoves();

        if (!localeICAO) {
          this.generalService.notify(
            'Selecione movimentos de somente uma localidade para compor o repasse.',
            'negative',
          );

          this.generatingGRU = false;
        } else if (await this.movesService.hasSelectedMovesAlreadyRepassed()) {
          this.generalService.notify(
            'Movimento(s) selecionado(s) já repassado(s) – reveja sua seleção.',
            'negative',
          );

          this.generatingGRU = false;
        } else {
          const locale = await this.localesServices.getLocaleByICAO(localeICAO);

          if (locale) {

            const localeTax = await this.localeTaxesServices.getCurrentLocaleTax(locale.id_localidade);

            if (!localeTax) {
              this.generalService.notify(
                'Localidade sem taxa vigente.',
                'negative',
              );
              this.generatingGRU = false;
              return;
            }

            await this.movesService.calculateRepass();

            setTimeout(() => {
              const dialogRef = this.dialog.open(CreateGruDialogComponent, {
                data: {
                  repass: this.movesService.newRepasseToBeGenerated,
                  localeTax,
                },
              });

              dialogRef.afterClosed().subscribe(async (reloadGRUsList) => {
                if (reloadGRUsList) {
                  await this.movesService.getMoves(true);
                }
              });

              this.generatingGRU = false;
            }, 1000);
          }
        }
      } else {
        this.generalService.notify(
          'Selecione pelo menos um movimento para compor o repasse.',
          'negative',
        );

        this.generatingGRU = false;
      }
    } catch (error) {
      this.generalService.notify(
        'Ocorreu um erro ao calcular o repasse',
        'negative',
      );
    }
  }

  openRemoveDialog(codRepasse: string) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'o repasse',
        id: codRepasse,
        name: `Código ${paddingZeros(codRepasse, 6)}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (codRepasse) => {
      if (codRepasse) {
        try {
          const result = await this.grusService.delete(codRepasse);

          if (result.error == true) {
            this.generalService.notify(result.messagens.join(" - "), 'negative');

            return;
          }

          this.generalService.notify('Repasse removido', 'positive');

          this.grusService.clearSearch();
          this.movesService.resetMovesRepasse();
          await this.movesService.getAllMoves(true);
        } catch {
          this.generalService.notify('Occoreu um erro ao tentar remover o repasse', 'negative');
        }
      }
    });
  }
}
