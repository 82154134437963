<div #content>
  @if (generalService.generalLoading) {
  <div class="updating-version">
    <div>
      <div style="display: flex; justify-content: center; padding: 15px">
        <mat-spinner diameter="30" class="mat-spinner-white"></mat-spinner>
      </div>

      <div>{{ generalService.generalLoadingLabel }}</div>
    </div>
  </div>
  }

  <div id="main-layout">
    <button class="toggle-menu-button" (click)="toggleMenu()">
      <mat-icon>{{ isMenuOpen ? 'menu_open' : 'menu' }}</mat-icon>
    </button>

    <div id="top-layout-bar">
      <div data-cy="logged-username" class="dropdown" (mouseleave)="fecharDropdown()">
        <a (click)="toggleDropdown($event)" style="display: flex; align-items: center">
          <mat-icon class="icon">person</mat-icon>
          <span>{{ _userService.user.nome }}</span>
        </a>
        <div class="dropdown-content" [style.display]="dropdownAberto ? 'block' : 'none'">
          <a routerLink="/groups/user-settings" [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="active-link" *ngIf="canAccessSettings">
            <mat-icon class="menu-icon">settings</mat-icon> Configurações Pessoais
          </a>
          <a href="#" (click)="close()">
            <mat-icon class="menu-icon">exit_to_app</mat-icon> Sair
          </a>
        </div>
      </div>

      <h3 class="page-title" id="page-title">{{ generalService.fullTitle }}</h3>


    </div>

    <div id="left-layout-panel" [class.closed]="!isMenuOpen">
      @if(this.isMenuOpen){
      <div class="app-version">
        <a class="app-version-text">
          <mat-icon class="menu-icon">info</mat-icon>
          <span class="menu-item-text">Versão {{ version }}</span>
        </a>
        <a class="app-version-text">
          <mat-icon class="menu-icon">schedule</mat-icon>
          <span class="menu-item-text">Tempo da sessão {{ tempoExp }}</span>
        </a>
      </div>
      }@else {
      <div ngif="!this.isMenuOpen" class="app-version-closed">
        <a class="app-version-text">
          <div class="tooltip">
            <mat-icon class="menu-icon">info</mat-icon>
            <span class="tooltiptext">Versão {{ version }}</span>
          </div>
        </a>
        <a class="app-version-text">
          <div class="tooltip">
            <mat-icon class="menu-icon">schedule</mat-icon>
            <span class="tooltiptext">Tempo da sessão {{ tempoExp }}</span>
          </div>
        </a>
      </div>
      }

      <div class="main-menu" [class.closed]="!isMenuOpen">
        @for (menu of menuService.list; track menu) {
        <div class="main-menu-section-name pai" (click)="toggleSection(menu.id_menu.toString())">
          <div class="menu-item-content">
            <span class="material-icons toggle-icon">
              {{ isSectionOpen(menu.id_menu.toString()) ? 'expand_more' : 'chevron_right' }}
            </span>
            <span class="menu-item-text">{{ menu.nome_menu }}</span>
          </div>
        </div>
        <div class="submenu" *ngIf="isSectionOpen(menu.id_menu.toString())">
          @for (subMenu of menu.sub_menus; track subMenu) {
          <a [routerLink]="subMenu.rota" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active-link"
            class="main-menu-item">
            @if(!this.isMenuOpen){
            <div class="tooltip">
              <mat-icon class="menu-icon">{{ subMenu.icone }}</mat-icon>
              <span class="tooltiptext">{{ subMenu.nome_sub_menu }}</span>
            </div>
            }@else{
            <mat-icon class="menu-icon">{{ subMenu.icone }}</mat-icon>
            }
            <span class="menu-item-text">{{ subMenu.nome_sub_menu }}</span>
          </a>
          }
        </div>
        }
      </div>
    </div>

    <div id="right-layout-panel">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>