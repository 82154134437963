import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { SharedModule } from '../../../shared/modules/shared.module';
import { IMove } from '../../../interfaces/move-interfaces';
import { toBrazilianDate } from '../../../services/date-service';
import { LocalesService } from '../../../services/locales-service';
import { ComboService } from '../../../services/combo-service';
import { UsersService } from '../../../services/users-service';
import { ICheck } from '../../../interfaces/check-interface';
import { DatService } from '../../../services/dat-service';

@Component({
  selector: 'dat-arrecadacao-tarifas-pdf',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dat-arrecadacao-tarifas-pdf.component.html',
  styleUrls: ['./dat-arrecadacao-tarifas-pdf.component.css'],
})
export class DatArrecadacaoTarifasPdf {
  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    public _comboService: ComboService,
    protected _userService: UsersService,
    protected _datService: DatService
  ) {}

  // @ts-ignore
  @ViewChild('pdfContent') pdfContent: ElementRef;
  protected move: IMove = {} as IMove;
  protected dateGenerated: Date = new Date();
  protected timeout: any;

  get nroDAT() {
    return `${this.move.icao}${this.move.nr_seq}`;
  }

  get aeroporto() {
    const localidade = this._userService.user.localidades[0] ?? 0;
    return `${this.move.icao} - ${this.localidades[localidade]}`;
  }

  protected formatDate(date: string) {
    return toBrazilianDate(date);
  }

  get localidades(): Record<number, string> {
    var locales = this._comboService.localidadesAll;

    return locales.reduce((acc: Record<number, string>, l) => {
      acc[l.id as number] = l.value;
      return acc;
    }, {} as Record<number, string>);
  }

  generatePDF(move: any) {
    this.move = move;
    this.dateGenerated = new Date();

    // Atualizando html
    this.cdr.detectChanges();

    // Exibir o conteúdo temporariamente
    this.renderer.setStyle(this.pdfContent.nativeElement, 'display', 'block');

    const data = this.pdfContent?.nativeElement;
    html2canvas(data)
      .then((canvas) => {
        const imgWidth = 190; // Largura A4 em mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        pdf.setProperties({
          title: this.nroDAT,
          subject: 'Geração de DAT',
          author: 'DECEA',
          creator: 'Portal Localidades',
        });

        let position = 10;

        // Adiciona a primeira página
        pdf.addImage(
          contentDataURL,
          'PNG',
          position,
          position,
          imgWidth,
          imgHeight
        );

        const pdfURL = pdf.output('bloburl');

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this._datService.getMoves(), 100);

        let iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        //@ts-ignore
        iframe.src = pdfURL;

        iframe.onload = function () {
          iframe?.contentWindow?.print();
        };
      })
      .finally(() => {
        this.renderer.setStyle(
          this.pdfContent.nativeElement,
          'display',
          'none'
        );
      });
  }
}
