<mat-card style="min-width: 350px" class="container-card">
  <mat-card-header class="header-date-tarrif">
    <mat-card-title>Vigências da tarifa</mat-card-title>
  </mat-card-header>

  <mat-divider></mat-divider>

  <mat-card-content>
    <mat-list role="listbox">
      <mat-list-item *ngFor="let vigencia of localesTariffsService.listVigenciasDates">
        <mat-grid-list cols="8" rowHeight="80px" gutterSize="15">
          <mat-grid-tile colspan="2">
            <mat-icon *ngIf="vigencia.current" aria-hidden="false" aria-label="arrow" fontIcon="arrow_forward"
              [color]="'warn'" class="icon-left" (click)="arrowClicked(vigencia)"
              matTooltip="Vigência Atual"></mat-icon>
          </mat-grid-tile>
          <mat-grid-tile colspan="4">
            <span [ngClass]="
                'text-center ' +
                (dateIdSelected == vigencia.idTariffVigencia
                  ? 'date-vigencia-selected'
                  : '')
              " (click)="dateClicked(vigencia)" style="cursor: pointer" matTooltip="Clique para mais detalhes">
              {{ localesTariffsService.formatDate(vigencia.date) }}
            </span>
          </mat-grid-tile>
          <mat-grid-tile colspan="2">
            <icon-button *ngIf="!vigencia.past && !readOnly" [iconName]="'close'" color="warn"
              (click)="deleteClicked($event, vigencia)" [matTooltip]="'Excluir vigência'" [style.cursor]="'pointer'"
              type="button">
            </icon-button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-list-item>
    </mat-list>

    <mat-paginator (page)="handlePageEvent($event)" showFirstLastButtons hidePageSize [pageSize]="5" />
  </mat-card-content>
</mat-card>