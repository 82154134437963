<div style="width:550px">
  <div class="header-dialog">
    <h2 class="title-dialog" mat-dialog-title>Classes e vigências</h2>
    @if (!localeClassService.loading) {
    <icon-button [iconCssClass]="'close-icon-dialog'" [btnCssClass]="'close-button-dialog'" iconName="close"
      (click)="closeDialog()" />
    }
  </div>

  <mat-dialog-content>
    @if (localeClassService.clearingForm) {
    <div class="full-width" style="display:flex;justify-content:center;padding:60px">
      <mat-spinner diameter="24" />
    </div>
    } @else {
    @if (!this.localesService.isReadonly) {
    <locale-class-form />
    }
    }

    <div style="min-height:270px">
      @if (!localesService.locale.value.id_localidade
      && !localeClassService.hasCurrentClassToBeCreated()) {
      <div style="color:red;font-size:13px;text-align:center;line-height:18px;padding-top:20px">
        É necessário que a localidade possua uma classe com data de vigência ativa, a despeito do eventual cadastro de
        datas futuras
      </div>
      }

      @if (localeClassService.loading) {
      <div class="full-width" style="display:flex;justify-content:center;padding-top:120px">
        <mat-spinner diameter="24" />
      </div>
      } @else if (localesService.locale.value.id_localidade) {
      <locale-classes-table />
      } @else {
      <locale-classes-creating-table />
      }
    </div>
  </mat-dialog-content>
</div>