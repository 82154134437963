import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from "@angular/material/button";
import { ReactiveFormsModule } from "@angular/forms";
import { MatError, MatFormField, MatInput, MatLabel } from "@angular/material/input";
import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";

import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle
} from "@angular/material/card";

import { LocalesService } from "../../../services/locales-service";
import { GeneralService } from "../../../services/general-service";
import {
  FilteredAutoCompleteComponent
} from "../../auto-completes/filtered-auto-complete/filtered-auto-complete.component";
import { onlyNumbers, cep, latitude, longitude } from "../../../services/data-transform";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import {
  CancellationConfirmationDialog
} from "../../dialogs/cancellation-confirmation-dialog/cancellation-confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { LocaleClassesDialogComponent } from "../../dialogs/locale-classes-dialog/locale-classes-dialog.component";
import { LocaleClassesService } from "../../../services/locale-classes-service";
import { ActivatedRoute, Router } from "@angular/router";
import { LocaleTaxesDialogComponent } from "../../dialogs/locale-taxes-dialog/locale-taxes-dialog.component";
import { LocaleTaxesService } from "../../../services/locale-taxes-service";
import { MatCheckbox } from "@angular/material/checkbox";
import { BooleanRadioComponent } from "../../radios/boolean-radio/boolean-radio.component";
import { GMSFormatService } from '../../../services/gmsformatservice';
import { MatIcon } from '@angular/material/icon';
import { ComboService } from '../../../services/combo-service';
import { UsersService } from '../../../services/users-service';
import { IComboOption } from '../../../interfaces/auto-complete-interfaces';

@Component({
  selector: 'locale-form',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardActions,
    MatButton,
    MatCardTitle,
    MatCardSubtitle,
    ReactiveFormsModule,
    MatInput,
    MatFormField,
    MatGridList,
    MatGridTile,
    MatOption,
    MatSelect,
    MatRadioGroup,
    MatRadioButton,
    MatLabel,
    MatError,
    MatAutocompleteTrigger,
    MatAutocomplete,
    FilteredAutoCompleteComponent,
    SubmitButtonComponent,
    MatProgressSpinner,
    MatCheckbox,
    BooleanRadioComponent,
    MatIcon
  ],
  templateUrl: './locale-form.component.html',
  styleUrl: './locale-form.component.scss'
})

export class LocaleFormComponent implements OnInit, OnDestroy {
  entidadesExternas: IComboOption[] = []

  constructor(
    private router: Router,
    protected generalService: GeneralService,
    protected _comboService: ComboService,
    protected localesService: LocalesService,
    protected localeClassesService: LocaleClassesService,
    protected localeTaxesService: LocaleTaxesService,
    private dialog: MatDialog,
    private _userService: UsersService,
    private route: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    if (this.localesService.isReadonly) {
      this.localesService.locale.disable()
    }

    if (this.localesService.locale.value.latitude) {
      this.localesService.locale.patchValue({
        latitude: latitude(this.localesService.locale.value.latitude),
      })
    }

    if (this.localesService.locale.value.longitude) {
      this.localesService.locale.patchValue({
        longitude: longitude(this.localesService.locale.value.longitude),
      })
    }

    if (this.localesService.locale.value.id_localidade) {
      await this.localeClassesService.getLocaleClasses();
      await this.localeTaxesService.getLocaleTaxes();
    }

    this.entidadesExternas = [{ value: '' } as IComboOption, ...this._comboService.entidadesExternas];
    await this.generalService.getGeneralData();
  }

  ngOnDestroy() {
    this.localeClassesService.currentLocaleClass = null;
    this.localeClassesService.toBeCreated = [];
    this.localeClassesService.currentLocaleClassToBeCreated = null;

    this.localeTaxesService.currentLocaleTax = null;
    this.localeTaxesService.toBeCreated = [];
    this.localeTaxesService.currentLocaleTaxToBeCreated = null;
  }

  checkClassesAndTaxes() {
    return !!this.localeClassesService.currentLocaleClass
      && (
        this.localesService.locale.controls.localidade_cobranca_vista.value === "0"
        || (
          this.localesService.locale.controls.localidade_cobranca_vista.value === "1"
          && !!this.localeTaxesService.currentLocaleTax
        )
      );
  }

  public async submit() {
    const classAndTaxNeededMessage = 'É necessário que toda localidade possua uma classe com vigência ativa e, quando realiza cobrança à vista, deve também possuir uma taxa de administração com vigência ativa, a despeito do eventual cadastramento de datas futuras para os referidos campos.';

    try {
      if (
        this.localesService.locale
        && this.localesService.locale.status === 'VALID'
      ) {
        const data = {
          ...this.localesService.locale.value,
        };

        if (this.localesService.locale.value.id_localidade) {
          if (this.checkClassesAndTaxes()) {
            const { data_ultima_atualizacao } = await this.localesService.update(data);

            this.localesService.locale.patchValue({ data_ultima_atualizacao });

            this.generalService.notify('Localidade atualizada com sucesso', 'positive');

          } else {
            this.generalService.notify(
              classAndTaxNeededMessage,
              'negative',
              60000,
              'OK',
            );
          }
        } else {
          if (
            !this.localeClassesService.hasCurrentClassToBeCreated()
            || (
              this.localesService.locale.controls.localidade_cobranca_vista.value === "1"
              && !this.localeTaxesService.hasCurrentTaxToBeCreated()
            )
          ) {
            this.generalService.notify(
              classAndTaxNeededMessage,
              'negative',
              60000,
              'OK',
            );
          } else {
            const propagating = this.localeTaxesService.toBeCreated.filter(
              (tax) => tax.propagar_taxa
            ).length;

            const { id_localidade, data_ultima_atualizacao } = await this.localesService.create(data);

            // create classes
            await this.localeClassesService.createVarious(id_localidade);

            // create taxes
            await this.localeTaxesService.createVarious(id_localidade);

            this.localesService.locale.patchValue({ id_localidade, data_ultima_atualizacao });

            await this.router.navigate([`locales/${id_localidade}`]);

            if (propagating) {
              this.generalService.notify('Localidade criada e taxa / vigência propagada com sucesso', 'positive');
            } else {
              this.generalService.notify('Localidade criada com sucesso', 'positive');
            }
          }
        }

        //ATUALIZAR TOKEN
        this._userService?.RefreshToken(this._userService.user)
      }

      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0]) {
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      } else {
        this.generalService.notify('erro ao cadastrar nova localidade', 'negative');
      }
      return Promise.reject(error);
    }
  }

  public addressNumber() {
    if (this.localesService.locale.value.numero) {
      this.localesService.locale.patchValue({
        numero: this.localesService.locale.value.numero,
      });
    }
  }

  public cep() {
    if (this.localesService.locale.value.cep) {
      this.localesService.locale.patchValue({
        cep: cep(this.localesService.locale.value.cep),
      });
    }
  }

  public latitude(event: Event) {
    // @ts-ignore
    const { inputType } = event;

    const clear = ['deleteContentForward', 'deleteContentBackward'].includes(inputType);

    if (this.localesService.locale.value.latitude) {
      this.localesService.locale.patchValue({
        latitude: clear ? '' : latitude(this.localesService.locale.value.latitude),
      });
    }
  }

  public longitude(event: Event) {
    // @ts-ignore
    const { inputType } = event;

    const clear = ['deleteContentForward', 'deleteContentBackward'].includes(inputType);

    if (this.localesService.locale.value.longitude) {
      this.localesService.locale.patchValue({
        longitude: clear ? '' : longitude(this.localesService.locale.value.longitude),
      });
    }
  }

  protected async openLocaleClassesDialog() {
    await this.localeClassesService.getLocaleClasses();

    const dialogRef = this.dialog.open(LocaleClassesDialogComponent, {
      data: { ...this.localesService.locale },
    });

    dialogRef.afterClosed().subscribe(async () => {
      void this.localeClassesService.clearForm(false);
    });
  }

  protected async openEditLocaleTaxesDialog() {
    await this.localeTaxesService.getLocaleTaxes();

    const dialogRef = this.dialog.open(LocaleTaxesDialogComponent, {
      data: { ...this.localesService.locale },
    });

    dialogRef.afterClosed().subscribe(async () => {
      void this.localeTaxesService.clearForm(false);
    });
  }

  async openCancelDialog() {
    const localeId = this.route.snapshot.paramMap.get('id');

    if (this.localesService.isReadonly) {
      await this.router.navigate(['locales']);
    } else {
      const dialogRef = this.dialog.open(CancellationConfirmationDialog, {
        data: {
          title: localeId != null ? 'Cancelar edição de localidade?' : 'Cancelar cadastro de localidade?',
          question: 'Tem certeza que deseja cancelar a edição desta localidade?',
          warning: 'Todas as informações não salvas serão perdidas.',
        },
      });

      dialogRef.afterClosed().subscribe((cancel: boolean) => {
        if (cancel) {
          void this.localesService.closeLocaleForm(true);
        }
      });
    }
  }
}
