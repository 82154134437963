import { Injectable } from '@angular/core';
import apiService from './api-service';
import {
  IAutoCompleteOption,
  IComboOption,
} from '../interfaces/auto-complete-interfaces';
import { UsersService } from './users-service';

const COMBO_DATA_KEY = 'dadosCombo';
const COMBO_DATA_NAME_KEY = 'nomeCombo';

@Injectable()
export class ComboService {
  private data: {
    [key: string]: IComboOption[];
  } = {};

  constructor(private _userService: UsersService) {
    this.getCombos();
  }

  //#region combos

  public localidadesAll: IComboOption[] = [];
  public localidades: IComboOption[] = [];
  public classes: IComboOption[] = [];
  public siglaIATA: IComboOption[] = [];
  public tarifas: IComboOption[] = [];
  public subordinacoes: IComboOption[] = [];
  public tiposContatos: IComboOption[] = [];
  public entidadesExternas: IComboOption[] = [];
  public grupos: IComboOption[] = [];
  public siglasICAO: IComboOption[] = [];
  public users: IComboOption[] = [];
  loading = false;

  //#endregion

  private setCombos() {
    this.localidadesAll = this.data['localidades'] ?? [];
    this.localidades = this.data['localidades'] ?? [];
    this.classes = this.data['classes'] ?? [];
    this.siglaIATA = this.data['siglasiata'] ?? [];
    this.tarifas = this.data['tarifas'] ?? [];
    this.subordinacoes = this.data['subordinacoes'] ?? [];
    this.tiposContatos = this.data['tiposcontatos'] ?? [];
    this.entidadesExternas = this.data['entidadesExternas'] ?? [];
    this.grupos = this.data['grupos'] ?? [];
    this.siglasICAO = this.data['siglasicao'] ?? [];
    this.users = this.users || [];

    if (!this._userService.user.DECEA) {
      this.localidades = this.localidades.filter((x) =>
        this._userService.user.localidades.includes(x.id as number)
      );
    }
  }

  //#region calls api
  public async getCombos() {
    this.loading = true;

    try {
      const { data } = await apiService.get(
        'Combos?tabelas=CLASSES&tabelas=SIGLA_IATA&tabelas=TARIFAS&tabelas=SUBORDINACOES&tabelas=TIPOS_CONTATOS&tabelas=ENTIDADES_EXTERNAS&tabelas=LOCALIDADES&tabelas=GRUPOS'
      );

      if (data.length) {
        for (const dataItem of data) {
          this.data[dataItem[COMBO_DATA_NAME_KEY]] = dataItem[COMBO_DATA_KEY];
        }
      }

      const { data: brazilianICAOResult } = await apiService.get(
        'Combos/sigla-icao?national_airfields=true'
      );

      if (
        brazilianICAOResult &&
        brazilianICAOResult[COMBO_DATA_KEY] &&
        brazilianICAOResult[COMBO_DATA_KEY].length
      ) {
        this.data[brazilianICAOResult[COMBO_DATA_NAME_KEY]] = [
          ...brazilianICAOResult[COMBO_DATA_KEY],
        ];
      }

      this.setCombos();
      this.loading = false;
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getComboData(tableName: string) {
    try {
      const { data } = await apiService.get(`Combos?tabelas=${tableName}`);

      return Promise.resolve(data[0]['dadosCombo']);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGroupsCombo(): Promise<IAutoCompleteOption[]> {
    try {
      const { data } = await apiService.get('Combos/groups');

      this.data[data.nomeCombo] = data.dadosCombo;

      return Promise.resolve(data.dadosCombo);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getUsers(): Promise<IAutoCompleteOption[]> {
    try {
      const { data } = await apiService.get('Combos/users');

      this.users = data.dadosCombo ?? [];

      return Promise.resolve(data.dadosCombo);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  //#endregion
}
