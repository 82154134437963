import { AfterViewInit, Component, inject, ViewChild } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCard, MatCardContent } from "@angular/material/card";
import { MatDialog } from "@angular/material/dialog";

import { IconButton } from "../../buttons/icon-button/icon-button.component";
import { ExternalEntityDialog } from "../../dialogs/external-entity-dialog/external-entity-dialog.component";
import { ExternalEntitiesService, IExternalEntity } from "../../../services/external-entities-service";
import { GeneralRemoveDialog } from "../../dialogs/general-remove-dialog/general-remove-dialog.component";

import { CNPJ } from "../../../services/data-transform";
import { MatPaginator } from "@angular/material/paginator";
import { GeneralService } from "../../../services/general-service";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import {SubmitButtonComponent} from "../../buttons/submit-button/submit-button.component";
import {MatButton} from "@angular/material/button";
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'external-entities-table',
  styleUrl: 'external-entities-table.component.scss',
  templateUrl: 'external-entities-table.component.html',
  standalone: true,
  imports: [
    MatTableModule,
    IconButton,
    ExternalEntityDialog,
    GeneralRemoveDialog,
    MatCard,
    MatCardContent,
    MatPaginator,
    MatProgressSpinner,
    SubmitButtonComponent,
    MatButton,
  ],
})

export class ExternalEntitiesTable implements AfterViewInit {
  externalEntitiesService = inject(ExternalEntitiesService);
  dialog = inject(MatDialog);
  displayedColumns: string[] = ['nr_cnpj', 'nm_entidade_externa', 'actions'];

  dataSource = new MatTableDataSource<IExternalEntity>(this.externalEntitiesService.list);
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected generalService: GeneralService,
    private funcionalidadesService: FuncionalidadesService
  ) {}

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_ENTIDADES_EXTERNAS,
    false
  );

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  maskCNPJ(value: string): string {
    return CNPJ(value);
  }

  openDialog(externalEntity: IExternalEntity): void {
    const dialogRef = this.dialog.open(ExternalEntityDialog, {
      data: { externalEntity },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((reloadExternalEntities) => {
      if (reloadExternalEntities) {
        void this.externalEntitiesService.getAll();
      }
    });
  }

  openRemoveDialog(externalEntity: IExternalEntity) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'a entidade externa',
        id: externalEntity.id_entidade_externa,
        name: `${CNPJ(externalEntity.nr_cnpj)} - ${externalEntity.nm_entidade_externa}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {

          const result = await this.externalEntitiesService.delete(removeId);


          if (result.error == true) {
            this.generalService.notify(result.messagens.join(" - "), 'negative');
            return
          }

          this.generalService.notify('Entidade externa removida', 'positive');

          await this.externalEntitiesService.clearSearch();

        } catch (error: any) {
          this.generalService.notify(error.messagens.join("<br>"), 'negative');
        }
      }
    });
  }
}
