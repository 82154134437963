<form style="margin-bottom:20px" [formGroup]="localeTaxesService.data" (submit)="trySubmitting()">
  <mat-grid-list cols="2" rowHeight="70px" gutterSize="15">
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <mat-label>% de taxa de adm.</mat-label>
        <input matInput formControlName="percentual_taxa" [maxLength]="6" (input)="numbersAndCommas()">
        <mat-error *ngIf="this.localeTaxesService.data.get('percentual_taxa')?.hasError('min')">
          Deve ser maior que 0.
        </mat-error>

        <mat-error *ngIf="this.localeTaxesService.data.get('percentual_taxa')?.hasError('max')">
          Deve ser menor ou igual a 100.
        </mat-error>

        <mat-error *ngIf="this.localeTaxesService.data.get('percentual_taxa')?.hasError('required')">
          Informe a taxa de administração
        </mat-error>
      </mat-form-field>

    </mat-grid-tile>

    <mat-grid-tile>
      <simple-datepicker [externalControl]="localeTaxesService.data.controls.data_vigencia" />
    </mat-grid-tile>
  </mat-grid-list>

  <div class="text-center">
    <mat-checkbox color="primary" formControlName="propagar_taxa">
      Propagar taxa / vigência para todas as localidades
    </mat-checkbox>
  </div>

  <div class="text-center" style="margin-top:8px">
    <submit-button label="Salvar" icon="save" [loading]="saving" />

    <button type="button" mat-button mat-raised-button style="min-width:100px;margin-left:10px"
      (click)="localeTaxesService.clearForm()">
      <mat-icon>close</mat-icon>
      Cancelar
    </button>
  </div>
</form>