<form (ngSubmit)="submit()">

    <div class="header-dialog">
      <h2 class="title-dialog" mat-dialog-title>{{ title }}</h2>
      <icon-button [iconCssClass]="'close-icon-dialog'" [btnCssClass]="'close-button-dialog'" iconName="close"
        (click)="closeDialog()" />
    </div>
  
    <mat-dialog-content>
      <div class="text-center">
        {{ question }}
      </div>
  
      <div class="text-center" style="color:red;margin-top:10px">
        {{ warning }}
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions align="end" style="padding-bottom: 15px">
  
      <button type="button" style="margin-right:9px;width: 120px" mat-raised-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>Não
      </button>
  
      <submit-button label="Sim" [icon]="'check'" style="margin-right: 10px" />
  
    </mat-dialog-actions>
  
  
  </form>