<form [formGroup]="_logsService.search" (ngSubmit)="submit()" class="form-flex">

  <div style="float:left;width:115px;margin-right:5px">
    <div class="full-width">
      @if (generalService.data['siglasicao'] && generalService.data['siglasicao'].length) {
      <select-icao [externalControl]="_logsService.search.controls.sigla_icao" />
      }
    </div>
  </div>

  <mat-form-field class="min-width-140">
    <mat-label>Funcionalidade / ação de cobrança a vista</mat-label>
    <mat-select formControlName="funcionalidade_log">
      @for (currentFunc of funcionalidadesLog; track currentFunc) {
      <mat-option [value]="currentFunc.id">{{ currentFunc.value }}</mat-option>
      }
    </mat-select>
    <mat-error>Informe a funcionalidade</mat-error>
  </mat-form-field>

  <mat-form-field class="min-width-140">
    <mat-label>Usuário</mat-label>
    <mat-select formControlName="id_usuario">
      @for (currentUser of _comboService.users; track currentUser) {
      <mat-option [value]="currentUser.id">{{
        currentUser.complemento
        }}</mat-option>
      }
    </mat-select>
    <mat-error>Informe o usuário</mat-error>
  </mat-form-field>

  <simple-datepicker label="De" [blockPastDays]="false" [externalControl]="searchForm.controls.data_inicio" />

  <simple-datepicker label="Até" [blockPastDays]="false" [externalControl]="searchForm.controls.data_fim" />

  <div class="btns-search">
    <submit-button label="Buscar" [icon]="'search'" />

    <clear-filter-button style="margin-left: 10px" (click)="_logsService.clearSearch()" />
  </div>
</form>