import { Component, OnInit, inject, ViewChild, Input } from '@angular/core';

import { LocalesService } from '../../../services/locales-service';
import { GeneralService } from '../../../services/general-service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { SharedModule } from '../../../shared/modules/shared.module';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { LocalesTarriffsService } from '../../../services/locales-tarriffs-service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralRemoveDialog } from '../../dialogs/general-remove-dialog/general-remove-dialog.component';
import { ITarifaVigencia } from '../../../shared/interfaces/dtos/ITarifaVigencia';

@Component({
  selector: 'locale-tariffs-table',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './locale-tariffs-table.component.html',
  styleUrl: './locale-tariffs-table.component.scss',
})
export class LocaleTariffsTableComponent implements OnInit {
  dialog = inject(MatDialog);

  @Input() readOnly: boolean = false;

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected localesService: LocalesService,
    protected localesTariffsService: LocalesTarriffsService,
    protected generalService: GeneralService,
    private formBuilder: FormBuilder
  ) {}

  ngAfterViewInit() {
    this.localesTariffsService.paginatorVigencias = this.paginator;
  }

  async ngOnInit() {
    const idVigencia = this.form.get('tarifa')?.value;
    this.localesTariffsService.loadVigencias(
      idVigencia,
      this.localesService.locale.value.id_localidade || 0
    );
  }

  get form(): FormGroup {
    return this.localesTariffsService.formTarifaDestinatario;
  }

  get dateIdSelected(): number {
    return this.form.get('id_vigencia')?.value || 0;
  }

  arrowClicked(vigencia: ITarifaVigencia) {}

  deleteClicked(e: Event, vigencia: ITarifaVigencia) {
    e.preventDefault();
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: '',
        id: vigencia.idTariffVigencia,
        name:
          'Vigência: ' + this.localesTariffsService.formatDate(vigencia.date),
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          await this.localesTariffsService.delete(removeId);

          this.generalService.notify('Tarifa removida', 'positive');

          this.localesTariffsService.resetForm();
          this.localesTariffsService.formTarifaDestinatario.patchValue({
            tarifa: vigencia.idTariff,
          });
          this.localesTariffsService.loadVigencias(
            vigencia.idTariff,
            this.localesService.locale.value.id_localidade || 0
          );
        } catch (error) {
          this.generalService.notify('Erro ao remover tarifa', 'negative');
        }
      }
    });
  }

  dateClicked(vigencia: ITarifaVigencia) {
    this.localesTariffsService.setVigenciaForm(vigencia, this.readOnly);
  }

  handlePageEvent(e: PageEvent) {
    this.localesTariffsService.paginateVigenciaDate(
      e.pageIndex * e.pageSize,
      (e.pageIndex + 1) * e.pageSize
    );
  }
}
