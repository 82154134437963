import { Component, OnDestroy } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { MatButton } from '@angular/material/button';

import { LocaleFormComponent } from '../../forms/locale-form/locale-form.component';
import { LocaleTariffsFormComponent } from '../../forms/locale-tariffs-form/locale-tariffs-form.component';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { LocaleContactsFormComponent } from '../../forms/locale-contacts-form/locale-contacts-form.component';
import { LocalesService } from '../../../services/locales-service';
import { LocalesTableComponent } from '../../tables/locales-table/locales-table.component';
import { LocaleContactsTableComponent } from '../../tables/locale-contacts-table/locale-contacts-table.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LocaleTariffsTableComponent } from '../../tables/locale-tariffs-table/locale-tariffs-table.component';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'locale-panel',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatGridList,
    MatGridTile,
    LocaleFormComponent,
    LocaleTariffsFormComponent,
    LocaleContactsFormComponent,
    LocalesTableComponent,
    LocaleContactsTableComponent,
    LocaleTariffsTableComponent,
    MatProgressSpinner,
  ],
  templateUrl: './locale-panel.component.html',
  styleUrl: './locale-panel.component.scss',
})
export class LocalePanelComponent implements OnDestroy {
  constructor(protected localesService: LocalesService,
    private _funcionalidadesService: FuncionalidadesService
  ) { }

  showTabContact: boolean = false
  showTabTarifas: boolean = false

  ngOnDestroy() {
    void this.localesService.closeLocaleForm();
  }

  ngOnInit(){
    this.showTabTarifas = this._funcionalidadesService.canAccess(
      FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_CONFIGURACOES_TARIFARIAS,
    );
  
    this.showTabContact = this._funcionalidadesService.canAccess(
      FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_CONTATOS,
    );

  }
}
