@if (!_comboService.siglasICAO) {
  <div class="full-width" style="display:flex;justify-content:center;padding:300px">
    <mat-spinner diameter="24"/>
  </div>
} @else {
  <form [formGroup]="localesService.locale" (ngSubmit)="submit()">
    <mat-card>
      <mat-card-content>
        <mat-grid-list cols="6" rowHeight="80px" gutterSize="15">
          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Nome da localidade</mat-label>

              <input type="text" maxlength="255" matInput formControlName="nm_localidade"/>
              <mat-error>Informe o nome da localidade</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Nome reduzido</mat-label>

              <input type="text" maxlength="100" matInput formControlName="nm_reduzido_localidade"/>
              <mat-error>Informe o nome reduzido</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <boolean-radio style="margin-top:-15px" label="Situação da localidade" firstOptionLabel="Ativa"
                           secondOptionLabel="Inativa" [externalControl]="localesService.locale.controls.status"/>
          </mat-grid-tile>

          <mat-grid-tile>
            @if (localesService.locale.value.id_localidade) {
              <mat-form-field class="full-width">
                <mat-label>Última atualização</mat-label>

                <input type="text" matInput readonly [value]="localesService.locale.value.data_ultima_atualizacao"
                       [disabled]="this.localesService.isReadonly"/>
              </mat-form-field>
            }
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="43" rowHeight="80px" gutterSize="15">
          <mat-grid-tile colspan="4">
            <filtered-auto-complete class="full-width" label="Sigla ICAO" [maxLength]="4"
                                    [options]="_comboService.siglasICAO"
                                    [externalControl]="localesService.locale.controls.sigla_icao"/>
          </mat-grid-tile>

          <mat-grid-tile colspan="4">
            <filtered-auto-complete class="full-width" label="Sigla IATA" [maxLength]="3" [minKeywordLength]="2"
                                    [options]="_comboService.siglaIATA"
                                    [externalControl]="localesService.locale.controls.sigla_iata"/>
          </mat-grid-tile>

          <mat-grid-tile colspan="7">
            <mat-form-field class="full-width">
              <mat-label>Classe atual e vigência</mat-label>

              <input type="text" matInput readonly [value]="localeClassesService.currentLocaleClassLabel"
                     [disabled]="this.localesService.isReadonly"/>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="5">
            <button type="button" color="primary" class="edit-validities-button full-width" mat-raised-button
                    (click)="openLocaleClassesDialog()">
              Vigências de classes
            </button>
          </mat-grid-tile>

          <mat-grid-tile colspan="9">
            <boolean-radio
              style="margin-top:-15px"
              label="Localidade realiza cobrança à vista?"
              firstOptionLabel="Sim"
              secondOptionLabel="Não"
              [externalControl]="localesService.locale.controls.localidade_cobranca_vista"
            />
          </mat-grid-tile>

          <mat-grid-tile colspan="9">
            <mat-form-field class="full-width">
              <mat-label>Taxa adm. atual e vigência</mat-label>

              <input
                type="text"
                matInput
                readonly
                [value]="localeTaxesService.currentLocaleTaxLabel"
                [disabled]="this.localesService.isReadonly"
              />
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="5">
            <button
              type="button"
              color="primary"
              class="edit-validities-button full-width"
              mat-flat-button
              (click)="openEditLocaleTaxesDialog()"
            >
              Vigências de taxas de adm.
            </button>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="3" rowHeight="80px" gutterSize="15">
          @if (_comboService.entidadesExternas) {
            <mat-grid-tile>
              <mat-form-field class="full-width">
                <mat-label>Administradora</mat-label>

                <mat-select formControlName="id_administradora">
                  @for (externalEntity of _comboService.entidadesExternas; track externalEntity) {
                    <mat-option [value]="externalEntity.id">{{ externalEntity.value }}
                    </mat-option>
                  }
                </mat-select>
                <mat-error>Informe a administradora</mat-error>
              </mat-form-field>
            </mat-grid-tile>

            <mat-grid-tile>
              <mat-form-field class="full-width">
                <mat-label>Responsável pelo controle de tráfego aéreo</mat-label>

                <mat-select formControlName="id_resp_cont_trafego_aereo">
                  @for (externalEntity of entidadesExternas; track externalEntity) {
                    <mat-option [value]="externalEntity.id">{{ externalEntity.value }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
          }
        </mat-grid-list>

        <mat-grid-list cols="8" rowHeight="80px" gutterSize="15">
          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>CEP</mat-label>

              <input type="text" maxlength="9" matInput formControlName="cep" (input)="cep()"/>
              <mat-error>Informe o CEP</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="3">
            <mat-form-field class="full-width">
              <mat-label>Endereço físico (logradouro)</mat-label>

              <input type="text" maxlength="255" matInput formControlName="endereco_fisico"/>
              <mat-error>Informe o endereço da localidade</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>Número</mat-label>

              <input type="text" maxlength="10" matInput formControlName="numero" (input)="addressNumber()"/>
              <mat-error>Informe o número</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>Compl.</mat-label>

              <input type="text" maxlength="20" matInput formControlName="complemento"/>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Bairro</mat-label>

              <input type="text" maxlength="45" matInput formControlName="bairro"/>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="15" rowHeight="80px" gutterSize="15">
          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Cidade</mat-label>

              <input type="text" maxlength="100" matInput formControlName="cidade"/>
              <mat-error>Informe a cidade da localidade</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>UF</mat-label>

              <mat-select formControlName="uf">
                @for (state of localesService.states; track state) {
                  <mat-option [value]="state.value">{{ state.viewValue }}</mat-option>
                }
              </mat-select>
              <mat-error>Informe a UF</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Latitude</mat-label>

              <input type="text" maxlength="13" spellcheck="false" matInput formControlName="latitude"
                     (input)="latitude($event)"/>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Longitude</mat-label>

              <input type="text" maxlength="20" spellcheck="false" matInput formControlName="longitude"
                     (input)="longitude($event)"/>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Tipo fonte</mat-label>

              <mat-select formControlName="tipo_fonte">
                @for (sourceType of localesService.sourceTypes; track sourceType) {
                  <mat-option [value]="sourceType.value">{{ sourceType.viewValue }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="2">
            <mat-form-field class="full-width">
              <mat-label>Subordinação</mat-label>

              <mat-select formControlName="id_subordinacao">
                @for (subordinate of _comboService.subordinacoes; track subordinate) {
                  <mat-option [value]="subordinate.id">{{ subordinate.value }}</mat-option>
                }
              </mat-select>
              <mat-error>Informe a subordinação</mat-error>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile colspan="3">
            <boolean-radio style="margin-top:-15px" label="Situação do coletor na localidade" firstOptionLabel="Ativo"
                           secondOptionLabel="Inativo"
                           [externalControl]="localesService.locale.controls.situacao_coletor_localidade"/>
          </mat-grid-tile>
        </mat-grid-list>

        <div>
          <mat-form-field class="full-width">
            <mat-label>Comentários</mat-label>

            <textarea matInput rows="3" maxlength="500" formControlName="comentarios"></textarea>

            <div style="font-size: 12px; text-align: right;">
              Caracteres digitados: {{ localesService.locale.value.comentarios?.length }} / 500
            </div>
          </mat-form-field>
        </div>
      </mat-card-content>

      <mat-card-actions>
        <div class="full-width text-center" style="margin-bottom:10px">
          @if (!this.localesService.isReadonly) {
            <submit-button style="margin-right:10px" label="Salvar" icon="save"
                           [loading]="localesService.saving"></submit-button>
          }

          <button type="button" style="width: 120px" mat-raised-button (click)="openCancelDialog()">
            <mat-icon>{{ !localesService.isReadonly ? 'close' : 'arrow_back' }}</mat-icon>
            {{ !localesService.isReadonly ? 'Cancelar' : 'Voltar' }}
          </button>

        </div>
      </mat-card-actions>
    </mat-card>
  </form>
}
