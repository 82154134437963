import { AfterContentInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GeneralService } from "../../../services/general-service";
import { FormBuilder, FormsModule, Validators } from "@angular/forms";
import MovesService, { A_POSTERIORI } from "../../../services/moves-service";
import { MatGridList } from "@angular/material/grid-list";
import { SharedModule } from "../../../shared/modules/shared.module";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import {
  FilteredAutoCompleteComponent
} from "../../auto-completes/filtered-auto-complete/filtered-auto-complete.component";
import { SimpleDatepickerComponent } from "../../datepickers/simple-datepicker.component";
import { UsersService } from "../../../services/users-service";
import { toISO8601 } from "../../../services/date-service";
import { SelectIcaoComponent } from "../../selects/select-icao/select-icao.component";
import { CustomValidateService } from "../../../services/custom-validate-service";
import { IMove } from '../../../interfaces/move-interfaces';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { numbersAndCommas, onlyNumbers } from "../../../services/data-transform";

@Component({
  selector: 'create-move-dialog',
  standalone: true,
  imports: [
    MatGridList,
    SharedModule,
    FormsModule,
    SubmitButtonComponent,
    FilteredAutoCompleteComponent,
    SimpleDatepickerComponent,
    SelectIcaoComponent
  ],
  templateUrl: './create-move-dialog.component.html',
  styleUrl: './create-move.dialog.component.scss'
})

export class CreateMoveDialog implements OnInit, AfterContentInit, OnDestroy {
  move = this.formBuilder.group({
    id_movimento: '',
    icao: '',
    identificacao: ['', [Validators.required]],
    matricula: '',
    tipo_aeronave: ['', [Validators.required]],
    pmd: ['', [Validators.required]],
    // arr
    data_pouso: ['', [CustomValidateService.BeforeDeparture]],
    hora_pouso: ['', [CustomValidateService.ArrivalTime]],
    origem_icao: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    // dep
    data_decolagem: ['', [CustomValidateService.AfterArrival]],
    hora_decolagem: ['', [CustomValidateService.DepartureTime]],
    destino_icao: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
  });

  saving = false;
  delay = 500;
  checkDatesAndTimesTimeout = 0;

  constructor(
    protected generalService: GeneralService,
    protected usersService: UsersService,
    private movesService: MovesService,
    public dialogRef: MatDialogRef<CreateMoveDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { move?: IMove },
    private formBuilder: FormBuilder,
    private _funcionalidadesService: FuncionalidadesService
  ) {
  }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_REGISTROS_MOVIMENTOS,
    false
  );

  ngOnInit() {
    if (this.data.move) {
      this.move.patchValue({
        id_movimento: this.data.move.id_movimento || '',
        icao: this.usersService.currentLocale?.sigla_icao || '',
        identificacao: this.data.move.identificacao,
        matricula: this.data.move.matricula,
        tipo_aeronave: this.data.move.tipo_aeronave,
        pmd: this.data.move.pmd,
        data_pouso: this.data.move.data_pouso,
        hora_pouso: this.data.move.hora_pouso,
        origem_icao: this.data.move.origem_icao,
        data_decolagem: this.data.move.data_decolagem,
        hora_decolagem: this.data.move.hora_decolagem,
        destino_icao: this.data.move.destino_icao,
      });
    } else {
      this.move.patchValue({
        icao: this.usersService.currentLocale?.sigla_icao || ''
      });
    }

    this.checkDatesAndTimes();

    if (this.isReadonly) {
      this.move.disable();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.checkDatesAndTimesTimeout);
  }

  private checkDatesAndTimes() {
    this.checkDatesAndTimesTimeout = setTimeout(() => {
      this.move.controls.data_pouso.updateValueAndValidity();
      this.move.controls.data_decolagem.updateValueAndValidity();
      this.move.controls.hora_pouso.updateValueAndValidity();
      this.move.controls.hora_decolagem.updateValueAndValidity();

      this.checkDatesAndTimes();
    }, 500);
  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (!this.usersService.singleLocale) {
        Array.from(document.querySelectorAll('input')).forEach(el => el.blur());
      }
    }, 150);
  }

  get readOnly() {
    return !this.usersService.singleLocale;
  }

  onlyNumbers(event: Event) {
    const pmd = (event.target as HTMLInputElement).value;

    if (this.move.controls.pmd) {
      this.move.patchValue({
        pmd: onlyNumbers(pmd),
      });
    }
  }

  closeDialog(reloadMovesList = false): void {
    this.dialogRef.close(reloadMovesList);
  }

  async submit() {
    try {
      this.saving = true;

      if (
        this.move.status === 'VALID'
        && this.move.value.data_pouso
        && this.move.value.data_decolagem
        && this.move.value.icao
        && this.move.value.identificacao
        && this.move.value.origem_icao
        && this.move.value.destino_icao
      ) {
        const flightClassification = await this.movesService.classifyFlight({
          icao_localidade: this.move.value.icao,
          identificacao: this.move.value.identificacao,
          origem: this.move.value.origem_icao,
          destino: this.move.value.destino_icao,
          data_decolagem: this.move.value.data_decolagem,
        });

        if (flightClassification.tipo_cobranca === A_POSTERIORI) {
          this.generalService.notify('Operação inválida: o movimento não atende às condições de cobrança à vista', 'negative', 10000);
        } else {
          await this.movesService.save({
            ...this.move.value,
            data_pouso: toISO8601(this.move.value.data_pouso),
            data_decolagem: toISO8601(this.move.value.data_decolagem),
            ...flightClassification,
          }, this.move.controls.id_movimento.value);

          this.closeDialog(true);

          if (this.move.controls.id_movimento.value) {
            this.generalService.notify('Movimento atualizado com sucesso', 'positive');
          } else {
            this.generalService.notify('Movimento registrado com sucesso', 'positive');
          }
        }
      }
    } catch (error: any) {
      console.error(error);

      setTimeout(() => {
        this.generalService.notify(error.messagens.join("<br>"), 'negative');
      }, this.delay);
    } finally {
      setTimeout(() => {
        this.saving = false;
      }, this.delay);
    }
  }

  protected numbersAndCommas() {
    const { value } = this.move.controls.pmd;
    this.move.patchValue({ pmd: numbersAndCommas(value || '') });
  }

  // formatNumber(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   let value = input.value.replace(/[^0-9]/g, '');

  //   if (value.length > 0) {
  //     value = value.replace(/^0+/, '');
  //     value = value.padStart(3, '0');

  //     value = value.replace(/(\d+)(\d{2})$/, '$1.$2');
  //   }

  //   this.move.patchValue({
  //     pmd: value
  //   })
  //   input.value = value;
  // }

}
