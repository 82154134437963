<form
  [formGroup]="form"
  (ngSubmit)="submit($event)"
>
  <div class="header-dialog">
    @if (!this.isReadonly) {
      @if (data?.group?.id_grupo) {
        <h2 class="title-dialog" mat-dialog-title>Editar grupo</h2>
      } @else {
        <h2 class="title-dialog" mat-dialog-title>Cadastrar novo grupo</h2>
      }
    } @else {
      <h2 class="title-dialog" mat-dialog-title>Visualizar grupo</h2>
    }

    <button
      type="button"
      class="close-button-dialog"
      mat-icon-button
      (click)="closeDialog()"
    >
      <mat-icon type="button" class="close-icon-dialog">close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="container">
      <div class="flex-row">
        <mat-form-field style="min-width: 350px">
          <mat-label>Nome do grupo</mat-label>

          <input [readOnly]="isReadonly" matInput formControlName="nm_grupo"/>
          <mat-error>Informe o nome do grupo</mat-error>
        </mat-form-field>

        <boolean-radio
          [externalControl]="form.controls.situacao"
          label="Situação do grupo"
          firstOptionLabel="Ativo"
          secondOptionLabel="Inativo"
        />
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="padding-bottom: 15px">
    <button
      type="button"
      style="margin-right:9px;width: 120px"
      mat-raised-button
      (click)="closeDialog()"
    >
      <mat-icon>{{ !this.isReadonly ? 'close' : 'arrow_back' }}</mat-icon>
      {{ !this.isReadonly ? 'Cancelar' : 'Voltar' }}
    </button>

    @if (!this.isReadonly) {
      <submit-button
        data-cy="submit-external-entity-button"
        label="Salvar"
        style="margin-right:10px;"
        [loading]="_groupsService.loading"
        [icon]="'save'"
      />
    }

  </mat-dialog-actions>
</form>
