import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from './general-service';
import apiService from './api-service';
import { Router } from '@angular/router';
import { LocaleClassesService } from './locale-classes-service';
import { IGroup, IGroupsUser } from '../interfaces/groups-interfaces';

@Injectable()
export class GroupsService {
  localeClassesService: LocaleClassesService | null = null;

  apiResource = 'Grupo';

  public showLocaleForm: boolean = false;
  public showLocaleContactForm: boolean = true;

  public loading: boolean = false;
  public saving: boolean = false;
  public list: IGroup[] = [];
  public listGroups: IGroupsUser = {} as IGroupsUser;

  public paginator: any;

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private router: Router
  ) {}

  //#region calls api
  public async getAll() {
    try {
      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        this.apiResource
      );

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter grupos', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async search(search: { [key: string]: string | null }) {
    try {
      this.loading = true;

      const params = { ...search };

      const { data } = await apiService.getWithParams(
        `${this.apiResource}`,
        params
      );

      this.list = [...data['result']];
    } catch (error) {
      console.error(error);

      this.generalService.notify('Erro ao buscar grupos', 'negative');
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  public async create(postData: any) {
    try {
      this.loading = true;

      await apiService.post(this.apiResource, postData);

      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else
        this.generalService.notify('erro ao cadastrar novo grupo', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async delete(removeId: any) {
    try {
      const { data } = await apiService.delete(
        `${this.apiResource}/${removeId}`
      );

      return Promise.resolve(data);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('Erro ao remover grupo', 'negative');

      return Promise.reject(error);
    }
  }

  public async update(id: any, postData: any) {
    try {
      this.loading = true;

      const { data } = await apiService.put(
        `${this.apiResource}/${id}`,
        postData
      );
      return Promise.resolve(data);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('Erro ao atualizar grupo', 'negative');
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async getUsersGroups(idGrupo: number) {
    try {
      this.loading = true;

      const { data }: { data: { result: IGroupsUser } } = await apiService.get(
        `${this.apiResource}/${idGrupo}/Users`
      );

      this.listGroups = data.result;

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify(
        'Erro ao obter os usuários do grupo',
        'negative'
      );

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async registerUsersGroups(idGrupo: number, idsUsers: number[]) {
    try {
      this.loading = true;

      const postData = {
        ids_usuarios: idsUsers,
      };
      const { data }: { data: { result: IGroupsUser } } = await apiService.post(
        `${this.apiResource}/${idGrupo}/Users`,
        postData
      );

      this.listGroups = data.result;

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify(
        'Erro ao vincular usuarios no grupo',
        'negative'
      );

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  //#endregion
}
