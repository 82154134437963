<div style="padding:15px">
  <search-entity-bar/>

  @if (!this.isReadonly) {
    <button
      data-cy="create-external-entity-button"
      mat-flat-button
      color="primary"
      style="position:absolute;top:37px;right:15px"
      (click)="openDialog()"
    >
      <mat-icon>add</mat-icon>
      Incluir entidade externa
    </button>
  }

  @if (externalEntitiesService.loading) {
    <div class="full-width" style="display:flex;justify-content:center">
      <mat-spinner diameter="24"/>
    </div>
  } @else {
    <external-entities-table/>
  }
</div>
