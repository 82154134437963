<div>
  <div class="container">
    <img src="./assets/logo.jpg" width="130" height="150" />
    <img src="./assets/logo.png" width="350" height="100" />


    <h1 class="title">Portal Localidades</h1>

    <form [formGroup]="this._userService.formLogin" (submit)="submit($event)">
      <mat-card class="container-card">
        <mat-card-content>
          <div class="container-filds">
            <mat-form-field style="min-width: 400px">
              <mat-label>E-mail</mat-label>

              <input data-cy="email-login-input" matInput formControlName="email" />
              <mat-error>Informe o e-mail</mat-error>
            </mat-form-field>

            <input-password [label]="'Senha'" [errorMessage]="'Informe a senha'"
              [formControl]="this._userService.formLogin.controls.senha"></input-password>

            <a class="button-link" (click)="forgotPassword()">
              Esqueci Minha Senha
            </a>

            <div class="btn-login">
              <submit-button label="Entrar" [icon]="'login'" [loading]="_userService.loading" />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>