<form
  [formGroup]="_usersService.searchForm"
  (ngSubmit)="submit()"
>
  <div class="container-user-search">
    <mat-form-field style="min-width: 300px">
      <mat-label>Nome do usuário</mat-label>

      <input matInput formControlName="nm_usuario"/>
    </mat-form-field>

    <boolean-radio
      [externalControl]="_usersService.searchForm.controls.ativo"
      label="Situação do usuário"
      firstOptionLabel="Ativo"
      secondOptionLabel="Inativo"
    />

    <mat-radio-group class="text-center" color="primary" formControlName="organizacao">
      <div class="boolean-radio-label">Organização do usuário</div>

      <div>
        <mat-radio-button value="0" (click)="selectLocale(0)">Todas</mat-radio-button>
        <mat-radio-button *ngIf="this._usersService.user.DECEA" value="9999"
                          (click)="selectLocale(9999)">DECEA/ATAN
        </mat-radio-button>
        <mat-radio-button value="1" (click)="selectLocale(1)">Localidade</mat-radio-button>
      </div>
    </mat-radio-group>

    <mat-form-field (click)="enable()">
      <mat-label>Localidade</mat-label>
      <mat-select formControlName="id_localidade">
        @for (currentClass of _comboService.localidades; track
          currentClass) {
          <mat-option [value]="currentClass.id">{{
              currentClass.value
            }}
          </mat-option>
        }
      </mat-select>
      <mat-error>Informe a classe</mat-error>
    </mat-form-field>

    <div class="buttons-search">
      <submit-button label="Buscar" icon="search"/>

      <clear-filter-button
        style="margin-left: 10px"
        (click)="_usersService.clearSearch()"
      />
    </div>

    @if (!this.isReadonly) {
      <button
        class="btn-add-user"
        type="button"
        color="primary"
        mat-flat-button
        (click)="showModalNovoUser()"
      >
        <mat-icon>add</mat-icon>
        Incluir usuário
      </button>
    }
  </div>
</form>
