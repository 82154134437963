import { Component } from '@angular/core';

@Component({
  selector: 'app-write-down-values-page',
  standalone: true,
  imports: [],
  templateUrl: './write-down-values-page.component.html',
  styleUrl: './write-down-values-page.component.scss'
})
export class WriteDownValuesPageComponent {

}
