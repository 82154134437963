import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import MovesService from "../../../services/moves-service";
import {IMove} from "../../../interfaces/move-interfaces";
import {FormControl, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'select-move-checkbox',
  standalone: true,
  imports: [
    MatCheckbox,
    ReactiveFormsModule
  ],
  templateUrl: './select-move-checkbox.component.html',
  styleUrl: './select-move-checkbox.component.scss'
})
export class SelectMoveCheckboxComponent implements OnInit {
  @Input() move?: IMove;
  @Output() selectedMovesChanged = new EventEmitter<boolean>();

  selected = new FormControl(false);

  constructor(
    public movesServices: MovesService,
  ) {
  }

  ngOnInit() {
    if (this.move) {
      const { id_movimento } = this.move;

      if (id_movimento) {
        if (this.movesServices.selectedMoves.find((moveId) => Number(id_movimento) === moveId)) {
          this.selected.setValue(true);
        }
      }
    }
  }

  protected updateSelectedMoves($event: MatCheckboxChange, move: IMove) {
    this.movesServices.updateSelectedMoves($event, move);

    this.selectedMovesChanged.emit(this.movesServices.refreshCheckboxes);
  }
}
