<mat-tab-group
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  animationDuration="0ms"
  preserveContent
  class="rounded-borders bg-white"
>
  <mat-tab label="Informações gerais">
    <locale-form/>
  </mat-tab>

  @if (showTabTarifas) {
    <mat-tab
      label="Configurações tarifárias"
      [disabled]="!localesService.locale.value.id_localidade"
    >
      <locale-tariffs-form/>
    </mat-tab>
  }

  @if (showTabContact) {
    <mat-tab
      label="Contatos"
      [disabled]="!localesService.locale.value.id_localidade"
    >
      @if (localesService.showLocaleContactForm) {
        <locale-contacts-form/>

        <locale-contacts-table/>
      } @else {
        <div class="full-width" style="display: flex; justify-content: center; padding: 350px">
          <mat-spinner diameter="24"/>
        </div>
      }
    </mat-tab>
  }
</mat-tab-group>
