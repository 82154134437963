import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { routes } from './app.routes';

// SERVICES
import { GeneralService } from './services/general-service';
import { ExternalEntitiesService } from './services/external-entities-service';
import { LocalesService } from './services/locales-service';
import { LocaleClassesService } from './services/locale-classes-service';
import { MatPaginatorIntlCro } from './services/pagination';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocaleTaxesService } from './services/locale-taxes-service';
import { LocalesTarriffsService } from './services/locales-tarriffs-service';
import { UsersService } from './services/users-service';
import { GroupsService } from './services/groups-service';
import { GroupFeaturesService } from "./services/group-features-service";
import MovesService from "./services/moves-service";
import { MenuService } from './services/menu-service';
import { FuncionalidadesService } from './services/funcionalidades-service';
import { ComboService } from './services/combo-service';
import { DatService } from './services/dat-service';
import {RepassesService} from "./services/repasses-service";
import { LogsService } from './services/logs-service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    { provide: GeneralService },
    { provide: ComboService },
    { provide: ExternalEntitiesService },
    { provide: LocalesService },
    { provide: LogsService },
    { provide: UsersService },
    { provide: MenuService },
    { provide: DatService },
    { provide: FuncionalidadesService },
    { provide: GroupsService },
    { provide: LocaleClassesService },
    { provide: LocaleTaxesService },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    { provide: LocalesTarriffsService },
    { provide: GroupFeaturesService },
    { provide: MovesService },
    { provide: RepassesService },
  ],
};
