import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatError, MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatOption } from "@angular/material/autocomplete";
import { MatLabel, MatSelect } from "@angular/material/select";
import { NgClass } from "@angular/common";

import { GeneralService } from "../../../services/general-service";
import { IAutoCompleteOption } from "../../../interfaces/auto-complete-interfaces";
import { MatIcon } from "@angular/material/icon";

@Component({
  standalone: true,
  selector: 'filtered-auto-complete',
  templateUrl: './filtered-auto-complete.component.html',
  styleUrl: './filtered-auto-complete.component.scss',
  imports: [
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatOption,
    MatSelect,
    NgClass,
    MatIcon
  ],
})

export class FilteredAutoCompleteComponent {
  @Input() options!: IAutoCompleteOption[]
  @Input() externalControl: FormControl = new FormControl('', [Validators.required, Validators.minLength(4)]);
  @Input() label = 'Escolha uma opção';
  @Input() minKeywordLength = 3;
  @Input() maxLength = 0;
  @Input() minLength = 0;
  @Input() disabled = false;
  @Input() validationErrorMessage = 'Informe a sigla ICAO';

  filteredResults: IAutoCompleteOption[] = [];
  selectedItem: IAutoCompleteOption | undefined;

  inputElementId: string;
  filteredResultsElementId: string;
  fullOverlayElementId: string;

  constructor(
    protected generalService: GeneralService
  ) {
    this.inputElementId = this.generalService.generateID(32);
    this.filteredResultsElementId = this.generalService.generateID(32);
    this.fullOverlayElementId = this.generalService.generateID(32);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setFilteredResultsElementPositionAndSize();
    }, 500);
  }

  protected clearFilteredResults(delay = 0) {
    setTimeout(() => {
      this.filteredResults = [];

      this.setFilteredResultsVisibility(false);
    }, delay);
  }

  protected openFilteredResults() {
    if (!this.disabled) {
      if (this.externalControl.value && this.externalControl.value?.length >= this.minKeywordLength) {
        const results = this.filter(this.externalControl.value);

        this.filteredResults = [...results];

        this.setFilteredResultsVisibility(true);
      }
    }
  }

  private filter(keyword: string) {
    return this.options.filter(
      (currentICAO) => currentICAO['value'].toLowerCase().includes(keyword.toLowerCase())
    );
  }

  private getFilterElement() {
    return document.getElementById(this.inputElementId);
  }

  private getFilteredResultsElement() {
    return document.getElementById(this.filteredResultsElementId);
  }

  private getFullOverlayElement() {
    return document.getElementById(this.fullOverlayElementId);
  }

  protected filterItems(event: Event) {
    if (!this.disabled) {
      const keyword = (event.target as HTMLInputElement).value;

      if (keyword.length >= this.minKeywordLength) {
        const results = this.filter(keyword);

        this.filteredResults = [...results];

        this.setFilteredResultsVisibility(true);
      } else {
        this.clearFilteredResults();
      }
    }
  }

  protected setSelectedItem(item: IAutoCompleteOption) {
    this.externalControl.setValue(item['value']);
    this.selectedItem = { ...item };

    this.clearFilteredResults();
  }

  private setFilteredResultsVisibility(visible: boolean) {
    const filteredResultsElement = this.getFilteredResultsElement();

    if (filteredResultsElement) {
      if (visible) {
        filteredResultsElement.classList.add('visible');
        filteredResultsElement.classList.remove('one-line');
        filteredResultsElement.classList.remove('two-lines');

        if (this.filteredResults.length < 2) {
          filteredResultsElement.classList.add('one-line');
        } else if (this.filteredResults.length === 2) {
          filteredResultsElement.classList.add('two-lines');
        }

        this.setFilteredResultsElementPositionAndSize();

        this.showOverlay(true);
      } else {
        filteredResultsElement.classList.remove('visible');

        this.showOverlay(false);
      }
    }
  }

  private showOverlay(show: boolean) {
    const overlayElement = this.getFullOverlayElement();

    if (overlayElement) {
      overlayElement.style.display = show ? 'block' : 'none';
    }
  }

  private setFilteredResultsElementPositionAndSize() {
    const filterElement = this.getFilterElement();
    const filteredResultsElement = this.getFilteredResultsElement();

    if (filterElement && filteredResultsElement) {
      const { top, left, width } = filterElement.getBoundingClientRect();

      // set position
      filteredResultsElement.style.top = `${top + 57}px`;
      filteredResultsElement.style.left = `${left}px`;

      // set width (the height will always 258px)
      filteredResultsElement.style.width = `${width}px`;
    }
  }
}
