import {Component, OnDestroy, OnInit} from '@angular/core';
import {SelectIcaoComponent} from "../../components/selects/select-icao/select-icao.component";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatGridTile} from "@angular/material/grid-list";
import {MatInput} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {SharedModule} from "../../shared/modules/shared.module";
import {RepassSearchBarComponent} from "../../components/bars/repass-search-bar/repass-search-bar.component";
import {GrusTableComponent} from "../../components/tables/grus-table/grus-table.component";
import {GeneralService} from "../../services/general-service";
import {
  GrusActionsButtonsBarComponent
} from "../../components/bars/grus-actions-buttons-bar/grus-actions-buttons-bar.component";
import {UsersService} from "../../services/users-service";
import MovesService from "../../services/moves-service";
import {ComboService} from "../../services/combo-service";

@Component({
  selector: 'repasses-page',
  standalone: true,
  imports: [
    SelectIcaoComponent,
    MatFormField,
    MatGridTile,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SharedModule,
    RepassSearchBarComponent,
    GrusTableComponent,
    GrusActionsButtonsBarComponent
  ],
  templateUrl: './repasses-page.component.html',
  styleUrl: './repasses-page.component.scss'
})
export class RepassesPageComponent implements OnInit, OnDestroy {
  constructor(
    protected generalService: GeneralService,
    protected movesServices: MovesService,
    protected usersService: UsersService,
    protected _comboService: ComboService,
  ) {
  }

  async ngOnInit() {
    this.generalService.setExtraTitle('Cobrança à vista - Repasse de valores');

    this.movesServices.injectUsersService(this.usersService);

    await this.movesServices.getMoves(true);
  }

  ngOnDestroy() {
    this.movesServices.resetMovesRepasse();
  }
}
