<form
  [formGroup]="search"
  (ngSubmit)="submit()"
>
  <mat-grid-list cols="16" rowHeight="80px" gutterSize="15" style="width:84%">
    @if (generalService.data['siglasicao'] && generalService.data['siglasicao'].length) {
      <mat-grid-tile colspan="2">
        <div class="full-width">
          <select-icao
            [externalControl]="search.controls.sigla_icao"
          />
        </div>
      </mat-grid-tile>
    }

    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Ident. do vôo</mat-label>

        <input
          data-cy="search-identificacao-input"
          type="text"
          matInput
          formControlName="identificacao"
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Matrícula</mat-label>

        <input
          data-cy="search-matricula-input"
          type="text"
          matInput
          formControlName="matricula"
        />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="3">
      <simple-datepicker
        label="De"
        class="full-width"
        [blockPastDays]="false"
        [externalControl]="search.controls.periodo_inicio"
      />
    </mat-grid-tile>

    <mat-grid-tile colspan="3">
      <simple-datepicker
        label="Até"
        class="full-width"
        [blockPastDays]="false"
        [externalControl]="search.controls.periodo_fim"
      />
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <submit-button
        label="Buscar"
        [icon]="'search'"
      />
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <clear-filter-button
        (click)="clearSearch()"
      />
    </mat-grid-tile>
  </mat-grid-list>
</form>
