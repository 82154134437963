import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import apiService from "./api-service";
import { ILocaleClass, ILocaleClassToBeCreated } from "../interfaces/locale-classes-interfaces";
import { GeneralService } from "./general-service";
import { LocalesService } from "./locales-service";
import {
  dateWithZeroHour,
  formatDateAndTime,
  getGreatestDate,
  toBrazilianDate,
  toISO8601
} from "./date-service";
import API from "./api-service";
import { ILocale } from "../interfaces/locale-interfaces";
import { ComboService } from "./combo-service";

const defaultControls = {
  id: 0,
  id_localidade: 0,
  id_classe: ['', Validators.required],
  data_vigencia: ['', Validators.required],
};

@Injectable()
export class LocaleClassesService {
  apiResource = 'LocalidadeClasses';
  public loading = true;
  public clearingForm = true;
  public list: ILocaleClass[] = [];
  public currentLocaleClass: ILocaleClass | null = null;
  public currentLocaleClassToBeCreated: ILocaleClassToBeCreated | null = null;

  allCurrentLocales: { [key: number]: ILocaleClass } = {};

  // while creating locale
  public toBeCreated: ILocaleClassToBeCreated[] = [];
  public dateToBeEdited = 0;

  public data = this.formBuilder.group({ ...defaultControls });

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private _comboService: ComboService,
    private localeService: LocalesService,
  ) {
  }

  public isCurrentClass(localeClassId: number) {
    if (!this.currentLocaleClass) return false;

    return this.currentLocaleClass.id_localidade_classe === localeClassId;
  }

  public isCurrentClassToBeCreated(localeClass: ILocaleClassToBeCreated) {
    return new Date(localeClass.data_vigencia) < new Date();
  }

  public hasCurrentClassToBeCreated() {
    if (!this.toBeCreated.length) return false;

    return !!this.toBeCreated.find(
      (localeClass) => new Date(localeClass.data_vigencia) < new Date()
    );
  }

  get currentLocaleClassLabel() {
    if (this.localeService.locale.value.id_localidade) {
      if (!this.currentLocaleClass) return 'Não definida';

      return this.getCurrentClassLabel(this.currentLocaleClass.id_classe, this.currentLocaleClass.data_vigencia, false);
    } else {
      if (!this.currentLocaleClassToBeCreated) return 'Não definida';

      return this.getCurrentClassLabel(Number(this.currentLocaleClassToBeCreated.id_classe), this.currentLocaleClassToBeCreated.data_vigencia, false);
    }
  }

  public getCurrentClassLabel(id_classe: number, data_vigencia: string, isGrid: boolean) {
    if (isGrid) {
      return `${this.getClassName(id_classe)}`;
    }

    return `${this.getClassName(id_classe)} - desde ${formatDateAndTime(data_vigencia)}`;
  }

  public clearForm(reMount = true) {
    this.clearingForm = true;

    this.data = this.formBuilder.group({ ...defaultControls });

    setTimeout(() => {
      this.clearingForm = !reMount;
    }, 500);
  }

  public getClassName(localeClassId: number) {
    return this._comboService.classes.find(({ id }) => id === localeClassId)?.value;
  }

  public async getAll() {
    try {
      const resource = `${this.apiResource}`;

      const { data }: { data: { result: ILocaleClass[] } } = await apiService.get(resource);

      return Promise.resolve(data.result);
    } catch (error) {
      this.generalService.notify('Erro ao obter classes / vigências', 'negative');

      return Promise.reject(error);
    }
  }

  /**
   * Set the current class of each locale
   */
  public async setCurrentClassesForAllLocales(locales: ILocale[]) {
    const allLocaleClasses = await this.getAll();

    const today = dateWithZeroHour(new Date());

    for (const locale of locales) {
      const localeClassesAux: Date[] = [];

      // find all past dates
      for (const localeClass of allLocaleClasses) {
        if (localeClass.id_localidade === locale.id_localidade) {
          const localeClassValidity = new Date(localeClass.data_vigencia);

          if (localeClassValidity <= today) {
            localeClassesAux.push(localeClassValidity);
          }
        }
      }

      if (localeClassesAux.length) {
        const greaterDate = getGreatestDate(localeClassesAux);

        const greaterClassValidity = allLocaleClasses.find(
          (currentLocaleClass) =>
            new Date(currentLocaleClass.data_vigencia).getTime() === greaterDate.getTime()
            && currentLocaleClass.id_localidade === locale.id_localidade,
        );

        if (greaterClassValidity) {
          this.allCurrentLocales[locale.id_localidade] = { ...greaterClassValidity };
        }
      }
    }
  }

  public async getLocaleClasses(setLoading = true) {
    try {
      this.loading = setLoading;

      const resource = `${this.apiResource}/Classes/${this.localeService.locale.value.id_localidade}`;

      const { data }: { data: { result: ILocaleClass[] } } = await apiService.get(resource);

      this.list = [...data.result];

      void this.setCurrentClass();

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter classes / vigências', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public storeClassesBeforeCreatingLocale() {
    const { data_vigencia } = this.data.value;

    if (!!data_vigencia) {
      const alreadyExists = this.toBeCreated.find(
        (localeClass) => toBrazilianDate(localeClass.data_vigencia) === toBrazilianDate(data_vigencia)
      );

      if (alreadyExists && !this.dateToBeEdited) {
        this.generalService.notify('A classe / vigência já foi cadastrada para esta localidade', 'negative');
      } else {
        this.loading = true;

        // EDITING
        if (
          this.dateToBeEdited
          && this.data.value.id_classe
          && this.data.value.data_vigencia
        ) {
          this.loading = true;

          const updatedList: ILocaleClassToBeCreated[] = [];

          let index = 1;

          for (const item of this.toBeCreated) {
            if (index === this.dateToBeEdited) {
              // check if date already exists in other position (using index)
              if (
                this.toBeCreated.find(
                  (toBe, currentIndex) => {
                    return this.data.value.data_vigencia?.toString() === toBe.data_vigencia.toString()
                      && index - 1 !== currentIndex
                  }
                )
              ) {
                this.generalService.notify('A classe / vigência já foi cadastrada para esta localidade', 'negative');

                updatedList.push(item);
              } else {
                updatedList.push({
                  id_classe: this.data.value.id_classe,
                  data_vigencia: this.data.value.data_vigencia,
                });
              }
            } else {
              updatedList.push(item);
            }

            index += 1;
          }

          this.toBeCreated = [...updatedList];
        } else {
          // CREATING
          if (this.toBeCreated.find((localeClass) => localeClass.data_vigencia === this.data.value.data_vigencia)) {
            this.generalService.notify('A classe / vigência já foi cadastrada para esta localidade', 'negative');
          } else {
            if (this.data.value.id_classe && this.data.value.data_vigencia) {
              this.toBeCreated.push({
                id_classe: this.data.value.id_classe,
                data_vigencia: this.data.value.data_vigencia,
              });
            }
          }
        }

        this.clearForm();

        void this.setCurrentClassToBeCreated();

        setTimeout(() => {
          this.loading = false;

          this.dateToBeEdited = 0;
        }, 500);
      }
    }
  }

  public async classValidityExistsInLocale(localeClass: {
    id: number,
    id_localidade: number,
    id_classe: number,
    data_vigencia: string,
  }) {
    try {
      const resource = `${this.apiResource}/Classes/${this.localeService.locale.value.id_localidade}`;

      const { data }: { data: { result: ILocaleClass[] } } = await apiService.get(resource);

      const exists = data.result.find(
        (currentLocaleClass) =>
          currentLocaleClass.data_vigencia.includes(localeClass.data_vigencia)
          && currentLocaleClass.id_localidade === localeClass.id_localidade
          && currentLocaleClass.id_localidade_classe !== localeClass.id
      );

      return Promise.resolve(exists);
    } catch (error) {
      this.generalService.notify('Erro ao verificar se a classe de localidade existe', 'negative');

      return Promise.reject(error);
    }
  }

  public async createVarious(localeId: number) {
    for (const localeClass of this.toBeCreated) {
      try {
        void apiService.post(this.apiResource, {
          id_localidade: localeId,
          id_classe: localeClass.id_classe,
          data_vigencia: toISO8601(localeClass.data_vigencia),
        });
      } catch (error) {
        console.error(error);

        this.generalService.notify('Erro ao criar a classe / vigência de localidade', 'negative');
      }
    }
  }

  public async create() {
    try {
      if (this.data.value.data_vigencia) {
        await apiService.post(this.apiResource, {
          ...this.data.value,
          data_vigencia: toISO8601(this.data.value.data_vigencia),
        });

        this.generalService.notify('A classe / vigência de localidade foi criada', 'positive');

        this.clearForm();

        setTimeout(async () => {
          await this.getLocaleClasses();
        }, 500);
      }
    } catch (error) {
      this.generalService.notify('Erro ao criar a classe / vigência de localidade', 'negative');

      return Promise.reject(error);
    }
  }

  public async update() {
    try {
      if (this.data.value.data_vigencia) {
        await apiService.put(`${this.apiResource}/${this.data.value.id}`, {
          ...this.data.value,
          data_vigencia: toISO8601(this.data.value.data_vigencia),
        });

        await this.getLocaleClasses();

        this.generalService.notify('A classe / vigência de localidade foi atualizada', 'positive');
      }
    } catch (error) {
      this.generalService.notify('Erro ao atualizar a classe / vigência de localidade', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async delete(localeClassId: number) {
    try {
      const { data } = await API.delete(`${this.apiResource}/${localeClassId}`);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteFromCreating(localeClassDate: string) {
    this.loading = true;

    const updatedList: ILocaleClassToBeCreated[] = [];

    for (const item of this.toBeCreated) {
      if (item.data_vigencia !== localeClassDate) {
        updatedList.push(item);
      }
    }

    this.toBeCreated = [...updatedList];

    this.currentLocaleClassToBeCreated = null;

    this.setCurrentClassToBeCreated();

    setTimeout(() => {
      this.loading = false
    }, 500);
  }

  public setCurrentClass() {
    try {
      this.currentLocaleClass = null;

      const today = dateWithZeroHour(new Date());

      const classesFound = this.list.filter((localeClass) => {
        // if (localeClass.id_localidade !== localeClass.id_localidade) return false;

        const localeClassValidity = new Date(localeClass.data_vigencia);

        return localeClassValidity <= today;
      });

      const localeClassesAux: Date[] = [];

      for (const localeClass of classesFound) {
        localeClassesAux.push(new Date(localeClass.data_vigencia));
      }

      const greaterDate = getGreatestDate(localeClassesAux);

      const greaterClasValidity = classesFound.find(
        (localeClass) => new Date(localeClass.data_vigencia).getTime() === greaterDate.getTime(),
      );

      if (greaterClasValidity) {
        this.currentLocaleClass = greaterClasValidity;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public setCurrentClassToBeCreated() {
    try {
      this.currentLocaleClass = null;

      const today = dateWithZeroHour(new Date());

      const classesFound = this.toBeCreated.filter((localeClass) => {
        const localeClassValidity = new Date(localeClass.data_vigencia);

        return localeClassValidity <= today;
      });

      const localeClassesAux: Date[] = [];

      for (const localeClass of classesFound) {
        localeClassesAux.push(new Date(localeClass.data_vigencia));
      }

      const greaterDate = getGreatestDate(localeClassesAux);

      const greaterClasValidity = classesFound.find(
        (localeClass) => new Date(localeClass.data_vigencia).getTime() === greaterDate.getTime(),
      );

      if (greaterClasValidity) {
        this.currentLocaleClassToBeCreated = greaterClasValidity;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
