import {Component, OnInit} from '@angular/core';
import {GeneralService} from '../../services/general-service';
import {MatButton} from '@angular/material/button';
import {LocalesTableComponent} from '../../components/tables/locales-table/locales-table.component';
import {LocalePanelComponent} from '../../components/panels/locale-panel/locale-panel.component';
import {LocalesService} from '../../services/locales-service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {MatIcon} from '@angular/material/icon';
import {LocaleClassesService} from '../../services/locale-classes-service';
import {LocaleSearchBarComponent} from '../../components/bars/locale-search-bar/locale-search-bar.component';
import {UsersService} from '../../services/users-service';
import {FormBuilder} from '@angular/forms';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';
import {FuncionalidadesService} from '../../services/funcionalidades-service';

@Component({
  selector: 'locales-page',
  standalone: true,
  imports: [
    MatButton,
    LocalesTableComponent,
    LocalePanelComponent,
    MatProgressSpinner,
    MatIcon,
    LocaleSearchBarComponent,
  ],
  templateUrl: './locales-page.component.html',
  styleUrl: './locales-page.component.scss',
})
export class LocalesPageComponent implements OnInit {
  constructor(
    public generalService: GeneralService,
    public localesService: LocalesService,
    public localeClassesService: LocaleClassesService,
    public userService: UsersService,
    private _funcionalidadesService: FuncionalidadesService,
    private _formBuilder: FormBuilder
  ) {
  }

  public canCreate = false;

  async ngOnInit() {
    this.generalService.setExtraTitle('Cadastros Básicos - Localidades');

    void this.localesService.injectClassesService(this.localeClassesService);

    await this.localesService.getAll();

    this.canCreate = this._funcionalidadesService.canAccess(
      FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_INFORMACOES_GERAIS,
      false
    );
  }
}
