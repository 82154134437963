import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SubmitButtonComponent } from '../buttons/submit-button/submit-button.component';
import { SharedModule } from '../../shared/modules/shared.module';

@Component({
  selector: 'app-custom-snack-bar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.scss']
})
export class CustomSnackBarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  onClose() {
    this.snackBarRef.dismiss(); // Fecha a mensagem de snackbar
  }
}