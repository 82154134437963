import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { GeneralService } from '../../services/general-service';
import { GroupsService } from '../../services/groups-service';
import { GroupsTableComponent } from '../../components/tables/groups-table/groups-table.component';
import { GroupSearchBarComponent } from '../../components/bars/group-search-bar/group-search-bar.component';
import { FormBuilder } from '@angular/forms';
import { UsersService } from '../../services/users-service';

@Component({
  selector: 'groups-page',
  standalone: true,
  imports: [GroupSearchBarComponent, GroupsTableComponent, SharedModule],
  templateUrl: './groups-page.component.html',
  styleUrl: './groups-page.component.scss',
})
export class GroupsPageComponent implements OnInit {
  constructor(
    public _groupsService: GroupsService,
    public _generalService: GeneralService,
    private formBuilder: FormBuilder,
    private _userService: UsersService
  ) { }

  search = this.formBuilder.group({
    nm_group: '',
    ativo: '',
    id_usuario: this._userService.user.usuarioId.toString() || '0'
  });

  async ngOnInit() {
    this._generalService.setExtraTitle('Usuários e Permissões - Cadastro de grupos');

    // CARREGAR GRUPOS DO USUARIOS
    if (!this._userService.user.DECEA) {
      let search = this.formBuilder.group({
        id_usuario: this._userService.user.usuarioId.toString() || '0'
      });
      await this._groupsService.search(search.value);
    } else {
      this._groupsService.getAll();
    }

  }
}
