import { Injectable } from '@angular/core';
import apiService from './api-service';
import { IAutoCompleteOption } from '../interfaces/auto-complete-interfaces';
import { MatSnackBar } from '@angular/material/snack-bar';
import packageJson from '../../../package.json';
import { CustomSnackBarComponent } from '../components/custom-snack-bar/custom-snack-bar.component';

const COMBO_DATA_KEY = 'dadosCombo';
const COMBO_DATA_NAME_KEY = 'nomeCombo';

const defaultGeneralLoadingLabel = 'Carregando atualizações';

@Injectable()
export class GeneralService {
  public version: string = packageJson.version;
  public generalLoading = false;
  public generalLoadingLabel = defaultGeneralLoadingLabel;
  outputColor = 'color:yellow;font-size:auto;';
  public title: string = 'DECEA - Portal Localidades';
  public extraTitle: string = 'Início';
  public itemsPerPage = 0;

  public loadingAllICAOs = true;

  public data: {
    [key: string]: IAutoCompleteOption[];
  } = {};

  constructor(private _snackBar: MatSnackBar) {
    void this.checkAppVersion();

    void this.getGeneralData();
  }

  public setLoading(label?: string) {
    this.generalLoadingLabel = label || defaultGeneralLoadingLabel;
    this.generalLoading = true;
  }

  public unsetLoading() {
    this.generalLoadingLabel = defaultGeneralLoadingLabel;
    this.generalLoading = false;
  }

  public log(text: string) {
    console.log(`%c${text}`, this.outputColor);
  }

  public notify(
    message: string,
    type: 'positive' | 'negative',
    milliseconds = 5000,
    action?: string
  ) {
    this._snackBar.open(message, action, {
      duration: milliseconds,
      panelClass: type,
    });
  }

  public notifyWithLink(message: string, url: string, type: string) {
    this._snackBar.openFromComponent(CustomSnackBarComponent, {
      duration: 20000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: { message, url },
      panelClass: [`${type}`, 'snack-bar-class'],

    });
  }

  public extractUrlAndMessage(error: any) {

    const errorData = error.response.data;

    if (errorData && errorData.messagens && errorData.messagens.length > 0) {

      const rawMessage = errorData.messagens[0];

      const parsedMessage = JSON.parse(rawMessage.replace('Erro: ', ''));

      const messageRegex = /(.*?)(<a href=["'](.*?)["'].*?>)/;

      const match = parsedMessage.message.match(messageRegex);

      if (match) {

        this.notifyWithLink(match[1].trim(), match[3], 'negative')

        return;
      } else {
        console.log('Mensagem completa:', parsedMessage.message);
      }
    }
  }

  public async getComboData(tableName: string) {
    try {
      const { data } = await apiService.get(`Combos?tabelas=${tableName}`);

      return Promise.resolve(data[0]['dadosCombo']);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public isValidICAO(icao: string) {
    const icaoFound = this.data['todos-icaos'].find(
      (currentIcao) => currentIcao.value === icao
    );

    return !!icaoFound;
  }

  public async loadAllICAOs() {
    try {
      this.loadingAllICAOs = true;


      const { data: allICAOResult } = await apiService.get('Combos/sigla-icao?national_airfields=false');

      if (
        allICAOResult &&
        allICAOResult[COMBO_DATA_KEY] &&
        allICAOResult[COMBO_DATA_KEY].length
      ) {
        this.data['todos-icaos'] = [
          ...allICAOResult[COMBO_DATA_KEY],
        ];
      }
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.loadingAllICAOs = false;
    }
  }

  public async getGeneralData() {
    try {
      const { data } = await apiService.get(
        'Combos?tabelas=CLASSES&tabelas=SIGLA_IATA&tabelas=TARIFAS&tabelas=SUBORDINACOES&tabelas=TIPOS_CONTATOS&tabelas=ENTIDADES_EXTERNAS&tabelas=LOCALIDADES&tabelas=GRUPOS'
      );

      if (data.length) {
        for (const dataItem of data) {
          this.data[dataItem[COMBO_DATA_NAME_KEY]] = dataItem[COMBO_DATA_KEY];
        }
      }

      const { data: brazilianICAOResult } = await apiService.get(
        'Combos/sigla-icao?national_airfields=true'
      );

      if (
        brazilianICAOResult &&
        brazilianICAOResult[COMBO_DATA_KEY] &&
        brazilianICAOResult[COMBO_DATA_KEY].length
      ) {
        this.data[brazilianICAOResult[COMBO_DATA_NAME_KEY]] = [
          ...brazilianICAOResult[COMBO_DATA_KEY],
        ];
      }

      // load all ICAOs (national and international codes)
      void this.loadAllICAOs();

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGroupsCombo(): Promise<IAutoCompleteOption[]> {
    try {
      const { data } = await apiService.get('Combos/groups');

      this.data[data.nomeCombo] = data.dadosCombo;

      return Promise.resolve(data.dadosCombo);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  get GroupsCombo() {
    return this.data['grupos'] ?? null;
  }

  public setExtraTitle(value: string) {
    setTimeout(() => {
      this.extraTitle = value;
    }, 10);
  }

  get fullTitle() {
    return `${this.title} - ${this.extraTitle}`;
  }

  public generateID(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return result;
  }

  public setItemsPerPage() {
    const pageContentElement = document.getElementById('right-layout-panel');

    this.itemsPerPage = pageContentElement
      ? Math.floor((pageContentElement.offsetHeight - 70) / 70)
      : 10;
  }

  getCurrentDateTime(): string {
    const currentDate = new Date();

    return currentDate.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  private async getLastVersion() {
    try {
      const { data } = await apiService.get('VersaoApp');

      this.version = data;
    } catch (error) {
      console.error(error);
    }
  }

  private async checkAppVersion() {
    await this.getLastVersion();

    const cachedVersion = window.localStorage.getItem('version');

    if (cachedVersion) {
      this.log(`Cached version: ${cachedVersion}`);

      if (this.version > cachedVersion) {
        await this.clearCache();

        window.localStorage.setItem('version', this.version);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } else {
      this.log('Downloaded for the first time!');

      window.localStorage.setItem('version', this.version || '0.0.0');
    }
  }

  async clearCache() {
    this.setLoading();

    if ('caches' in window) {
      const names = await caches.keys();

      for (const name of names) {
        await caches.delete(name);
      }
    }
  }

  formatCurrency(value: string | number | null): string {
    if (value === null || value === '') {
      return '';
    }

    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  }

}
