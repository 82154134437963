@if(!this._comboService.loading){

@if (!dataSource.data.length) {
<mat-card>
  <mat-card-content>Nenhum usuário encontrado.</mat-card-content>
</mat-card>
} @else {
<table mat-table [dataSource]="dataSource" class="mat-elevation-z1 highlight-table">
  <ng-container matColumnDef="nm_usuario">
    <th mat-header-cell *matHeaderCellDef>Nome do usuário</th>
    <td mat-cell *matCellDef="let user">{{ user.nm_usuario }}</td>
  </ng-container>

  <ng-container matColumnDef="ativo">
    <th mat-header-cell *matHeaderCellDef>Situação</th>
    <td mat-cell *matCellDef="let user">
      {{ user.ativo == 1 ? "Ativo" : "Inativo" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Login(e-mail)</th>
    <td mat-cell *matCellDef="let user">{{ user.email }}</td>
  </ng-container>

  <ng-container matColumnDef="telefone">
    <th mat-header-cell *matHeaderCellDef>Telefone</th>
    <td mat-cell *matCellDef="let user">{{ user.telefone }}</td>
  </ng-container>

  <ng-container matColumnDef="organizacao">
    <th mat-header-cell *matHeaderCellDef>Organização</th>
    <td mat-cell *matCellDef="let user">
      <a *ngIf="user.locales.length > 1" style="padding-left: 0px" class="button-link button-show-locales"
        (click)="showLocales(user)">
        Usuário em mais de uma localidade - visualizar
        <mat-icon>visibility</mat-icon>
      </a>
      <span *ngIf="!(user.locales.length > 1)">
        {{ getDescriptionOrganization(user) }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Ações</th>
    <td mat-cell *matCellDef="let user">
      @if(!this.isReadonly){
      <icon-button [tooltip]="'Editar'" [iconName]="'edit'" style="margin-right: 5px" (click)="editUser(user)" />

      <icon-button [tooltip]="'Excluir'" [iconName]="'delete'" (click)="removeUser(user)" />
      }@else{
      <icon-button [tooltip]="'Visualizar'" [iconName]="'visibility'" (click)="editUser(user)" />
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9" style="padding: 15px">
      Nenhum usuário encontrado.
    </td>
  </tr>
</table>

@if (_generalService.itemsPerPage < dataSource.data.length) { <mat-paginator showFirstLastButtons hidePageSize
  [pageSizeOptions]="[5, 10, 20]" [pageSize]="_generalService.itemsPerPage"></mat-paginator>
  } }
  }@else {
  <div class="loading-container">
    <mat-spinner diameter="20" class="mat-spinner-black"></mat-spinner>
  </div>
  }