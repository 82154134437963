@if (!dataSource.data.length) {
  <mat-card>
    <mat-card-content>Nenhum repasse encontrado.</mat-card-content>
  </mat-card>
} @else {
  <table
    mat-table
    class="mat-elevation-z1 highlight-table table-text-center"
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="icao">
      <th mat-header-cell *matHeaderCellDef>Localidade<br/>(ICAO)</th>
      <td
        mat-cell
        [class]="{ baixado: move.baixado }"
        *matCellDef="let move"
      >
        {{ move.icao }}
      </td>
    </ng-container>

    <ng-container matColumnDef="identificacao">
      <th mat-header-cell *matHeaderCellDef>Ident.<br/>do vôo</th>
      <td
        mat-cell
        [class]="{ baixado: move.baixado }"
        *matCellDef="let move"
      >
        {{ move.identificacao }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dados_da_aeronave">
      <th mat-header-cell *matHeaderCellDef>
        <div>Dados da aeronave</div>

        <table class="move-inside-table-data">
          <tr>
            <td style="min-width:60px !important">
              Matr.
            </td>
            <td>
              Tipo
            </td>
            <td>
              PMD
            </td>
          </tr>
        </table>
      </th>
      <td
        mat-cell
        [class]="{ baixado: move.baixado }"
        *matCellDef="let move"
      >
        <table class="move-inside-table-data">
          <tr>
            <td style="min-width:60px !important;">
              {{ move.matricula }}
            </td>
            <td>
              {{ move.tipo_aeronave }}
            </td>
            <td>
              {{ move.pmd }}
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="dados_arr">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          <div>Dados da operação - ARR</div>

          <table class="header-table">
            <tr>
              <td>
                Pouso
              </td>
              <td>
                Hora
              </td>
              <td>
                Origem
              </td>
            </tr>
          </table>
        </div>
      </th>
      <td
        mat-cell
        [class]="{ baixado: move.baixado }"
        *matCellDef="let move"
      >
        <table class="move-inside-table-data">
          <tr>
            <td>
              {{ formatDate(move.data_pouso) }}
            </td>
            <td>
              {{ move.hora_pouso }}h
            </td>
            <td>
              {{ move.origem_icao }}
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="dados_dep">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          <div>Dados da operação - DEP</div>

          <table class="header-table">
            <tr>
              <td>
                Decolagem
              </td>
              <td>
                Hora
              </td>
              <td>
                Destino
              </td>
            </tr>
          </table>
        </div>
      </th>
      <td
        mat-cell
        [class]="{ baixado: move.baixado }"
        *matCellDef="let move"
      >
        <table class="move-inside-table-data">
          <tr>
            <td>
              {{ formatDate(move.data_decolagem) }}
            </td>
            <td>
              {{ move.hora_decolagem }}h
            </td>
            <td>
              {{ move.destino_icao }}
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="repasse">
      <th
        class="light-blue-background"
        style="min-width:240px"
        mat-header-cell
        *matHeaderCellDef
      >
        <div class="full-width">
          <div>Dados do repasse</div>

          <table class="move-inside-table-data">
            <tr>
              <td>
                Código
              </td>
              <td>
                Data de ref.
              </td>
              <td>
                Valor
              </td>
            </tr>
          </table>
        </div>
      </th>
      <td
        mat-cell
        class="light-blue-background"
        [class]="{ baixado: move.baixado }"
        *matCellDef="let move"
      >
        @if (move.cod_repasse && move.data_referencia_repasse && move.valor_repasse) {
          <table class="move-inside-table-data">
            <tr>
              <td>
                {{ paddingZeros(move.cod_repasse, 6) }}
              </td>
              <td>
                {{ toBrazilianDate(move.data_referencia_repasse) }}
              </td>
              <td>
                {{ move.valor_repasse }}
              </td>
            </tr>
          </table>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>

      <td
        mat-cell
        style="width:80px"
        [class]="{ baixadoBold: move.baixado }"
        *matCellDef="let move"
      >
        @if (move.baixado) {
          <div>BAIXADO</div>
        } @else if (!hideCheckbox && !usersService.user.DECEA) {
          <select-move-checkbox
            [move]="move"
            (selectedMovesChanged)="selectedMovesChanged($event)"
          />
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9" style="padding:15px">Nenhum repasse encontrada</td>
    </tr>
  </table>

  @if ((generalService.itemsPerPage - 3) < dataSource.data.length) {
    <mat-paginator
      showFirstLastButtons
      hidePageSize
      [pageSize]="generalService.itemsPerPage - 3"
    ></mat-paginator>
  }
}
