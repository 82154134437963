import {Component, OnInit} from '@angular/core';
import {GeneralService} from "../../services/general-service";
import {MovesSearchBarComponent} from "../../components/bars/moves-search-bar/moves-search-bar.component";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import MovesService from "../../services/moves-service";
import {MovesTableComponent} from "../../components/tables/moves-table/moves-table.component";
import {CreateMoveDialog} from "../../components/dialogs/create-move-dialog/create-move-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {UsersService} from "../../services/users-service";
import {MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from "@angular/material/card";
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';
import { ComboService } from '../../services/combo-service';

@Component({
  selector: 'moves-page',
  standalone: true,
  imports: [
    MovesSearchBarComponent,
    MatButton,
    MatIcon,
    MovesTableComponent,
    MatProgressSpinner,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
  ],
  templateUrl: './moves-page.component.html',
  styleUrl: './moves-page.component.scss'
})

export class MovesPageComponent implements OnInit {
  constructor(
    protected generalService: GeneralService,
    protected movesServices: MovesService,
    protected usersService: UsersService,
    protected _comboService: ComboService,
    public dialog: MatDialog,
    private _funcionalidadesService: FuncionalidadesService
  ) {}

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_REGISTROS_MOVIMENTOS,
    false
  );

  protected isSingleLocaleUser = false;
  protected allowMoves = true;

  async ngOnInit() {
    this.generalService.setExtraTitle('Cobrança à vista - Painel de movimentos');

    this.movesServices.injectUsersService(this.usersService);

    this.isSingleLocaleUser = this.usersService.singleLocale;

    if (this.isSingleLocaleUser && !this.usersService.hasCobrancaAVista) {
      this.allowMoves = false;
    }

    await this.movesServices.getMoves();
  }

  openCreateMoveDialog() {
    const dialogRef = this.dialog.open(CreateMoveDialog, {
      data: { move: this.movesServices.item },
    })

    dialogRef.afterClosed().subscribe(async (reloadMovesList) => {
      if (reloadMovesList) {
        await this.movesServices.getMoves();
      }
    })
  }
}
