<div>
  <div class="container">
    <form [formGroup]="form" (submit)="submit($event)">
      <mat-dialog-content style="width: 800px">
        <div style="float: left; width: 47%; margin-right: 4%">
          <mat-form-field class="full-width">
            <mat-label>Nome do Usuário</mat-label>

            <div class="dense-input">
              <input readonly type="text" [value]="this._userService.user.nome" width="150" maxlength="150" matInput />
            </div>
          </mat-form-field>
        </div>

        <div style="float: left; width: 49%">
          <mat-form-field class="full-width">
            <mat-label>Login do Usuário</mat-label>

            <div class="dense-input">
              <input readonly type="text" [value]="this._userService.user.email" width="150" maxlength="150" matInput />
            </div>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-card style="width: 800px" class="container-card">
        <!-- LOCALIDADES -->
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Minhas Localidades
                <div style="left: 30px" matBadgeOverlap="true" [matBadge]="dataSourceLocales.data.length"
                  matBadgeSize="small" class="badge"></div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div style="min-width: 350px; max-width: 800px">
              @if (!dataSourceLocales.data.length) {
              <mat-card>
                <mat-card-content>Nenhuma localidade vinculada.</mat-card-content>
              </mat-card>
              } @else {
              <table mat-table [dataSource]="dataSourceLocales" class="mat-elevation-z1">
                <ng-container matColumnDef="nm_localidade">
                  <th mat-header-cell *matHeaderCellDef>Nome da localidade</th>
                  <td mat-cell *matCellDef="let locale">
                    {{ locale.nm_localidade }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef>Situação</th>
                  <td mat-cell *matCellDef="let locale">
                    {{ locale.status == 1 ? "Ativa" : "Inativa" }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsLocales"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsLocales"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="9" style="padding: 15px">
                    Nenhuma localidade vinculada.
                  </td>
                </tr>
              </table>
              }
              <div class="app-version">
                <a class="app-version-text">
                  <mat-icon class="menu-icon">info</mat-icon>
                  <span style="text-align: right">A associação é feita pelos administradores do
                    sistema.</span>
                </a>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- GRUPOS -->
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Meus Grupos
                <div style="left: 30px" [matBadge]="dataSourceGroups.data.length" matBadgeSize="small" class="badge">
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div style="min-width: 350px; max-width: 800px">
              @if (!dataSourceGroups.data.length) {
              <mat-card>
                <mat-card-content>Nenhum grupo vinculado.</mat-card-content>
              </mat-card>
              } @else {
              <table mat-table [dataSource]="dataSourceGroups" class="mat-elevation-z1">
                <ng-container matColumnDef="nm_grupo">
                  <th mat-header-cell *matHeaderCellDef>Nome do grupo</th>
                  <td mat-cell *matCellDef="let group">{{ group.nm_grupo }}</td>
                </ng-container>

                <ng-container matColumnDef="situacao">
                  <th mat-header-cell *matHeaderCellDef>Situação</th>
                  <td mat-cell *matCellDef="let group">
                    {{ group.situacao == 1 ? "Ativo" : "Inativo" }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsGroups"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsGroups"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="9" style="padding: 15px">
                    Nenhum grupo vinculado.
                  </td>
                </tr>
              </table>
              }

              <div class="app-version">
                <a class="app-version-text">
                  <mat-icon class="menu-icon">info</mat-icon>
                  <span class="menu-item-text">A associação é feita pelos administradores do
                    sistema.</span>
                </a>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- TROCA DE SENHA -->
        @if(!this.isReadonly){
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" (opened)="trocaSenhapanelOpenState.set(true)"
            (closed)="trocaSenhapanelOpenState.set(false)">
            <mat-expansion-panel-header>
              <mat-panel-title> Troca de Senha </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card-content>
              <div class="container-filds">
                <input-password [label]="'Nova senha'" [errorMessage]="'Informe a senha'"
                  [formControl]="form.controls.senha"></input-password>

                <input-password [label]="'Conf. da nova senha'" [errorMessage]="'Confirme a senha'"
                  [formControl]="form.controls.confirmar_senha"></input-password>

                <mat-dialog-actions align="end" style="padding-bottom: 15px">
                  <submit-button label="Salvar" [loading]="_userService.loading" [icon]="'save'"
                    style="margin-right: 10px" />
                </mat-dialog-actions>

                <div class="app-version">
                  <a class="app-version-text">
                    <mat-icon class="menu-icon">info</mat-icon>
                    <span style="text-align: right">Ao efetuar a troca de senha será necessário efetuar o
                      login novamente.</span>
                  </a>
                </div>
              </div>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>
        }

      </mat-card>
    </form>
  </div>
</div>