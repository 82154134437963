import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from './general-service';
import { ILocaleContact } from '../interfaces/locale-contact-interfaces';
import apiService from './api-service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LocaleClassesService } from './locale-classes-service';
import { ILocale } from '../interfaces/locale-interfaces';
import FuncionalidadesConst from '../shared/constants/FuncionalidadesConst';
import { FuncionalidadesService } from './funcionalidades-service';
import { ILog } from '../interfaces/log-interfaces';

const apiResource = 'Logs';

@Injectable()
export class LogsService {
  localeClassesService: LocaleClassesService | null = null;

  apiResource = apiResource;

  public loading: boolean = false;
  public list: ILog[] = [];

  public paginator: any;

  public search = this.formBuilder.group({
    id_localidade: '',
    funcionalidade_log: '',
    id_usuario: '',
    data_inicio: '',
    data_fim: '',
    sigla_icao: '',
  });

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService
  ) { }

  public async getAll() {
    try {
      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        this.apiResource
      );

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter logs', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async searchLocale(search: { [key: string]: string | null }) {
    try {
      this.loading = true;

      let queryString = '';

      for (const key of Object.keys(search))
        if (search[key] || String(search[key]) === '0')
          queryString += `${key}=${search[key]}&`;

      debugger

      const { data } = await apiService.get(
        `${this.apiResource}?${queryString}`
      );

      this.list = [...data['result']];
    } catch (error) {
      console.error(error);

      this.generalService.notify('Erro ao obter logs', 'negative');
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  public async clearSearch() {
    this.search.setValue({
      id_localidade: '',
      funcionalidade_log: '',
      id_usuario: '',
      data_inicio: '',
      data_fim: '',
      sigla_icao: '',
    });

    await this.getAll();
  }
}
