<form
  [formGroup]="repasseData"
  (ngSubmit)="submit()"
>
  <div class="header-dialog">
    <h2 class="title-dialog" mat-dialog-title>
      Repasse de valores
    </h2>

    <button
      type="button"
      mat-icon-button
      class="close-button-dialog"
      (click)="closeDialog()"
    >
      <mat-icon type="button" class="close-icon-dialog">
        close
      </mat-icon>
    </button>
  </div>

  <mat-dialog-content style="width:600px">
    <table class="full-width">
      <tr class="text-center">
        <td>
          Código do repasse a ser gerado:<br/>
          <b>{{ repasseData.controls.codigo_repasse.value }}</b>
        </td>

        <td>
          Data de referência do repasse:<br/>
          <b>{{ repasseData.controls.data_referencia_repasse.value }}</b>
        </td>
      </tr>
    </table>

    <div class="text-center" style="padding: 20px 0 10px 0">
      Valor do repasse (já descontada a taxa de administração<br/>
      vigente para a localidade, que é de {{ formatPercentage(repasseData.controls.taxa_percentual_localidade.value) }}%):<br/>
      <b>{{ repasseData.controls.valor_repasse.value }}</b>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="padding-bottom: 15px">
    <button
      type="button"
      style="margin-right: 15px; width: 110px;"
      mat-button
      mat-raised-button
      (click)="closeDialog()"
    >
      <mat-icon>close</mat-icon>
      Cancelar
    </button>

    <submit-button
      [loading]="saving"
      data-cy="submit-external-entity-button"
      label="Gravar repasse"
      icon="save"
      style="margin-right:10px;"
    />
  </mat-dialog-actions>
</form>
