import { Component } from '@angular/core';
import { SubmitButtonComponent } from '../../components/buttons/submit-button/submit-button.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../services/users-service';
import { Validators as ValidatorsCustom } from '../../shared/validators/Validators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, distinctUntilChanged, interval } from 'rxjs';
import { InputPasswordComponent } from '../../components/inputs/input-password/input-password.component';
import { GeneralService } from '../../services/general-service';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { GeneralConfirmDialog } from '../../components/dialogs/general-confirm-dialog/general-confirm-dialog.component';

@Component({
  selector: 'app-change-password-page',
  standalone: true,
  imports: [InputPasswordComponent, SubmitButtonComponent, SharedModule],
  templateUrl: './change-password-page.component.html',
  styleUrl: './change-password-page.component.scss',
})
export class ChangePasswordPageComponent {
  constructor(
    protected _userService: UsersService,
    protected _generalService: GeneralService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _activateRouter: ActivatedRoute,
    private _dialog: MatDialog
  ) { }

  form = this._formBuilder.group({
    senha: ['', [Validators.required]],
    confirmar_senha: ['', [Validators.required]],
  });

  formResetToken = this._formBuilder.group({
    email: [''],
    token: [''],
  });

  token: string = '';
  email: string = '';


  ngOnInit() {
    this.addValidators();

    this._activateRouter.queryParams.subscribe((params: any) => {
      this.token = params['token'] ?? '';
      this.email = params['email'] ?? '';
    });

    this.validateToken();
  }

  public async validateToken() {

    try {
      if (this.formResetToken.status === 'VALID') {
        const postData = {
          email: this.email,
          token: this.token,
        };

        var result = await this._userService.ValidateResetPasswordToken(postData);

        if (!result) {
          this._dialog.open(GeneralConfirmDialog, {
            data: {
              title: 'Link Expirado',
              description: 'Este link não é mais válido. Por favor, solicite um novo link para redefinir sua senha através da opção "Esqueci Minha Senha".',
              onConfirm: () => {
                this._router.navigate(['/login']);
                this._userService.clearToken();
              },
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  public async submit(e: Event) {
    e.preventDefault();
    try {
      if (this.form.status === 'VALID') {
        const postData = {
          senha: this.form.controls.senha?.value ?? '',
          email: this.email,
          token: this.token,
        };

        await this._userService.changePassword(postData);

        this._generalService.notify(
          'Troca de senha efetuada com sucesso.',
          'positive'
        );

        setTimeout(() => {
          this.backLogin();
        }, 1500);
      }
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }

  backLogin() {
    window.location.href = '/login';
  }

  private addValidators() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      const senha = this.form.get('senha') as FormControl;
      const confirmarSenha = this.form.get('confirmar_senha') as FormControl;

      if (senha.value) {
        this.form
          .get('confirmar_senha')
          ?.addValidators([ValidatorsCustom.notEqualText(senha)]);
      }

      if (confirmarSenha.value) {
        this.form
          .get('senha')
          ?.addValidators([ValidatorsCustom.notEqualText(confirmarSenha)]);

        senha.updateValueAndValidity({ emitEvent: false });
        confirmarSenha.updateValueAndValidity({ emitEvent: false });
      }
    });
  }
}
