import {AbstractControl, ValidationErrors} from "@angular/forms";
import {validCNPJ} from "./data-validation-service";

export class CustomValidateService {
  static CNPJ(control: AbstractControl): ValidationErrors | null {
    if (control.value.length == 0) return {needed: true};
    if (control.value.length < 14) return {invalid: true};
    return !validCNPJ(control.value) ? {nonexistent: true} : null;
  }

  static Time(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') return {needed: true};

    if (control.value.indexOf(':') === -1) return {invalid: true};

    const splitHour = control.value.split(':');

    if (!splitHour[0] || Number(splitHour[0]) > 23) return {invalid: true};

    if (!splitHour[1] || Number(splitHour[1]) > 59) return {invalid: true};

    return null;
  }

  static AfterArrival(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') return {needed: true};

    const arrival = control.parent?.get('data_pouso')?.value;

    if (arrival && control.value < arrival) return {invalid: true};

    return null;
  }

  static ArrivalTime(control: AbstractControl): ValidationErrors | null {
    const timeValidation = CustomValidateService.Time(control);

    if (timeValidation) return timeValidation;

    const arrivalDate = String(control.parent?.get('data_pouso')?.value);
    const departureDate = String(control.parent?.get('data_decolagem')?.value);
    const departureTime = control.parent?.get('hora_decolagem')?.value;

    if (arrivalDate === departureDate && departureTime) {
      const splitArrivalTime = control.value.split(':');
      const splitDepartureTime = departureTime.split(':');

      if (Number(splitDepartureTime[0]) < Number(splitArrivalTime[0])) return {before: true};

      if (
        Number(splitDepartureTime[0]) === Number(splitArrivalTime[0])
        && Number(splitDepartureTime[1]) <= Number(splitArrivalTime[1])
      ) return {before: true};
    }

    return null;
  }

  static DepartureTime(control: AbstractControl): ValidationErrors | null {
    const timeValidation = CustomValidateService.Time(control);

    if (timeValidation) return timeValidation;

    const arrivalDate = String(control.parent?.get('data_pouso')?.value);
    const departureDate = String(control.parent?.get('data_decolagem')?.value);
    const arrivalTime = control.parent?.get('hora_pouso')?.value;

    if (arrivalDate === departureDate && arrivalTime) {
      const splitDepartureTime = control.value.split(':');
      const splitArrivalTime = arrivalTime.split(':');

      if (Number(splitDepartureTime[0]) < Number(splitArrivalTime[0])) return {before: true};

      if (
        Number(splitDepartureTime[0]) === Number(splitArrivalTime[0])
        && Number(splitDepartureTime[1]) <= Number(splitArrivalTime[1])
      ) return {before: true};
    }

    return null;
  }

  static BeforeDeparture(control: AbstractControl): ValidationErrors | null {
    if (control.value === '') return {needed: true};

    const departure = control.parent?.get('data_decolagem')?.value;

    if (departure && control.value > departure) return {invalid: true};

    return null;
  }
}
