import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from './general-service';
import apiService from './api-service';
import { UsersService } from './users-service';
import MovesService from './moves-service';

@Injectable()
export class DatService {

  apiGerarDat = 'LocalidadeDat/gerar';

  public loading: boolean = false;

  constructor(
    private generalService: GeneralService,
    private usersService: UsersService,
    private movesServices: MovesService
  ) { }

  public async create(idMove: string, gerarPdf: boolean) {
    try {
      this.loading = true;

      const { data } = await apiService.post(`${this.apiGerarDat}/${idMove}`, {});

      return Promise.resolve(true);
    } catch (error: any) {
      this.generalService.extractUrlAndMessage(error)
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async getMoves() {
    if (
      this.usersService.currentLocale &&
      this.usersService.currentLocale.sigla_icao
    ) {
      await this.movesServices.getSingleLocaleMoves(
        this.usersService.currentLocale.sigla_icao
      );
    }
  }

}
