import { Routes } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ExternalEntitiesPageComponent } from './pages/external-entities-page/external-entities-page.component';
import { LocalesPageComponent } from './pages/locales-page/locales-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { AutenticacaoGuardService } from './shared/guards/autenticacao-guard.service';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { GroupsPageComponent } from './pages/groups-page/groups-page.component';
import { UserGroupsPageComponent } from './pages/user-groups-page/user-groups-page.component';
import { GroupFeaturesPageComponent } from './pages/group-features-page/group-features-page.component';
import { MovesPageComponent } from "./pages/moves-page/moves-page.component";
import { ChangePasswordPageComponent } from './pages/change-password-page/change-password-page.component';
import { UserSettingsPageComponent } from './pages/user-settings-page/user-settings-page.component';
import { RepassesPageComponent } from "./pages/repasses-page/repasses-page.component";
import { WriteDownValuesPageComponent } from './pages/write-down-values-page/write-down-values-page.component';
import { LogsPageComponent } from './pages/logs-page/logs-page.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Início',
        component: HomePageComponent,
      },
    ],
  },
  {
    path: 'login',
    children: [
      {
        path: '',
        title: 'Login',
        component: LoginPageComponent,
      },
    ],
  },
  {
    path: 'forgot-password',
    children: [
      {
        path: '',
        title: 'Esqueci Minha Senha',
        component: ForgotPasswordPageComponent,
      },
    ],
  },
  {
    path: 'change-password',
    children: [
      {
        path: '',
        title: 'Alterar Minha Senha',
        component: ChangePasswordPageComponent,
      },
    ],
  },
  {
    path: 'account',
    component: AuthLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: 'login',
        title: 'Entrar',
        component: LoginPageComponent,
      },
    ],
  },
  {
    path: 'locales',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Localidades',
        component: LocalesPageComponent,
      },
      {
        path: ':id',
        title: 'Localidades',
        component: LocalesPageComponent,
      },
    ],
  },
  {
    path: 'external-entities',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Entidades externas',
        component: ExternalEntitiesPageComponent,
      },
    ],
  },
  {
    path: 'moves',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Registro de movimentos',
        component: MovesPageComponent,
      }
    ],
  },
  {
    path: 'repasses',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Repasse de valores',
        component: RepassesPageComponent,
      },
    ],
  },
  {
    path: 'write-down-values',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Baixa de Valores',
        component: WriteDownValuesPageComponent,
      },
    ],
  },
  {
    path: 'users',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Cadastro de usuários',
        component: UsersPageComponent,
      },
    ],
  },
  {
    path: 'groups',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Cadastro de grupos',
        component: GroupsPageComponent,
      },
      {
        path: 'users',
        title: 'Associação de usuários a grupo',
        component: UserGroupsPageComponent,
      },
      {
        path: 'features',
        title: 'Associação de funcionalidades a grupo',
        component: GroupFeaturesPageComponent,
      },
      {
        path: 'user-settings',
        title: 'Configurações pessoais',
        component: UserSettingsPageComponent,
      },
    ],
  },
  {
    path: 'logs',
    component: MainLayoutComponent,
    canActivate: [AutenticacaoGuardService],
    children: [
      {
        path: '',
        title: 'Logs de cobrança à vista',
        component: LogsPageComponent,
      },
    ],
  },
];
