export const onlyNumbers = (value: string) => {
  if (!value) return '';

  return value.replace(/\D/g,'');
};

/**
 * Allows only number and commas (with only 2 decimals)
 * @param value
 */
export const numbersAndCommas = (value: string) => {
  if (!value) return '';

  const aux = value.replace(/[^\d,]+/g, '');

  const totalCommas = (aux.match(/,/g) || []).length;

  // remove exceeding comma
  if (totalCommas === 2) {
    return aux.slice(0, aux.length - 1);
  }

  // avoid more than 2 decimals
  if (aux.indexOf(',') !== -1) {
    const splat = aux.split(',');

    if (splat[1].length > 2) {
      return aux.slice(0, aux.length - 1);
    }
  }

  return aux;
};

export const twoDecimals = (value: string) => {
  let formattedString = onlyNumbers(value);

  if (formattedString.length > 2) {
    formattedString = `${formattedString.slice(0, formattedString.length-2)}.${formattedString.slice(formattedString.length-2, formattedString.length)}`;
  }

  return formattedString;
}

export const CNPJ = (value: string): string => {
  if (!value) return '';

  if (
    value.length < 14
    || (value.length < 18 && (value.includes('.') || value.includes('/')))
  ) return onlyNumbers(value);

  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5")
};

export const cep = (value: string) => onlyNumbers(value).replace(/(\d{5})(\d{3})/, "$1-$2");

export const latitude = (value: string) => {
  const newValue = value.replace(/[^0-9-]+/g, '');
  const negative = value.indexOf('-') === 0;

  if (negative) {
    if (newValue.length < 3) return newValue;

    const degrees = newValue.substring(1, 3);
    let minutes = newValue.substring(3, 5);
    minutes += minutes.length === 2 ? "'" : '';
    let seconds = newValue.substring(5, 7);
    seconds += seconds.length === 2 ? '"S' : '';

    return `${negative ? '-' : ''}${degrees}º${minutes ? `${minutes}` : ''}${seconds ? `${seconds}` : ''}`;
  }

  if (newValue.length < 2) return newValue;

  const degrees = newValue.substring(0, 2);
  let minutes = newValue.substring(2, 4);
  minutes += minutes.length === 2 ? "'" : '';
  let seconds = newValue.substring(4, 6);
  seconds += seconds.length === 2 ? '"N' : '';

  return `${negative ? '-' : ''}${degrees}º${minutes ? `${minutes}` : ''}${seconds ? `${seconds}` : ''}`;
};

export const longitude = (value: string) => {
  const newValue = value.replace(/[^0-9-]+/g, '');
  const negative = value.indexOf('-') === 0;

  if (negative) {
    if (newValue.length < 3) return newValue;

    const degrees = newValue.substring(1, 4);
    let minutes = newValue.substring(4, 6);
    minutes += minutes.length === 2 ? "'" : '';
    let seconds = newValue.substring(6, 8);
    seconds += seconds.length === 2 ? '"W' : '';

    return `${negative ? '-' : ''}${degrees}º${minutes ? `${minutes}` : ''}${seconds ? `${seconds}` : ''}`;
  }

  if (newValue.length < 3) return newValue;

  const degrees = newValue.substring(0, 3);
  let minutes = newValue.substring(3, 5);
  minutes += minutes.length === 2 ? "'" : '';
  let seconds = newValue.substring(5, 7);
  seconds += seconds.length === 2 ? '"E' : '';

  return `${negative ? '-' : ''}${degrees}º${minutes ? `${minutes}` : ''}${seconds ? `${seconds}` : ''}`;
};

export const paddingZeros = (num: string, size: number) => {
  num = num.toString();

  while (num.length < size) num = "0" + num;

  return num;
};
