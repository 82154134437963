<button type="button" [class]="btnCssClass" [attr.data-cy]="dataCy" mat-icon-button [matTooltip]="tooltip"
  [color]="color" [style]="{ 'top': label ? '-7px !important' : '0' }" [disabled]="disabled">
  @if (iconName) {
  <mat-icon [class]="iconCssClass">
    {{ iconName }}
  </mat-icon>
  }

  @if (label) {
  <div style="font-size:14px;font-weight:bold;position:relative;top:1px;left:-2px">
    {{ label }}
  </div>
  }
</button>