import {Component, OnInit} from '@angular/core';
import {
  FilteredAutoCompleteComponent
} from "../../auto-completes/filtered-auto-complete/filtered-auto-complete.component";
import {GeneralService} from "../../../services/general-service";
import {LocalesService} from "../../../services/locales-service";
import {FormBuilder, ReactiveFormsModule} from "@angular/forms";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {SharedModule} from "../../../shared/modules/shared.module";
import {SimpleDatepickerComponent} from "../../datepickers/simple-datepicker.component";
import {SubmitButtonComponent} from "../../buttons/submit-button/submit-button.component";
import {SelectIcaoComponent} from "../../selects/select-icao/select-icao.component";
import MovesService from "../../../services/moves-service";
import {ClearFilterButtonComponent} from "../../buttons/clear-filter-button/clear-filter-button.component";
import {UsersService} from "../../../services/users-service";
import {toISO8601} from "../../../services/date-service";
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'moves-search-bar',
  standalone: true,
  imports: [
    FilteredAutoCompleteComponent,
    MatGridList,
    MatGridTile,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SharedModule,
    SimpleDatepickerComponent,
    SubmitButtonComponent,
    SelectIcaoComponent,
    ClearFilterButtonComponent,
  ],
  templateUrl: './moves-search-bar.component.html',
})
export class MovesSearchBarComponent implements OnInit {
  constructor(
    protected generalService: GeneralService,
    protected localesService: LocalesService,
    protected movesService: MovesService,
    protected usersService: UsersService,
    private formBuilder: FormBuilder,
    private _funcionalidadesService: FuncionalidadesService
  ) {
  }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_REGISTROS_MOVIMENTOS,
    false
  );

  ngOnInit() {
    this.movesService.injectUsersService(this.usersService);
  }

  public search = this.formBuilder.group({
    sigla_icao: '',
    identificacao: '',
    matricula: '',
    periodo_inicio: '',
    periodo_fim: '',
  });

  public async submit() {
    await this.movesService.search({
      sigla_icao: this.search.controls.sigla_icao.value || '',
      identificacao: this.search.controls.identificacao.value || '',
      matricula: this.search.controls.matricula.value || '',
      periodo_inicio: toISO8601(this.search.controls.periodo_inicio.value || ''),
      periodo_fim: toISO8601(this.search.controls.periodo_fim.value || ''),
    });
  }

  async clearSearch() {
    this.search.patchValue({
      identificacao: '',
      matricula: '',
      periodo_inicio: '',
      periodo_fim: '',
    });

    // clear ICAO if not single locale user
    if (!this.usersService.singleLocale) {
      this.search.controls.sigla_icao.setValue('');
    }

    await this.movesService.getMoves();
  }
}
