<form style="width:860px" [formGroup]="move" (ngSubmit)="submit()">
  <div class="header-dialog">
    @if (data.move?.id_movimento) {
    <h2 class="title-dialog" mat-dialog-title>Editar movimento</h2>
    } @else {
    <h2 class="title-dialog" mat-dialog-title>Registrar movimento</h2>
    }

    <button mat-icon-button type="button" class="close-button-dialog" (click)="closeDialog()">
      <mat-icon type="button" class="close-icon-dialog">close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <mat-grid-list cols="4" rowHeight="80px" gutterSize="15">
      <mat-grid-tile>
        <mat-form-field class="full-width">
          <mat-label>Ident. do vôo</mat-label>

          <input data-cy="move-identificacao-input" type="text" maxlength="7" matInput formControlName="identificacao"
            [readonly]="readOnly" />
          <mat-error>Informe a identificação</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-form-field class="full-width">
          <mat-label>Matrícula</mat-label>

          <input data-cy="search-matricula-input" type="text" maxlength="10" matInput formControlName="matricula"
            [readonly]="readOnly" />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-form-field class="full-width">
          <mat-label>Tipo de aero.</mat-label>

          <input data-cy="search-tipo_aeronave-input" type="text" maxlength="4" matInput formControlName="tipo_aeronave"
            [readonly]="readOnly" />
          <mat-error>Informe o tipo de aeronave</mat-error>
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile>
        <mat-form-field class="full-width">
          <mat-label>PMD (ton.)</mat-label>
          <input data-cy="search-pmd-input" type="text" minlength="1" maxlength="6" matInput formControlName="pmd"
            [readonly]="readOnly" (input)="numbersAndCommas()" />
          <mat-error>Informe o PMD</mat-error>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-card style="margin-bottom: 5px;" appearance="outlined">
      <div class="text-center" style="padding:20px">Operação ARR (pouso)</div>

      <mat-card-content>
        <mat-grid-list cols="3" rowHeight="80px" gutterSize="15">
          <mat-grid-tile>
            <simple-datepicker class="full-width" label="Data de pouso"
              neededValidationErrorMessage="Informe a data de pouso"
              invalidValidationErrorMessage="Data de pouso posterior à decolagem"
              [disableToggle]="!usersService.singleLocale" [blockPastDays]="false"
              [externalControl]="move.controls.data_pouso" />
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>Hora de pouso</mat-label>

              <input data-cy="move-hora-pouso-input" type="text" maxlength="5" matInput mask-time [readonly]="readOnly"
                [formControl]="move.controls.hora_pouso" />

              @if (move.controls.hora_pouso.hasError('before')) {
              <mat-error data-cy="invalid-hora-decolagem-warning">
                Hora de pouso é posterior à decolagem
              </mat-error>
              }

              @if (move.controls.hora_pouso.hasError('invalid')) {
              <mat-error data-cy="invalid-hora-decolagem-warning">
                Hora de pouso inválida
              </mat-error>
              }

              @if (move.controls.hora_pouso.hasError('needed')) {
              <mat-error data-cy="needed-hora-decolagem-warning">
                Informe a hora de pouso
              </mat-error>
              }
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>Origem (ICAO)</mat-label>
              <input [readonly]="!usersService.singleLocale" data-cy="move-origem-icao-input" type="text" maxLength="4"
                minLength="4" matInput formControlName="origem_icao" class="uppercase-input" />
              <mat-error>Informe a origem</mat-error>
            </mat-form-field>
          </mat-grid-tile>


        </mat-grid-list>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <div class="text-center" style="padding:20px">Operação DEP (decolagem)</div>

      <mat-card-content>
        <mat-grid-list cols="3" rowHeight="80px" gutterSize="15">
          <mat-grid-tile>
            <simple-datepicker label="Data de decolagem" neededValidationErrorMessage="Informe a data de decolagem"
              invalidValidationErrorMessage="Data de decolagem anterior ao pouso"
              [disableToggle]="!usersService.singleLocale" [blockPastDays]="false"
              [externalControl]="move.controls.data_decolagem" />
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>Hora de decolagem</mat-label>

              <input data-cy="move-hora-decolagem-input" type="text" maxlength="5" matInput mask-time
                [readonly]="readOnly" [formControl]="move.controls.hora_decolagem" />

              @if (move.controls.hora_decolagem.hasError('before')) {
              <mat-error data-cy="invalid-hora-decolagem-warning">
                Hora de decolagem é anterior ao pouso
              </mat-error>
              }

              @if (move.controls.hora_decolagem.hasError('invalid')) {
              <mat-error data-cy="invalid-hora-decolagem-warning">
                Hora de decolagem inválida
              </mat-error>
              }

              @if (move.controls.hora_decolagem.hasError('needed')) {
              <mat-error data-cy="needed-hora-decolagem-warning">
                Informe a hora de decolagem
              </mat-error>
              }
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field class="full-width">
              <mat-label>Destino (ICAO)</mat-label>
              <input [readonly]="!usersService.singleLocale" data-cy="move-destino-icao-input" type="text" maxLength="4"
                minLength="4" matInput formControlName="destino_icao" class="uppercase-input" />
              <mat-error>Informe o destino</mat-error>
            </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="padding-bottom: 15px">
    @if (usersService.singleLocale) {
    <button type="button" style="margin-right:9px; width: 110px;" mat-button mat-raised-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
      Cancelar
    </button>

    <submit-button data-cy="submit-move-button" label="Salvar" [loading]="saving" icon="save"
      style="margin-right:10px;" />
    } @else {
    <button type="button" style="width: 120px" mat-raised-button (click)="closeDialog()">
      <mat-icon>arrow_back</mat-icon>
      Voltar
    </button>
    }
  </mat-dialog-actions>
</form>