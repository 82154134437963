import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle} from "@angular/material/dialog";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

import {DialogData} from "../external-entity-dialog/external-entity-dialog.component";
import {LocaleTaxFormComponent} from "../../forms/locale-tax-form/locale-tax-form.component";
import {LocaleTaxesService} from "../../../services/locale-taxes-service";
import {LocaleTaxesTableComponent} from "../../tables/locale-taxes-table/locale-taxes-table.component";
import {IconButton} from "../../buttons/icon-button/icon-button.component";
import {
  LocaleClassesCreatingTableComponent
} from "../../tables/locale-classes-creating-table/locale-classes-creating-table.component";
import {LocaleClassesTableComponent} from "../../tables/locale-classes-table/locale-classes-table.component";
import {LocalesService} from "../../../services/locales-service";
import {
  LocaleTaxesCreatingTableComponent
} from "../../tables/locale-taxes-creating-table/locale-taxes-creating-table.component";

@Component({
  selector: 'locale-taxes-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    LocaleTaxFormComponent,
    LocaleTaxesTableComponent,
    MatProgressSpinner,
    IconButton,
    LocaleClassesCreatingTableComponent,
    LocaleClassesTableComponent,
    LocaleTaxesCreatingTableComponent
  ],
  templateUrl: './locale-taxes-dialog.component.html',
  styleUrl: './locale-taxes-dialog.component.scss'
})

export class LocaleTaxesDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LocaleTaxesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected localesService: LocalesService,
    protected localeTaxesService: LocaleTaxesService,
  ) {
  }

  ngOnInit() {
    setTimeout(() => { this.localeTaxesService.clearingForm = false; }, 500);
  }

  closeDialog(reloadExternalEntities = false): void {
    this.dialogRef.close(reloadExternalEntities);
  }
}
