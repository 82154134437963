import {Component, inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogActions} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";

import {GeneralService} from "../../services/general-service";
import {InputTextComponent} from "../../components/inputs/input-text/input-text.component";
import {ExternalEntityDialog} from "../../components/dialogs/external-entity-dialog/external-entity-dialog.component";
import {ExternalEntitiesService, IExternalEntity} from "../../services/external-entities-service";
import {
  ExternalEntitiesTable,
} from "../../components/tables/external-entities-table/external-entities-table.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SubmitButtonComponent} from "../../components/buttons/submit-button/submit-button.component";
import {MatFormField, MatInput, MatLabel} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatCardContent} from "@angular/material/card";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {SearchEntityBarComponent} from "../../components/bars/search-entity-bar/search-entity-bar.component";
import { MatIcon } from '@angular/material/icon';
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'app-external-entities-page',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextComponent,
    ExternalEntitiesTable,
    MatButton,
    MatDialogActions,
    SubmitButtonComponent,
    MatInput,
    MatFormField,
    MatProgressSpinner,
    MatLabel,
    MatCardContent,
    MatGridList,
    MatGridTile,
    SearchEntityBarComponent,
    MatIcon
  ],
  templateUrl: './external-entities-page.component.html',
  styleUrl: './external-entities-page.component.scss'
})

export class ExternalEntitiesPageComponent implements OnInit {
  externalEntity: IExternalEntity | null = null;
  protected generalService = inject(GeneralService);

  constructor(
    public externalEntitiesService: ExternalEntitiesService,
    public dialog: MatDialog,
    private funcionalidadesService: FuncionalidadesService
  ) {}

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_ENTIDADES_EXTERNAS,
    false
  );

  async ngOnInit() {
    this.generalService.setExtraTitle('Cadastros Básicos - Entidades externas');

    await this.externalEntitiesService.getAll();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ExternalEntityDialog, {
      data: {externalEntity: this.externalEntity},
    })

    dialogRef.afterClosed().subscribe((reloadExternalEntities) => {
      if (reloadExternalEntities) {
        void this.externalEntitiesService.getAll();
      }
    })
  }
}
