@if (!dataSource.data.length) {
<mat-card>
  <mat-card-content>Nenhum log encontrado.</mat-card-content>
</mat-card>
} @else {
<table mat-table [dataSource]="dataSource" class="mat-elevation-z1 highlight-table">
  <ng-container matColumnDef="localidade">
    <th mat-header-cell *matHeaderCellDef>Localidade (ICAO)</th>
    <td mat-cell *matCellDef="let log">{{ log.localidade || "DECEA" }}</td>
  </ng-container>

  <ng-container matColumnDef="funcionalidade">
    <th mat-header-cell *matHeaderCellDef>Funcionalidade/ação</th>
    <td mat-cell *matCellDef="let log">
      {{ log.nome_funcionalidade }}
    </td>
  </ng-container>

  <ng-container matColumnDef="usuario">
    <th mat-header-cell *matHeaderCellDef>Usuário</th>
    <td mat-cell *matCellDef="let log">
      {{ log.login_usuario }}
    </td>
  </ng-container>

  <ng-container matColumnDef="registro">
    <th mat-header-cell *matHeaderCellDef>Registro</th>
    <td mat-cell *matCellDef="let log">
      {{ log.representante_registro }}
    </td>
  </ng-container>

  <ng-container matColumnDef="data">
    <th mat-header-cell *matHeaderCellDef>Data</th>
    <td mat-cell *matCellDef="let log">
      {{ getData(log.data) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="hora">
    <th mat-header-cell *matHeaderCellDef>Hora</th>
    <td mat-cell *matCellDef="let log">
      {{ getHora(log.data) }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9" style="padding: 15px">
      Nenhum log encontrada
    </td>
  </tr>
</table>

@if (generalService.itemsPerPage < dataSource.data.length) { <mat-paginator showFirstLastButtons hidePageSize
  [pageSizeOptions]="[5, 10, 20]" [pageSize]="generalService.itemsPerPage"></mat-paginator>
  } }