import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { GeneralService } from '../../../services/general-service';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { ClearFilterButtonComponent } from '../../buttons/clear-filter-button/clear-filter-button.component';
import { BooleanRadioComponent } from '../../radios/boolean-radio/boolean-radio.component';
import { SharedModule } from '../../../shared/modules/shared.module';
import { MatDialog } from '@angular/material/dialog';
import { GroupsService } from '../../../services/groups-service';
import { GroupDialog } from '../../dialogs/group-dialog/group-dialog.component';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { UsersService } from '../../../services/users-service';

@Component({
  selector: 'group-search-bar',
  standalone: true,
  imports: [
    SubmitButtonComponent,
    ClearFilterButtonComponent,
    BooleanRadioComponent,
    SharedModule,
  ],
  templateUrl: './group-search-bar.component.html',
  styleUrl: './group-search-bar.component.scss',
})
export class GroupSearchBarComponent {
  constructor(
    protected generalService: GeneralService,
    protected _groupsService: GroupsService,
    protected _funcionalidadesService: FuncionalidadesService,
    private _dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _userService: UsersService
  ) { }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_GRUPOS,
    false
  );

  search = this.formBuilder.group({
    nm_group: '',
    ativo: ''
  });

  protected async submit() {
    await this._groupsService.search(this.search.value);
  }

  protected showModalNovoGroup() {
    this._dialog.open(GroupDialog);
  }

  protected clearSearch() {
    this.search.setValue({
      nm_group: '',
      ativo: ''
    });

    this._groupsService.getAll();
  }
}
