import { Component, Input, ViewChild } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { ICheck } from '../../../interfaces/check-interface';
import { MatSelectionList } from '@angular/material/list';
import { GroupFeaturesService } from '../../../services/group-features-service';

@Component({
  selector: 'list-check',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './list-check.component.html',
  styleUrl: './list-check.component.scss',
})
export class ListCheckComponent {
  @ViewChild('listCheck') listCheck: MatSelectionList | undefined;

  @Input() itens: ICheck[] = [];
  @Input() title: string = '';
  @Input() chooseReadOnly: boolean = false;
  @Input() height: number = 500;
  @Input() readOnly: boolean = false;
  @Input() loading: boolean = false;

  constructor(private groupFeaturesService: GroupFeaturesService) { }

  selectOption(id: number) {
    if (this.readOnly) return;

    this.itens.forEach((item) => {
      if (item.id == id) {
        item.check = !item?.check;
      }
    });
  }

  setReadOnly(event: any, featureId: number) {
    event.stopPropagation();

    if (this.readOnly) return;

    for (const selectedFeature of this.groupFeaturesService.selectedFeatures) {
      if (selectedFeature.id === featureId) {
        selectedFeature.readOnly = !selectedFeature.readOnly;
      }
    }
  }

  get heigthCss() {
    return `min-height: ${this.height}px;max-height: ${this.height}px;`;
  }
}
