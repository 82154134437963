import {Component, Inject} from '@angular/core';

import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatGridListModule} from '@angular/material/grid-list';

import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogClose,
  MatDialogTitle,
  MatDialogRef,
} from "@angular/material/dialog";

import {InputTextComponent} from "../../inputs/input-text/input-text.component";
import {SubmitButtonComponent} from "../../buttons/submit-button/submit-button.component";
import {IconButton} from "../../buttons/icon-button/icon-button.component";
import { MatIcon } from '@angular/material/icon';

export interface DialogData {
  label: string,
  id: number,
  name: string,
}

@Component({
  selector: 'general-remove-dialog',
  standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        InputTextComponent,
        MatGridListModule,
        SubmitButtonComponent,
        ReactiveFormsModule,
        IconButton,
        MatIcon
    ],
  templateUrl: './general-remove-dialog.component.html',
  styleUrl: './general-remove-dialog.component.scss',
})

export class GeneralRemoveDialog {
  dialogData: DialogData;
  removing = false;

  constructor(
    public dialogRef: MatDialogRef<GeneralRemoveDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.dialogData = {...data};
  }

  public async submit() {
    this.dialogRef.close(this.dialogData.id);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
