<form (ngSubmit)="submit()">
  <h2 mat-dialog-title>{{ data.title }}</h2>

  <mat-dialog-content>
    <div *ngIf="data.isHtml" [innerHTML]="data.description"></div>
    <div *ngIf="!data.isHtml">{{ data.description }}</div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <submit-button label="Ok" [loading]="loading" />
  </mat-dialog-actions>
</form>
