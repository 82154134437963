const FuncionalidadesConst = {
  MANUTENCAO_DE_LOCALIDADES_INFORMACOES_GERAIS: 3,
  MANUTENCAO_DE_LOCALIDADES_CONFIGURACOES_TARIFARIAS: 4,
  MANUTENCAO_DE_LOCALIDADES_CONTATOS: 5,
  MANUTENCAO_DE_USUARIOS: 7,
  MANUTENCAO_DE_GRUPOS: 8,
  ASSOCIACAO_DE_USUARIOS_A_GRUPO: 9,
  ASSOCIACAO_DE_FUNCIONALIDADES_A_GRUPO: 10,
  CONFIGURACOES_PESSOAIS: 11,
  MANUTENCAO_DE_ENTIDADES_EXTERNAS: 12,
  MANUTENCAO_DE_REGISTROS_MOVIMENTOS: 13,
  LOGS: 14,
  BAIXA_DE_VALORES: 15,
};

export default FuncionalidadesConst;
