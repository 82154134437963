import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { UsersService } from '../../services/users-service';
import { MatDialog } from '@angular/material/dialog';
import { GeneralConfirmDialog } from '../../components/dialogs/general-confirm-dialog/general-confirm-dialog.component';
import { MenuService } from '../../services/menu-service';
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../constants/FuncionalidadesConst';
import { FormBuilder } from '@angular/forms';
import { GroupsService } from '../../services/groups-service';

@Injectable({ providedIn: 'root' })
export class AutenticacaoGuardService implements CanActivate {
  constructor(
    private _router: Router,
    private _userService: UsersService,
    private _menuService: MenuService,
    private _funcionalidadesService: FuncionalidadesService,
    private _dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _groupsService: GroupsService
  ) { }

  private urlsAllow = ['/'];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.checkZoom();
      return Promise.all([this.canAccess(route, state)])
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.checkZoom();
      return Promise.all([this.canAccess(route, state)])
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async canAccess(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      let logged = true;

      let token = this._userService.getToken();

      if (!token?.token) logged = false;

      if (logged) {
        const tokenDecode = jwtDecode(token['token']);

        // CARREGAR DADOS DO USUARIO
        await this._userService.setUser(tokenDecode);
       
        // //CARREGAR LOCALIDADES DO USUARIO
        await this._userService.setUserLocale();
       
        // CARREGAR GRUPOS DO USUARIOS
        if (!this._userService.user.DECEA) {
          let search = this.formBuilder.group({
            id_usuario: this._userService.user.usuarioId.toString() || '0'
          });
          await this._groupsService.search(search.value);
        } else {
          this._groupsService.getAll();
        }


        if (tokenDecode?.exp && !(Date.now() <= tokenDecode?.exp * 1000)) {
          this._dialog.open(GeneralConfirmDialog, {
            data: {
              title: 'Sessão expirada',
              description: 'O tempo da sua sessão terminou, realize o login novamente.',
              onConfirm: () => {
                this._router.navigate(['/login']);
                this._userService.clearToken();
              },
            },
          });

          return;
        }
      }

      if (!logged) {
        this._router.navigate(['/login']);
        this._userService.clearToken();
        return reject();
      }

      if (
        this._funcionalidadesService.canAccess(
          FuncionalidadesConst.CONFIGURACOES_PESSOAIS
        )
      ) {
        this.urlsAllow.push('/groups/user-settings');
      }

      const { url } = state;

      if (!(this._menuService.subMenus.length > 0)) {
        await this._menuService.getAll();
      }

      if (
        this._menuService.subMenus.length > 0 &&
        !this.urlsAllow.includes(url) &&
        !this._menuService.subMenus.some((s) => s.rota == url)
      ) {
        this._router.navigate(['/']);
        return reject();
      }

      return resolve();
    });
  }

  checkZoom() { }
}
