import { Component, Inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import MovesService, { IRepasseData } from "../../../services/moves-service";
import { toBrazilianDate, toUSADate } from "../../../services/date-service";
import { RepassesService } from "../../../services/repasses-service";
import { GeneralService } from "../../../services/general-service";
import { paddingZeros } from "../../../services/data-transform";
import { ILocaleTax } from "../../../interfaces/locale-taxes-interfaces";

@Component({
  selector: 'create-gru-dialog',
  standalone: true,
  templateUrl: './create-gru-dialog.component.html',
  imports: [
    ReactiveFormsModule,
    MatDialogContent,
    MatButton,
    MatDialogActions,
    MatIcon,
    SubmitButtonComponent,
    MatDialogTitle,
    MatGridList,
    MatGridTile,
    MatIconButton,
  ],
  styleUrl: './create-gru-dialog.component.scss'
})
export class CreateGruDialogComponent {
  protected repasseData = this.formBuilder.group({
    codigo_repasse: '',
    data_referencia_repasse: '',
    valor_repasse: '',
    taxa_percentual_localidade: 0,
  });

  saving = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateGruDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { repass: IRepasseData, localeTax: ILocaleTax },
    protected generalService: GeneralService,
    protected movesService: MovesService,
    protected grusService: RepassesService,
  ) {
    const { repass, localeTax } = data;

    // Verificação e conversão segura de 'percentual_taxa'
    let floatTax = 0;
    if (localeTax.percentual_taxa) {
      floatTax = parseFloat(localeTax.percentual_taxa.replace(',', '.'));
    }

    const totalValue = parseFloat(repass.valor_repasse);

    const discount = (floatTax * totalValue) / 100;

    const totalValueAfterDiscount = totalValue - discount;

    const formattedValueAfterDiscount = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValueAfterDiscount);

    this.repasseData.setValue({
      codigo_repasse: paddingZeros(repass.cod_repasse, 6),
      data_referencia_repasse: toBrazilianDate(repass.data_referencia_repasse),
      valor_repasse: formattedValueAfterDiscount,
      taxa_percentual_localidade: floatTax,
    });

  }

  protected async submit() {
    try {
      this.saving = true;

      const calculo = await this.movesService.calculateRepass();
      console.log(calculo)


      if (
        this.repasseData.controls.codigo_repasse.value
        && this.repasseData.controls.data_referencia_repasse.value
        && this.repasseData.controls.valor_repasse.value
      ) {
        const response = await this.grusService.saveRepass({
          cod_repasse: Number(this.repasseData.controls.codigo_repasse.value),
          data_referencia_repasse: new Date(toUSADate(this.repasseData.controls.data_referencia_repasse.value)),
          valor_repasse: this.repasseData.controls.valor_repasse.value,
        });

        await this.movesService.updateMovesRepasseId(response.data.result.cod_repasse);

        this.movesService.resetMovesRepasse();

        setTimeout(() => {
          this.saving = false;

          this.closeDialog(true);
        }, 1000);
      } else {
        this.generalService.notify('Não possível gerar o repasse', 'negative');

        this.saving = false;
      }
    } catch (error) {
      this.generalService.notify('Ocorreu um erro ao gerar o repasse', 'negative');

      this.saving = false;
    }
  }

  protected closeDialog(reloadGRUs = false): void {
    this.dialogRef.close(reloadGRUs);
  }

  protected formatPercentage(value: number | null) {
    if (!value) return '';

    return value.toFixed(2).toString().replace('.', ',');
  }
}
