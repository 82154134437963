<div class="main-pdf">
  <div #pdfContent class="container-pdf">
    <div class="container">
      <div class="title">
        <h1>DECEA / ATAN</h1>
        <strong>
          DAT - Documento de Arrecadação de Tarifas – Navegação Aérea
        </strong>
        <br />
        <strong>Tariffs Collect Document</strong>
      </div>

      <table class="table-pdf table-infos">
        <thead>
          <th>Aeroporto Airport</th>
          <th>Natureza Voo</th>
          <th>Marcas Nac/Matricula Aircraft Registration</th>
          <th>Tipo Padronizado Aircraft Type</th>
          <th>PMD Weight</th>
          <th>Grupo tarifário</th>
          <th width="300px">Nº DAT</th>
        </thead>

        <tr>
          <td>{{move.icao}}</td>
          <td>{{move.natureza}}</td>
          <td>{{move.matricula}}</td>
          <td>{{move.tipo_aeronave}}</td>
          <td>{{move.pmd}}</td>
          <td>{{move.grupo}}</td>
          <td>{{nroDAT}}</td>
        </tr>

        <tr>
          <th colspan="3">Pouso <br />(Landing)</th>
          <th colspan="3">Decolagem <br />(Take Off)</th>
          <td class="table-row-border-none"></td>
        </tr>

        <thead>
          <th>Data/Date</th>
          <th>Hora/Hour</th>
          <th>Proced /Origin</th>
          <th>Data/Date</th>
          <th>Hora/Hour</th>
          <th>Destino /Destination</th>
          <th class="table-row-border-none"></th>
        </thead>

        <tr>
          <td>{{formatDate(move.data_pouso)}}</td>
          <td>{{move.hora_pouso}}</td>
          <td>{{move.origem_icao}}</td>
          <td>{{formatDate(move.data_decolagem)}}</td>
          <td>{{move.hora_decolagem}}</td>
          <td>{{move.destino_icao}}</td>
          <td class="table-row-border-none"></td>
        </tr>
      </table>

      <table class="table-pdf table-tarifas">
        <thead>
          <tr>
            <th width="500px">Tarifas/Tariffs</th>
            <th>Valores/Values (US$)</th>
            <th>Valores/Values (R$)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trecho entrada (landing)</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="td-left">TAN</td>
            <td>{{this.move.tan_origem_dolar}}</td>
            <td>{{this.move.tan_origem}}</td>
          </tr>
          <tr>
            <td class="td-left">TAT-APP</td>
            <td>{{this.move.tatApp_origem_dolar}}</td>
            <td>{{this.move.tatApp_origem}}</td>
          </tr>
          <tr>
            <td class="td-left">TAT-ADR</td>
            <td>{{this.move.tatAdr_origem_dolar}}</td>
            <td>{{this.move.tatAdr_origem}}</td>
          </tr>
          <tr>
            <td>Trecho saida (take off)</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td class="td-left">TAN</td>
            <td>{{this.move.tan_destino_dolar}}</td>
            <td>{{this.move.tan_destino}}</td>
          </tr>
          <tr>
            <td class="td-left">TAT-APP</td>
            <td>{{this.move.tatApp_destino_dolar}}</td>
            <td>{{this.move.tatApp_destino}}</td>
          </tr>
          <tr>
            <td class="td-left">TAT-ADR</td>
            <td>{{this.move.tatAdr_destino_dolar}}</td>
            <td>{{this.move.tatAdr_destino}}</td>
          </tr>
          <tr>
            <td class="total">Total</td>
            <td>{{this.move.total_dolar}}</td>
            <td>{{this.move.total}}</td>
          </tr>
        </tbody>
      </table>

      <table class="table-pdf table-assinatura">
        <tr>
          <th>Proprietario/Explorador / Aircraft Owner/Operator</th>
          <th>Assinatura / Signature</th>
        </tr>

        <tr>
          <td></td>
          <td></td>
        </tr>
      </table>

      <table class="table-pdf table-infos-complementar">
        <tr>
          <th colspan="2">Informações Complementares / Additional Information</th>
          <th>Observações / Remarks </th>
        </tr>

        <tr>
          <td>Cotação / Price US$: {{move.cotacao_dolar}}</td>
          <td>Data / Date US$: {{move.data_cotacao_dolar}}</td>
          <td></td>
        </tr>
      </table>

      <table class="table-pdf table-autenticacao">
        <tr>
          <th>Autenticação / Authentication</th>
          <th>Aeroporto / Airport</th>
        </tr>
        <tr>
          <td></td>
          <td>{{move.localidade_dat}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>