<div>
    <div class="container">
        <img src="./assets/logo.jpg" alt="Loading..." style="height: 150px" />
        <img src="./assets/logo.png" alt="Loading..." style="height: 80px" />

        <h1 class="title">Portal Localidades</h1>

        <form [formGroup]="form" (submit)="submit($event)">
            <mat-card class="container-card">
                <mat-card-content>
                    <div class="container-filds">
                        <mat-form-field style="min-width: 400px">
                            <mat-label>E-mail</mat-label>

                            <input matInput formControlName="email" />
                            <mat-error>Informe o e-mail</mat-error>
                        </mat-form-field>

                        <a class="button-link">
                            Digite seu e-mail de login para que possamos enviar para você um link
                            de recuperação de senha.
                        </a>

                        <mat-dialog-actions align="end" style="padding-bottom: 15px">
                            <button type="button" (click)="backLogin()" style="margin-right:9px; width: 110px;"
                                mat-button mat-raised-button>
                                <mat-icon>close</mat-icon> Cancelar
                            </button>

                            <submit-button label="Enviar" [loading]="_userService.loading" [icon]="'send'"
                                style="margin-right:10px;" />
                        </mat-dialog-actions>

                    </div>
                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>