<div>
  <div class="container">
    <img src="./assets/logo.jpg" width="130" height="150" />
    <img src="./assets/logo.png" width="350" height="100" />

    <h1 class="title">Portal Localidades</h1>

    <form [formGroup]="form" (submit)="submit($event)">
      <mat-card class="container-card">
        <mat-card-content>
          <div class="container-filds">
            <input-password [label]="'Nova senha'" [errorMessage]="'Informe a senha'"
              [formControl]="form.controls.senha"></input-password>

            <input-password [label]="'Conf. da nova senha'" [errorMessage]="'Confirme a senha'"
              [formControl]="form.controls.confirmar_senha"></input-password>

            <mat-dialog-actions align="end" style="padding-bottom: 15px">
              <button type="button" (click)="backLogin()" style="margin-right: 9px; width: 110px" mat-button
                mat-raised-button>
                <mat-icon>close</mat-icon> Cancelar
              </button>

              <submit-button label="Enviar" [loading]="_userService.loading" [icon]="'send'"
                style="margin-right: 10px" />
            </mat-dialog-actions>
          </div>

        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>