import { Component, inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import { MatDialog } from '@angular/material/dialog';
import { GeneralService } from '../../../services/general-service';
import { MatPaginator } from '@angular/material/paginator';
import { SharedModule } from '../../../shared/modules/shared.module';
import { UsersService } from '../../../services/users-service';
import { IUser } from '../../../interfaces/user-interfaces';
import { GeneralRemoveDialog } from '../../dialogs/general-remove-dialog/general-remove-dialog.component';
import { CreateUserDialog } from '../../dialogs/create-user-dialog/create-user-dialog.component';
import { GeneralConfirmDialog } from '../../dialogs/general-confirm-dialog/general-confirm-dialog.component';
import { IAutoCompleteOption } from '../../../interfaces/auto-complete-interfaces';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import { ComboService } from '../../../services/combo-service';

@Component({
  selector: 'users-table',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './users-table.component.html',
  styleUrl: './users-table.component.scss',
})
export class UsersTableComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'nm_usuario',
    'ativo',
    'email',
    'telefone',
    'organizacao',
    'actions',
  ];

  dataSource = new MatTableDataSource<IUser>(this._userService.list);
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected _generalService: GeneralService,
    protected _comboService: ComboService,
    protected _userService: UsersService,
    private _dialog: MatDialog,
    private funcionalidadesService: FuncionalidadesService
  ) {}

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_USUARIOS,
    false
  );

  get localidades(): Record<number, string> {
    var locales = this._comboService.localidadesAll;

    return locales.reduce((acc: Record<number, string>, l) => {
      acc[l.id as number] = l.value;
      return acc;
    }, {} as Record<number, string>);
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public getDescriptionOrganization(user: IUser) {
    if (user.DECEA === true)
      return 'DECEA - Departamento de Controle do Espaço Aéreo';

    if (user.locales.length == 1 && this.localidades[user.locales[0]] != null)
      return this.localidades[user.locales[0]];

    if (user.locales.length > 1) return 'Usuário em mais de uma localidade';
    return ' - ';
  }

  public editUser(user: IUser) {
    this._dialog.open(CreateUserDialog, {
      data: { user },
    });
  }

  public removeUser(user: IUser) {
    const dialogRef = this._dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'o usuário',
        id: user.id_usuario,
        name: `${user.nm_usuario} - ${user.email}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          await this._userService.delete(removeId);

          this._generalService.notify('Usuário removido', 'positive');

          await this._userService.clearSearch();
        } catch (error) {
          this._generalService.notify('Erro ao remover usuário', 'negative');
        }
      }
    });
  }

  public showLocales(user: IUser) {
    const localidadesHtml = user.locales
      .map((localidade) => this.localidades[localidade])
      .map((localidade) => `<li>${localidade}</li>`)
      .join(' ');

    this._dialog.open(GeneralConfirmDialog, {
      data: {
        title: `Localidades vinculadas ao usuário ${user.nm_usuario}`,
        description: `<ul>${localidadesHtml}</ul>`,
        isHtml: true,
        onConfirm: () => {},
      },
    });
  }
}
