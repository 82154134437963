<form class="container-locale-contatc-new" [formGroup]="localesService.localeContact" (ngSubmit)="submit()"
  ngNoValidate>
  @if(!this.isReadonly){
  <button mat-flat-button color="primary" (click)="localesService.createNewContact()" style="
      position: absolute;
      top: -10px;
      right: 17px;
      margin-top: 10px;
      margin-bottom: 50px;
    ">
    <mat-icon>add</mat-icon> Incluir Contato
  </button>
  }

  <mat-grid-list cols="6" rowHeight="80px" gutterSize="15">
    <mat-grid-tile colspan="3">
      <mat-form-field class="full-width">
        <mat-label>Nome do Contato</mat-label>
        <input type="text" maxlength="150" matInput formControlName="nm_contato" />
        <mat-error>Informe o nome do contato</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input type="email" maxlength="50" matInput formControlName="email" />
        <mat-error *ngIf="localesService.localeContact.get('email')?.errors?.['required']">Informe o email</mat-error>
        <mat-error *ngIf="localesService.localeContact.get('email')?.errors?.['email']">Email inválido</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    @if (localesService.localeContact.value.id_contato) {
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <mat-label>Última atualização</mat-label>
        <input type="text" matInput formControlName="data_ultima_atualizacao" readonly />
      </mat-form-field>
    </mat-grid-tile>
    }
  </mat-grid-list>

  <mat-grid-list cols="8" rowHeight="80px" gutterSize="15">
    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Tipo Contato</mat-label>
        <mat-select formControlName="id_tipo_contato">
          @for (currentClass of _comboService.tiposContatos; track currentClass)
          {
          <mat-option [value]="currentClass.id">{{ currentClass.value }}
          </mat-option>
          }
        </mat-select>
        <mat-error>Informe o tipo de contato</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Cargo / Função do contato</mat-label>
        <input type="text" maxlength="50" matInput formControlName="cargo_funcao" />
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Telefone Principal</mat-label>
        <input type="text" maxlength="15" matInput formControlName="telefone_principal" mask-phone />

        <mat-error>Informe o telefone principal</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Telefones Secundarios</mat-label>
        <input type="text" maxlength="70" matInput formControlName="telefones_secundarios" mask-phone />
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>

  <mat-grid-list cols="4" rowHeight="80px" gutterSize="15">
    <mat-grid-tile colspan="2">
      <mat-form-field class="full-width">
        <mat-label>Empresa</mat-label>
        <mat-select formControlName="id_entidade_externa">
          @for (currentClass of _comboService.entidadesExternas; track
          currentClass) {
          <mat-option [value]="currentClass.id">{{ currentClass.value }}
          </mat-option>
          }
        </mat-select>
        <mat-error>Informe a empresa</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile colspan="1">

      <mat-radio-group formControlName="contato_ativo" class="full-width" color="primary">
        <mat-label class="active-radio-label"> Contato Ativo?</mat-label>
        <mat-radio-button value="1">Sim</mat-radio-button>
        <mat-radio-button value="0">Não</mat-radio-button>
      </mat-radio-group>
    </mat-grid-tile>

    @if (localesService.localeContact.value.id_contato) {
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <mat-label>Data Inclusão</mat-label>
        <input type="text" matInput formControlName="data_inclusao" readonly />
      </mat-form-field>
    </mat-grid-tile>
    }
  </mat-grid-list>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Comentários</mat-label>
      <textarea matInput rows="3" maxlength="500" formControlName="comentarios"></textarea>
      <div style="font-size: 12px; text-align: right">
        Caracteres digitados:
        {{ localesService.localeContact.value.comentarios?.length }} / 500
      </div>
    </mat-form-field>
  </div>

  <div class="full-width text-center" style="margin-bottom: 10px">
    @if(!this.isReadonly){
    <submit-button style="margin-right: 10px; width: 120px !important" [icon]="'save'" label=" Salvar"
      [loading]="localesService.saving">
    </submit-button>
    }

    <button type="button" style="width: 120px" mat-raised-button (click)="openCancelDialog()">
      <mat-icon>{{ !this.isReadonly ? "close" : "arrow_back" }}</mat-icon>
      {{ !this.isReadonly ? "Cancelar" : "Voltar" }}
    </button>
  </div>
</form>