import { Component, inject, ViewChild, AfterViewInit } from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { LocalesService } from '../../../services/locales-service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { GeneralRemoveDialog } from '../../dialogs/general-remove-dialog/general-remove-dialog.component';
import { GeneralService } from '../../../services/general-service';
import { MatPaginator } from '@angular/material/paginator';
import { MatCard, MatCardContent } from '@angular/material/card';
import { ActivatedRoute, Router } from '@angular/router';
import { ILocale } from '../../../interfaces/locale-interfaces';
import { LocaleClassesService } from '../../../services/locale-classes-service';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { ComboService } from '../../../services/combo-service';

@Component({
  selector: 'locales-table',
  standalone: true,
  imports: [
    IconButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatNoDataRow,
    MatProgressSpinner,
    MatPaginator,
    MatCard,
    MatCardContent,
  ],
  templateUrl: './locales-table.component.html',
  styleUrl: './locales-table.component.scss',
})
export class LocalesTableComponent implements AfterViewInit {
  dialog = inject(MatDialog);

  displayedColumns: string[] = [
    'icao',
    'name',
    'cashBilling',
    'collectorStatus',
    'class',
    'responsible',
    'status',
    'actions',
  ];

  canActions = false;
  dataSource = new MatTableDataSource<ILocale>(this.localesService.list);
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected generalService: GeneralService,
    protected _comboService: ComboService,
    protected localesService: LocalesService,
    protected localeClassesService: LocaleClassesService,
    protected _funcionalidadesService: FuncionalidadesService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    const localeId = this.route.snapshot.paramMap.get('id');

    if (localeId) {
      const currentLocaleData = this.localesService.list.find(
        (locale) => locale.id_localidade === Number(localeId)
      );

      if (currentLocaleData) {
        await this.startEditing(currentLocaleData);
      }
    }

  }

  ngOnInit() {

    this.canActions = this._funcionalidadesService.canAccess(
      FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_INFORMACOES_GERAIS,
      false
    );
  }

  public async startEditing(localeData: ILocale) {
    // @ts-ignore
    this.localesService.locale.setValue({
      ...localeData,
      status: String(localeData.status), // must be string in the boolean-options-component
    });

    this.localesService.openLocaleForm();

    const { id_localidade } = this.localesService.locale.value;

    await this.router.navigate([`locales/${id_localidade}`]);
  }

  public openRemoveDialog(locale: ILocale) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'a localidade',
        id: locale.id_localidade,
        name: `${locale.sigla_icao} - ${locale.nm_reduzido_localidade}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          const result = await this.localesService.delete(removeId);

          if (result.error == true) {
            this.generalService.notify(
              result.messagens.join(' - '),
              'negative'
            );
            return;
          }

          this.generalService.notify('Localidade removida', 'positive');

          await this.localesService.clearSearch();
        } catch (error: any) {
          this.generalService.notify(error.messagens.join('<br>'), 'negative');
        }
      }
    });
  }

  public getCurrentClassLabel(localeClass: {
    id_classe: number;
    data_vigencia: string;
  }) {
    if (!localeClass) return '';

    return this.localeClassesService.getCurrentClassLabel(
      localeClass.id_classe,
      localeClass.data_vigencia,
      true
    );
  }

  public getExternalEntityName(externalEntityId?: number) {
    if (!externalEntityId) return '';

    return (
      this._comboService.entidadesExternas.find(
        (externalEntity) => externalEntity.id === externalEntityId
      )?.value || ''
    );
  }
}
