{
  "name": "atan-frontend",
  "version": "0.2.93",
  "scripts": {
    "ng": "ng",
    "build": "ng build",
    "build-dev": "ng build --configuration development",
    "build-hml": "ng build --configuration homolog",
    "watch": "ng build --watch --configuration development",
    "cypress": "cypress open",
    "dev": "ng serve --configuration=development --open",
    "hml": "ng serve --configuration=homolog --open",
    "local": "ng serve --configuration=local --open"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/cdk": "^17.3.1",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/material": "^17.3.1",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "axios": "^1.6.8",
    "html2canvas": "^1.4.1",
    "jspdf": "^2.5.1",
    "jwt-decode": "^4.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.1",
    "@angular/cli": "^17.3.1",
    "@angular/compiler-cli": "^17.3.0",
    "@types/jasmine": "~5.1.0",
    "cypress": "^13.10.0",
    "cypress-slow-down": "^1.3.1",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
