import {Component, Inject, OnInit} from '@angular/core';

import {FormBuilder, Validators} from '@angular/forms';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {SubmitButtonComponent} from '../../buttons/submit-button/submit-button.component';
import {SharedModule} from '../../../shared/modules/shared.module';
import {BooleanRadioComponent} from '../../radios/boolean-radio/boolean-radio.component';
import {GeneralService} from '../../../services/general-service';
import {InputPasswordComponent} from '../../inputs/input-password/input-password.component';
import {GroupsService} from '../../../services/groups-service';
import {IGroup} from '../../../interfaces/groups-interfaces';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import {FuncionalidadesService} from '../../../services/funcionalidades-service';

@Component({
  selector: 'group-dialog',
  standalone: true,
  imports: [
    InputPasswordComponent,
    BooleanRadioComponent,
    SubmitButtonComponent,
    SharedModule,
  ],
  templateUrl: './group-dialog.component.html',
  styleUrl: './group-dialog.component.scss',
})
export class GroupDialog implements OnInit {
  constructor(
    protected _generalService: GeneralService,
    protected _groupsService: GroupsService,
    public dialogRef: MatDialogRef<IGroup>,
    @Inject(MAT_DIALOG_DATA) public data: { group?: IGroup },
    private _formBuilder: FormBuilder,
    private funcionalidadesService: FuncionalidadesService
  ) {
  }

  form = this._formBuilder.group({
    id_grupo: 0,
    nm_grupo: ['', [Validators.required]],
    situacao: ['1', [Validators.required]],
  });

  ngOnInit() {
    if (this.data?.group?.id_grupo) {
      this.form.patchValue({
        id_grupo: this.data?.group?.id_grupo,
        nm_grupo: this.data?.group?.nm_grupo,
        situacao: this.data?.group?.situacao?.toString(),
      });
    }

    if (this.isReadonly) {
      this.form.disable()
    }
  }

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_GRUPOS,
    false
  );

  public async submit(e: Event) {
    e.preventDefault();
    try {
      const data = {
        ...this.form.getRawValue(),
      };

      if (this.form.status === 'VALID') {
        if (this.data?.group?.id_grupo) {
          const result = await this._groupsService.update(
            this.data.group.id_grupo,
            data
          );
          this._generalService.notify(
            'Grupo atualizado com sucesso',
            'positive'
          );
          this.form.patchValue({...result});
        } else {
          await this._groupsService.create(data);
          this._generalService.notify('Grupo criado com sucesso', 'positive');
        }
        this.dialogRef.close();
        await this._groupsService.getAll();
      }
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
