import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../shared/modules/shared.module';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'input-password',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './input-password.component.html',
  styleUrl: './input-password.component.scss',
})
export class InputPasswordComponent {
  showPassword: boolean = false;

  @Input() label: string = 'Label';
  @Input() formControl: FormControl<string | null> = new FormControl();

  @Input() errorMessage: string = 'Informe a senha';
  @Input() errorConfirmarSenhaMessage: string = 'Senhas não correspondem';
  @Input() dataCy: string = '';

}
