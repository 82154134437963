import { Component, OnDestroy } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectIcaoComponent } from '../../selects/select-icao/select-icao.component';
import { SharedModule } from '../../../shared/modules/shared.module';
import { RepassesService } from '../../../services/repasses-service';
import { SimpleDatepickerComponent } from '../../datepickers/simple-datepicker.component';
import { ClearFilterButtonComponent } from '../../buttons/clear-filter-button/clear-filter-button.component';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { toISO8601 } from '../../../services/date-service';
import MovesService from '../../../services/moves-service';
import { onlyNumbers } from '../../../services/data-transform';

@Component({
  selector: 'repass-search-bar',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SelectIcaoComponent,
    SharedModule,
    SimpleDatepickerComponent,
    ClearFilterButtonComponent,
    SubmitButtonComponent,
  ],
  templateUrl: './repass-search-bar.component.html',
  styleUrl: './repass-search-bar.component.scss',
})
export class RepassSearchBarComponent implements OnDestroy {
  constructor(
    protected repassesService: RepassesService,
    protected movesService: MovesService
  ) {}

  async ngOnDestroy() {
    await this.clearSearch(false);
  }

  protected changeStatusRepasse(value: string) {
    this.repassesService.search.patchValue({
      status_repasse: value,
    });
  }

  protected async submit() {
    await this.movesService.search({
      sigla_icao: this.repassesService.search.controls.sigla_icao.value || '',
      identificacao:
        this.repassesService.search.controls.identificacao.value || '',
      matricula: this.repassesService.search.controls.matricula.value || '',
      periodo_inicio: toISO8601(
        this.repassesService.search.controls.operacao_desde.value || ''
      ),
      periodo_fim: toISO8601(
        this.repassesService.search.controls.operacao_ate.value || ''
      ),
      data_referencia_de: toISO8601(
        this.repassesService.search.controls.data_referencia_de.value || ''
      ),
      data_referencia_ate: toISO8601(
        this.repassesService.search.controls.data_referencia_ate.value || ''
      ),
      status_repasse:
        this.repassesService.search.controls.status_repasse.value || '',
      cod_repasse: this.repassesService.search.controls.cod_repasse.value || '',
    });
  }

  public async clearSearch(reloadMoves = true) {
    this.repassesService.clearSearch();

    this.movesService.resetMovesRepasse();

    if (reloadMoves) await this.movesService.getAllMoves(true);
  }

  onlyNumbers(event: Event) {
    const cod_repasse = (event.target as HTMLInputElement).value;

    if (this.repassesService.search.controls.cod_repasse) {
      this.repassesService.search.patchValue({
        cod_repasse: onlyNumbers(cod_repasse),
      });
    }
  }
}
