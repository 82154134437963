<div style="padding: 15px">
  <group-search-bar/>

  @if (_groupsService.loading) {
    <div class="full-width" style="display: flex; justify-content: center">
      <mat-spinner diameter="24"/>
    </div>
  } @else {
    <groups-table/>
  }
</div>
