import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export class Validators {
  public static percentageEqual100(control: FormControl) {
    const value = control.value;
    if (value < 0 || value > 100) {
      return { invalidPercentage: true };
    }
    return null;
  }

  public static notEqualText(comparedControl: FormControl): ValidatorFn {
    return (control: AbstractControl) => {
      if (!comparedControl.value || !control.value) {
        return null;
      }

      if (control.value != comparedControl.value) {
        return {
          notEqual: true,
        };
      }
      return null;
    };
  }

  public static required(control: AbstractControl) {
    if (
      !control.value ||
      (typeof control.value === 'string' && !control.value.trim())
    ) {
      return {
        required: true,
      };
    }

    return null;
  }

  public static max(max: number): ValidatorFn {

    return (control: AbstractControl) => {

      const value = control.value.replace(',', '.') as number ?? 0;

      if (
        value && value > max
      ) {
        return {
          max: true,
        };
      }

      return null;
    };
  }

  public static min(min: number): ValidatorFn {

    return (control: AbstractControl) => {

      const value = control.value.replace(',', '.') as number ?? 0;
      if (
        value && value <= min
      ) {
        debugger
        return {
          min: true,
        };
      }

      return null;
    };
  }
}
