import { Component, Inject, OnInit } from '@angular/core';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule, FormBuilder } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from '@angular/material/grid-list';
import { Validators } from '@angular/forms';
import { MatProgressSpinner } from "@angular/material/progress-spinner";

import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogClose,
  MatDialogTitle,
  MatDialogRef,
} from "@angular/material/dialog";

import { InputTextComponent } from "../../inputs/input-text/input-text.component";
import { ExternalEntitiesService, IExternalEntity } from "../../../services/external-entities-service";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import { CNPJ } from "../../../services/data-transform";
import { CustomValidateService } from "../../../services/custom-validate-service";
import { GeneralService } from "../../../services/general-service";
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { IconButton } from '../../buttons/icon-button/icon-button.component';

export interface DialogData {
  externalEntity: IExternalEntity,
}

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    InputTextComponent,
    MatGridListModule,
    MatInputModule,
    MatProgressSpinner,
    SubmitButtonComponent,
    MatIcon,
    CommonModule,
    IconButton
  ],
  selector: 'external-entity-dialog',
  templateUrl: 'external-entity-dialog.component.html',
})

export class ExternalEntityDialog implements OnInit {
  externalEntity = this.formBuilder.group({
    id_entidade_externa: 0,
    nr_cnpj: ['', [Validators.required, CustomValidateService.CNPJ]],
    nm_entidade_externa: ['', Validators.required],
  });

  saving = false;
  delay = 500;

  constructor(
    private externalEntityService: ExternalEntitiesService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ExternalEntityDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private generalService: GeneralService,
    private funcionalidadesService: FuncionalidadesService
  ) { }

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_ENTIDADES_EXTERNAS,
    false
  );

  ngOnInit() {

    if (this.isReadonly) {
      this.externalEntity.disable();
    }

    if (this.data.externalEntity && this.data.externalEntity.id_entidade_externa) {
      this.externalEntity.setValue({
        id_entidade_externa: this.data.externalEntity.id_entidade_externa,
        nm_entidade_externa: this.data.externalEntity.nm_entidade_externa,
        nr_cnpj: CNPJ(this.data.externalEntity.nr_cnpj),
      });
    }
  }

  closeDialog(reloadExternalEntities = false): void {
    this.dialogRef.close(reloadExternalEntities);
  }

  async submit() {
    try {
      this.saving = true;

      if (this.externalEntity.status === 'VALID') {
        const {
          id_entidade_externa,
          nr_cnpj,
          nm_entidade_externa
        } = this.externalEntity.value;

        if (nr_cnpj && nm_entidade_externa) {
          // update...
          if (id_entidade_externa) {
            await this.externalEntityService.update({
              id_entidade_externa,
              nr_cnpj: nr_cnpj?.replace(/\D/g, '') || '',
              nm_entidade_externa,
            });

            this.generalService.notify('Entidade externa atualizada com sucesso', 'positive');
          } else {
            // ...or create
            const result = await this.externalEntityService.create({
              nr_cnpj: nr_cnpj?.replace(/\D/g, '') || '',
              nm_entidade_externa,
            });

            if (result.error == true) {
              this.generalService.notify(result.messagens.join(" - "), 'negative');
              return
            }

            this.generalService.notify('Entidade externa criada com sucesso', 'positive');

          }

          setTimeout(() => {
            this.closeDialog(true);
          }, this.delay);
        }
      } else {
        this.saving = false;
      }
    } catch (error: any) {
      setTimeout(() => {
        this.generalService.notify(error.messagens.join("<br>"), 'negative');
      }, this.delay);
    } finally {
      setTimeout(() => {
        this.saving = false;
      }, this.delay);
    }
  }

  maskCNPJ(): void {
    const { nr_cnpj } = this.externalEntity.value;

    if (nr_cnpj) {
      this.externalEntity.patchValue({
        nr_cnpj: CNPJ(nr_cnpj),
      });
    }
  }
}
