import { Component } from '@angular/core';
import { SubmitButtonComponent } from '../../components/buttons/submit-button/submit-button.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../services/users-service';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general-service';

@Component({
  selector: 'app-forgot-password-page',
  standalone: true,
  imports: [SubmitButtonComponent, SharedModule],
  templateUrl: './forgot-password-page.component.html',
  styleUrl: './forgot-password-page.component.scss',
})
export class ForgotPasswordPageComponent {
  constructor(
    protected _userService: UsersService,
    protected _generalService: GeneralService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) {}

  form = this._formBuilder.group({
    email: ['', [Validators.required]],
  });

  public async submit(e: Event) {
    e.preventDefault();
    try {
      if (this.form.status === 'VALID') {
        const postData = this.form.getRawValue();

        await this._userService.resetPassword(postData?.email ?? '');

        this._generalService.notify(
          'Solicitação efetuada com sucesso, confira sua caixa de email.',
          'positive'
        );
        setTimeout(() => {
          this.backLogin();
        }, 1500);
      }
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }

  backLogin() {
    window.location.href = '/login';
  }
}
