<mat-form-field [class]="'full-width'">
  <mat-label>{{ label }}</mat-label>

  <input
    [attr.data-cy]="dataCy"
    matInput
    [formControl]="formControl"
    [type]="showPassword ? 'text' : 'password'"
  />

  <mat-error *ngIf="formControl?.errors?.['required']">{{
    errorMessage
  }}</mat-error>
  <mat-error *ngIf="formControl?.errors?.['notEqual']">{{
    errorConfirmarSenhaMessage
  }}</mat-error>

  <button
    type="button"
    matSuffix
    mat-icon-button
    aria-label="Toogle password"
    (click)="showPassword = !showPassword"
  >
    <mat-icon>{{ showPassword ? "visibility" : "visibility_off" }}</mat-icon>
  </button>
</mat-form-field>
