import { Component, Input, input } from '@angular/core';
import {
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/modules/shared.module';

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  standalone: true,
  providers: [
    provideNativeDateAdapter({
      parse: {
        dateInput: ['l', 'LL'],
      },
      display: {
        dateInput: 'L',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }),
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
  ],
})
export class DatepickerOverviewExample {
  @Input() nomeGrupoForm: FormGroup = new FormGroup('');
  @Input() nomeControleForm: string = '';
  @Input() label: string = 'Vigência';
  @Input() readonly: boolean = false;

  protected today = new Date();
}
