<div class="container-locale-contacts">
  @if(!this.isReadonly){
  <form [formGroup]="form" class="container-locale-contatc-new">
    <div class="conainer-filters">
      <mat-checkbox formControlName="contato_ativo" [color]="'primary'" (change)="onChangeContatoAtivo($event)">Listar
        somente os contatos ativos da Localidade</mat-checkbox>
    </div>
  </form>
  }

  <table mat-table [dataSource]="this.localesService.localeContactFiltered" class="mat-elevation-z1 highlight-table"   >
    <ng-container matColumnDef="type_contact">
      <th mat-header-cell *matHeaderCellDef>Tipo do contato</th>
      <td mat-cell *matCellDef="let locale">{{ locale.nm_tipo_contato }}</td>
    </ng-container>

    <ng-container matColumnDef="name_contact">
      <th mat-header-cell *matHeaderCellDef>Nome do contato</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.nm_contato }}
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Cargo / Função do contato</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.nm_entidade_externa }}
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell *matCellDef="let locale">{{ locale.email }}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Telefone principal</th>
      <td mat-cell *matCellDef="let locale">{{ locale.telefone_principal }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.contato_ativo == "1" ? "Ativo" : "Inativo" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      @if(!this.isReadonly){
      <td mat-cell *matCellDef="let locale">
        <icon-button [tooltip]="'Editar'" [iconName]="'edit'" style="margin-right: 5px" (click)="edit(locale)" />

        <icon-button [tooltip]="'Deletar'" [iconName]="'delete'" (click)="openRemoveDialog(locale)" />
      </td>
      }@else {
      <td mat-cell *matCellDef="let locale">
        <icon-button [tooltip]="'Visualizar'" [iconName]="'visibility'" (click)="edit(locale)" />
      </td>
      }
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9" style="padding: 15px">
        Nenhum contato de localidade encontrada
      </td>
    </tr>
  </table>

  <mat-paginator showFirstLastButtons hidePageSize [pageSize]="3"></mat-paginator>
</div>