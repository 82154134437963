@if (!dataSource.data.length) {
  <mat-card>
    <mat-card-content>Nenhuma entidade externa encontrada.</mat-card-content>
  </mat-card>
} @else {
  <div>
    <table
      mat-table
      class="mat-elevation-z1 highlight-table"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="nr_cnpj">
        <th mat-header-cell *matHeaderCellDef>CNPJ</th>

        <td mat-cell *matCellDef="let externalEntity">
          {{ maskCNPJ(externalEntity.nr_cnpj) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nm_entidade_externa">
        <th mat-header-cell *matHeaderCellDef>
          Nome da entidade externa
        </th>

        <td mat-cell *matCellDef="let externalEntity">
          {{ externalEntity.nm_entidade_externa }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>

        <td mat-cell *matCellDef="let externalEntity">
          @if (!this.isReadonly) {
            <icon-button
              style="margin-right:5px"
              [tooltip]="'Editar'"
              [dataCy]="'edit-external-entity-button-'+externalEntity.nr_cnpj"
              [iconName]="'edit'"
              (click)="openDialog(externalEntity)"
            />

            <icon-button
              [tooltip]="'Deletar'"
              [dataCy]="'delete-external-entity-button-'+externalEntity.nr_cnpj"
              [iconName]="'delete'"
              (click)="openRemoveDialog(externalEntity)"
            />
          } @else {
            <icon-button
              [tooltip]="'Visualizar'"
              [iconName]="'visibility'"
              (click)="openDialog(externalEntity)"
            />
          }

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr data-cy="external-entity-table-rows" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    @if (generalService.itemsPerPage < dataSource.data.length) {
      <mat-paginator
        showFirstLastButtons
        hidePageSize
        [pageSizeOptions]="[5, 10, 20]"
        [pageSize]="generalService.itemsPerPage"
      ></mat-paginator>
    }
  </div>
}
