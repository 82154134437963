<mat-form-field class="full-width" [formGroup]="nomeGrupoForm">
  <mat-label *ngIf="!!label">{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker"
    [formControlName]="nomeControleForm"
    [min]="today"
    [readonly]="true"
  />
  <mat-error *ngIf="nomeGrupoForm.get(nomeControleForm)?.hasError('dateUsed')">
    Vigência já está em uso
  </mat-error>

  <mat-error *ngIf="nomeGrupoForm.get(nomeControleForm)?.hasError('required')">
    Informe a data
  </mat-error>
  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
    *ngIf="!readonly"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
