import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { LogsSearchBarComponent } from '../../components/bars/logs-search-bar/logs-search-bar.component';
import { LogsTableComponent } from '../../components/tables/logs-table/logs-table.component';
import { GeneralService } from '../../services/general-service';
import { LogsService } from '../../services/logs-service';

@Component({
  selector: 'app-logs-page',
  standalone: true,
  imports: [SharedModule, LogsSearchBarComponent, LogsTableComponent],
  templateUrl: './logs-page.component.html',
  styleUrl: './logs-page.component.scss',
})
export class LogsPageComponent implements OnInit {
  constructor(
    public generalService: GeneralService,
    public logsService: LogsService
  ) {}

  async ngOnInit() {
    this.generalService.setExtraTitle('Consulta a logs');

    await this.logsService.getAll();
  }
}
