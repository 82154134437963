<div class="container-tariff-recipient">
  <mat-card class="container-new-tariff">
    <mat-card-header>
      <mat-card-title>{{ taxName }}</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br />

    <mat-card-content style="min-height: 400px !important">
      <form ngNoValidate [formGroup]="formTarifa">
        <div class="full-width">
          <datepicker class="full-width" label="Tarifa vigente para a localidade a partir de"
            [nomeGrupoForm]="formTarifa" [nomeControleForm]="'data_vigencia'"
            [readonly]="localesTarriffsService.readonly">
          </datepicker>
        </div>

        <div class="body-recipientsbody-recipients">
          <div class="header-recipients">
            <span>Destinatários da tarifa</span>
            <span>Percentuais</span>
          </div>

          <div [formGroup]="formTarifa">
            <div formArrayName="recipients">
              <div class="body-recipients" *ngFor="
                  let recipientControl of localesTarriffsService.listDestinatariosPaged;
                  let i = index
                " [formGroupName]="recipientControl.index">
                <mat-form-field class="full-width">
                  <mat-label>Destinatário</mat-label>
                  <mat-select formControlName="recipient">
                    @for (externalEntity of
                    listOptionsEntidadesExterna(recipientControl.index); track
                    externalEntity) {
                    <mat-option [value]="externalEntity.id">
                      {{ externalEntity.value }}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error>Informe o destinatário</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width">
                  <input matInput type="number" formControlName="percentual" class="input-number" [min]="1" [max]="100"
                    [readonly]="localesTarriffsService.readonly" />
                  <span matTextSuffix>%</span>
                  <mat-error *ngIf="
                      recipientControl.control
                        .get('percentual')
                        ?.hasError('min')
                    ">
                    Deve ser maior que 0.
                  </mat-error>
                  <mat-error *ngIf="
                      recipientControl.control
                        .get('percentual')
                        ?.hasError('max')
                    ">
                    Deve ser menor ou igual a 100.
                  </mat-error>
                  <mat-error *ngIf="
                      recipientControl.control
                        .get('percentual')
                        ?.hasError('required')
                    ">
                    Informe o percentual.
                  </mat-error>
                </mat-form-field>

                <icon-button *ngIf="
                    recipientControl.index > 0 &&
                    !localesTarriffsService.readonly
                  " [matTooltip]="'Remover'" [style.cursor]="'pointer'" [iconName]="'close'" color="warn" (click)="
                    localesTarriffsService.removeRecipient(
                      recipientControl.index
                    )
                  " />
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="!localesTarriffsService.readonly" class="mat-container">
        <a style="margin-right: 45px" (click)="addTariffRecipient()" class="button-link">
          <mat-icon  style="font-size: 13px; display: inline-flex">add</mat-icon>
          Incluir novo destinatário
        </a>
      </div>

      <mat-paginator class="calculate-total" (page)="handlePageEvent($event)" showFirstLastButtons hidePageSize
        [pageSize]="3"></mat-paginator>
    </mat-card-content>

    <mat-divider></mat-divider>
    <div class="mat-container">
      <mat-form-field class="calculate-total">
        <mat-label>Total</mat-label>
        <input matInput type="number" [value]="calcularTotal()" readonly class="input-number" />
        <span matTextSuffix>%</span>
      </mat-form-field>
    </div>
  </mat-card>
</div>