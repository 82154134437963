@if (!dataSource.data.length) {
<div style="padding-top:20px">
  <mat-card>
    <mat-card-content>Nenhuma classe / vigência encontrada para esta localidade.</mat-card-content>
  </mat-card>
</div>
} @else {
<table mat-table [dataSource]="dataSource">
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <ng-container matColumnDef="arrow">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let localeClass">
      @if (localeClassesService.isCurrentClass(localeClass.id_localidade_classe)) {
      <mat-icon color="warn" aria-hidden="false" aria-label="arrow" fontIcon="arrow_forward"
        class="icon-left"></mat-icon>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="id_classe">
    <th mat-header-cell *matHeaderCellDef>Classe</th>
    <td mat-cell *matCellDef="let localeClass"> {{ localeClassesService.getClassName(localeClass.id_classe) }}</td>
  </ng-container>

  <ng-container matColumnDef="data_vigencia">
    <th mat-header-cell *matHeaderCellDef>Vigência</th>
    <td mat-cell *matCellDef="let localeClass"> {{ formatDate(localeClass.data_vigencia) }}</td>
  </ng-container>



  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Ações</th>
    <td mat-cell *matCellDef="let localeClass">
      @if(!isReadonly){
      @if (isFutureLocaleClass(localeClass.data_vigencia)) {
      <icon-button [iconName]="'edit'" style="margin-right:5px" (click)="startEditing(localeClass)" />

      <icon-button [iconName]="'delete'" (click)="openRemoveDialog(localeClass)" />
      }
      }
    </td>
  </ng-container>


</table>

@if (3 < dataSource.data.length) { <mat-paginator showFirstLastButtons hidePageSize [pageSize]="3"></mat-paginator>
  }
  }