import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {MatAnchor, MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";

import {SubmitButtonComponent} from "../../buttons/submit-button/submit-button.component";
import {CNPJ, onlyNumbers} from "../../../services/data-transform";
import {ExternalEntitiesService} from "../../../services/external-entities-service";
import {ClearFilterButtonComponent} from "../../buttons/clear-filter-button/clear-filter-button.component";

@Component({
  selector: 'search-entity-bar',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatGridList,
    MatGridTile,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    SubmitButtonComponent,
    MatButton,
    MatAnchor,
    ClearFilterButtonComponent
  ],
  templateUrl: './search-entity-bar.component.html',
})

export class SearchEntityBarComponent {
  constructor(
    public externalEntitiesService: ExternalEntitiesService,
  ) {
  }

  public async startSearch() {
    try {
      if (!this.externalEntitiesService.searchData.value.nr_cnpj && !this.externalEntitiesService.searchData.value.nm_entidade_externa) {
        await this.externalEntitiesService.clearSearch();
      } else {
        if (this.externalEntitiesService.searchData.value.nr_cnpj) {
          this.externalEntitiesService.loading = true;

          await this.externalEntitiesService.search('nr_cnpj', onlyNumbers(this.externalEntitiesService.searchData.value.nr_cnpj));
        }

        if (this.externalEntitiesService.searchData.value.nm_entidade_externa) {
          this.externalEntitiesService.loading = true;

          await this.externalEntitiesService.search('nm_entidade_externa', this.externalEntitiesService.searchData.value.nm_entidade_externa);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.externalEntitiesService.loading = false;
      }, 500);
    }
  }

  public maskCNPJ() {
    if (this.externalEntitiesService.searchData.value.nr_cnpj) {
      this.externalEntitiesService.searchData.patchValue({
        nr_cnpj: CNPJ(this.externalEntitiesService.searchData.value.nr_cnpj),
      });
    }
  }

  public async handleFields() {
    this.maskCNPJ();

    if (!this.externalEntitiesService.searchData.value.nr_cnpj && !this.externalEntitiesService.searchData.value.nm_entidade_externa) {
      await this.externalEntitiesService.clearSearch();
    }
  }

  public clearNonFocusedField(fieldName: 'nr_cnpj' | 'nm_entidade_externa') {
    if (fieldName === 'nr_cnpj') {
      this.externalEntitiesService.searchData.patchValue({ nm_entidade_externa: '' });
    } else {
      this.externalEntitiesService.searchData.patchValue({ nr_cnpj: '' });
    }
  }
}
