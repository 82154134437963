import { Component } from '@angular/core';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatOption, MatSelect } from '@angular/material/select';
import { LocalesService } from '../../../services/locales-service';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { GeneralService } from '../../../services/general-service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { FilteredAutoCompleteComponent } from '../../auto-completes/filtered-auto-complete/filtered-auto-complete.component';
import { ExternalEntitiesService } from '../../../services/external-entities-service';
import { CancellationConfirmationDialog } from '../../dialogs/cancellation-confirmation-dialog/cancellation-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatCommonModule } from '@angular/material/core';
import { MatIcon } from '@angular/material/icon';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { Router } from '@angular/router';
import { ComboService } from '../../../services/combo-service';
import { SharedModule } from '../../../shared/modules/shared.module';
import { BooleanRadioComponent } from '../../radios/boolean-radio/boolean-radio.component';

@Component({
  selector: 'locale-contacts-form',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardActions,
    MatButton,
    MatCardTitle,
    MatCardSubtitle,
    ReactiveFormsModule,
    MatInput,
    MatFormField,
    MatGridList,
    MatGridTile,
    MatOption,
    MatSelect,
    MatRadioGroup,
    MatRadioButton,
    MatLabel,
    MatError,
    MatAutocompleteTrigger,
    MatAutocomplete,
    FilteredAutoCompleteComponent,
    SubmitButtonComponent,
    CommonModule,
    MatCommonModule,
    MatIcon,
    SharedModule,
    BooleanRadioComponent
  ],
  templateUrl: './locale-contacts-form.component.html',
  styleUrl: './locale-contacts-form.component.scss',
})
export class LocaleContactsFormComponent {
  displayedColumns: string[] = [
    'idContato',
    'tipoContato',
    'NomeContato',
    'cargoFuncaoContato',
    'email',
    'telefonePrincipal',
  ];

  constructor(
    protected generalService: GeneralService,
    protected _comboService: ComboService,
    protected localesService: LocalesService,
    protected externalEntitiesService: ExternalEntitiesService,
    private dialog: MatDialog,
    private _funcionalidadesService: FuncionalidadesService,
    private router: Router
  ) {
    const { id_localidade } = this.localesService.locale.value;

    this.localesService.localeContact.value.id_localidade = id_localidade;
  }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_CONTATOS,
    false
  );

  async ngOnInit() {
    if (this.isReadonly) {
      this.localesService.localeContact.disable();
    }
  }

  async submit() {
    try {
      let idLocalidade = this.localesService.locale.value.id_localidade;
      this.localesService.localeContact.value.id_localidade = idLocalidade;

      if (this.localesService.localeContact.status === 'VALID') {
        if (this.localesService.localeContact.value.id_contato) {
          const result = await this.localesService.updateContact();

          this.generalService.notify(
            'Contato atualizado com sucesso',
            'positive'
          );

          this.localesService.localeContact.patchValue({ ...result });
        } else {
          this.localesService.localeContact.value.id_localidade = idLocalidade;

          const result = await this.localesService.createContact();

          this.localesService.localeContact.patchValue({ ...result });

          this.generalService.notify('Contato criado com sucesso', 'positive');
        }

        this.localesService.createNewContact();
        await this.localesService.getAllContact(idLocalidade);
      }
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }

  openCancelDialog(): void {
    if (this.isReadonly) {
      this.router.navigate(['locales']);
    } else {
      const dialogRef = this.dialog.open(CancellationConfirmationDialog, {
        data: {
          title: 'Cancelar edição do contato',
          question: 'Tem certeza que deseja cancelar a edição deste contato?',
          warning: 'Todas as informações não salvas serão perdidas.',
        },
      });

      dialogRef.afterClosed().subscribe(async (cancel: boolean) => {
        if (cancel) {
          await this.localesService.closeLocaleForm(true);
        }
      });
    }
  }
}
