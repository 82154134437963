import { Component, OnDestroy, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { GeneralService } from '../../services/general-service';
import { GroupsService } from '../../services/groups-service';
import { FormBuilder, Validators } from '@angular/forms';
import { ListCheckComponent } from '../../components/radios/list-check/list-check.component';
import { ICheck } from '../../interfaces/check-interface';
import { IAutoCompleteOption } from '../../interfaces/auto-complete-interfaces';
import {
  GroupFeaturesService,
  IGroupFeature,
} from '../../services/group-features-service';
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';
import { UsersService } from '../../services/users-service';
import { IGroup } from '../../interfaces/groups-interfaces';

@Component({
  selector: 'user-groups-page',
  standalone: true,
  imports: [ListCheckComponent, SharedModule],
  templateUrl: './group-features-page.component.html',
  styleUrl: './group-features-page.component.scss',
})
export class GroupFeaturesPageComponent implements OnInit, OnDestroy {
  constructor(
    public _groupsService: GroupsService,
    public _generalService: GeneralService,
    protected groupFeaturesService: GroupFeaturesService,
    private _formBuilder: FormBuilder,
    private _funcionalidadesService: FuncionalidadesService,
    private _userService: UsersService
  ) {}

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.ASSOCIACAO_DE_FUNCIONALIDADES_A_GRUPO,
    false
  );

  form = this._formBuilder.group({
    group: [0, [Validators.required]],
  });

  featuresList: IAutoCompleteOption[] = [];
  features: ICheck[] = [];
  groupFeatures: IGroupFeature[] = [];

  groupsCombo: IGroup[] = [];

  get groupId() {
    return Number(this.form.get('group')?.value);
  }

  async ngOnInit() {
    this._generalService.setExtraTitle(
      'Usuários e Permissões - Associação de funcionalidades a grupo'
    );

    const result = await this._generalService.getComboData('FUNCIONALIDADES');

    this.featuresList = [...result];

    this.groupsCombo = this._groupsService.list;

  }

  ngOnDestroy() {
    this.cancel();
  }

  swap(type: 'add' | 'remove', all = false) {
    if (all) {
      if (type == 'add' && this.features.length > 0) {
        this.groupFeaturesService.selectedFeatures = [
          ...this.groupFeaturesService.selectedFeatures,
          ...this.features,
        ].map((e) => ({ ...e, check: false }));
        this.features = [];
      }

      if (
        type == 'remove' &&
        this.groupFeaturesService.selectedFeatures.length > 0
      ) {
        this.features = [
          ...this.features,
          ...this.groupFeaturesService.selectedFeatures,
        ].map((e) => ({ ...e, check: false }));
        this.groupFeaturesService.selectedFeatures = [];
      }
    } else {
      if (type == 'add' && this.features.length > 0) {
        this.groupFeaturesService.selectedFeatures = [
          ...this.groupFeaturesService.selectedFeatures,
          ...this.features.filter((i) => i.check),
        ].map((e) => ({ ...e, check: false }));
        this.features = [...this.features.filter((i) => !i.check)];
      }

      if (
        type == 'remove' &&
        this.groupFeaturesService.selectedFeatures.length > 0
      ) {
        this.features = [
          ...this.features,
          ...this.groupFeaturesService.selectedFeatures.filter((i) => i.check),
        ].map((e) => ({ ...e, check: false }));
        this.groupFeaturesService.selectedFeatures = [
          ...this.groupFeaturesService.selectedFeatures.filter((i) => !i.check),
        ];
      }
    }
  }

  cancel() {
    try {
      this._groupsService.loading = true;
      this.form.setValue({ group: 0 });
      this.features = [];
      this.groupFeaturesService.selectedFeatures = [];
    } finally {
      setTimeout(() => {
        this._groupsService.loading = false;
      }, 500);
    }
  }

  async selectedGrupo() {
    try {
      this._groupsService.loading = true;

      const items = await this.groupFeaturesService.getByGroupId(this.groupId);

      this.groupFeatures = [...items];

      const featuresToSelected = [];
      const selectedFeatures = [];

      for (const feature of this.featuresList) {
        const featureInGroup = this.groupFeatures.find(
          (groupFeature) => groupFeature.idFuncionalidade === feature.id
        );

        if (featureInGroup) {
          selectedFeatures.push({
            id: feature.id as number,
            name: feature.value,
            check: false,
            readOnly: !featureInGroup.somenteConsulta,
          });
        } else {
          featuresToSelected.push({
            id: feature.id as number,
            name: feature.value,
            check: false,
            readOnly: false,
          });
        }
      }

      this.features = [...featuresToSelected];

      this.groupFeaturesService.selectedFeatures = [...selectedFeatures];
    } finally {
      setTimeout(() => {
        this._groupsService.loading = false;
      }, 500);
    }
  }

  async saveFeatures() {
    try {
      if (this.form.controls['group'].value === 0) {
        this.form.controls['group'].setErrors({ invalid: true });
      } else {
        this.form.controls['group'].setErrors(null);
      }

      if (this.form.status !== 'VALID') {
        this.form.patchValue({});
        this.form.markAllAsTouched();
        return;
      }

      await this.groupFeaturesService.save(this.groupId);

      this._generalService.notify(
        'Funcionalidades do grupo atualizadas com sucesso',
        'positive'
      );
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }
}
