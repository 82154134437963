import { Component, Inject } from '@angular/core';
import { FormsModule } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'cancellation-confirmation-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    SubmitButtonComponent,
    IconButton,
    MatIcon
  ],
  templateUrl: './cancellation-confirmation-dialog.component.html',
  styleUrl: './cancellation-confirmation-dialog.component.scss'
})

export class CancellationConfirmationDialog {
  title: string;
  question: string;
  warning: string;

  constructor(
    public dialogRef: MatDialogRef<CancellationConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, question: string, warning: string },
  ) {
    this.title = this.data.title;
    this.question = this.data.question;
    this.warning = this.data.warning;
  }

  submit() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
