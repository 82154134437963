@if (!dataSource.data.length) {
<mat-card>
  <mat-card-content>Nenhum grupo encontrado.</mat-card-content>
</mat-card>
} @else {
<table mat-table [dataSource]="dataSource" class="mat-elevation-z1 highlight-table">
  <ng-container matColumnDef="nm_grupo">
    <th mat-header-cell *matHeaderCellDef>Nome do grupo</th>
    <td mat-cell *matCellDef="let group">{{ group.nm_grupo }}</td>
  </ng-container>

  <ng-container matColumnDef="situacao">
    <th mat-header-cell *matHeaderCellDef>Situação</th>
    <td mat-cell *matCellDef="let group">
      {{ group.situacao == 1 ? "Ativo" : "Inativo" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="qtd_usuarios">
    <th mat-header-cell *matHeaderCellDef>Qtd. Usuários</th>
    <td mat-cell *matCellDef="let group">{{ group.qtd_usuarios }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Ações</th>
    <td mat-cell *matCellDef="let group">
      <div class="btns-action">

        @if(!isReadonly){
        <icon-button [tooltip]="'Editar'" [iconName]="'edit'" (click)="editGroup(group)" />
        
        <icon-button [tooltip]="'Deletar'" [iconName]="'delete'" (click)="removeGroup(group)" />
        }@else {
        <icon-button [tooltip]="'Visualizar'" [iconName]="'visibility'" (click)="editGroup(group)" />
        }
        <icon-button [tooltip]="'Visualizar usuários'" [iconName]="'person'" (click)="showUsers(group)" />
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9" style="padding: 15px">
      Nenhum grupo encontrado.
    </td>
  </tr>
</table>

@if (_generalService.itemsPerPage < dataSource.data.length) { <mat-paginator showFirstLastButtons hidePageSize
  [pageSizeOptions]="[5, 10, 20]" [pageSize]="_generalService.itemsPerPage"></mat-paginator>
  } }