import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { FormsModule } from '@angular/forms';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatButton, MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSpinner } from '@angular/material/progress-spinner';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { GeneralService } from '../../../services/general-service';
import { MovesSearchBarComponent } from '../../bars/moves-search-bar/moves-search-bar.component';
import { formatDateAndTime } from '../../../services/date-service';
import { CreateMoveDialog } from '../../dialogs/create-move-dialog/create-move-dialog.component';
import { UsersService } from '../../../services/users-service';
import { GeneralRemoveDialog } from '../../dialogs/general-remove-dialog/general-remove-dialog.component';
import { DatArrecadacaoTarifasPdf } from '../../pdfs/dat-arrecadacao-tarifas-pdf/dat-arrecadacao-tarifas-pdf.component';
import { IMove } from '../../../interfaces/move-interfaces';
import MovesService from '../../../services/moves-service';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { DatService } from '../../../services/dat-service';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { GenerateCalcDialogComponent } from '../../dialogs/generate-calc-dialog/generate-calc-dialog.component';

@Component({
  selector: 'moves-table',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    IconButton,
    MatPaginator,
    MatHeaderRow,
    MatRow,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    MatNoDataRow,
    FormsModule,
    MatGridList,
    MatGridTile,
    MatButton,
    MatIcon,
    MovesSearchBarComponent,
    MatFabButton,
    DatArrecadacaoTarifasPdf,
    SubmitButtonComponent,
    MatSpinner
  ],
  templateUrl: './moves-table.component.html',
  styleUrl: './moves-table.component.scss',
})
export class MovesTableComponent implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<{}>(this.movesServices.list);

  displayedColumns: string[] = [
    'icao',
    'identificacao',
    'dados_da_aeronave',
    'dados_arr',
    'dados_dep',
    'modalidade',
    'actions',
  ];

  isNonZeroBool: boolean = false;
  protected isSingleLocaleUser = false;
  protected disabledGeneratedDAt: boolean = false;

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ts-ignore
  @ViewChild(DatArrecadacaoTarifasPdf) datPdfContent: DatArrecadacaoTarifasPdf;

  constructor(
    protected generalService: GeneralService,
    protected usersService: UsersService,
    public movesServices: MovesService,
    public dialog: MatDialog,
    public _funcionalidadesService: FuncionalidadesService,
    public _datService: DatService
  ) {
  }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_REGISTROS_MOVIMENTOS,
    false
  );

  ngOnInit() {
    this.isSingleLocaleUser = this.usersService.singleLocale;
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public formatDate(date: string) {
    return formatDateAndTime(date);
  }

  public async getMoves() {
    if (
      this.usersService.currentLocale &&
      this.usersService.currentLocale.sigla_icao
    ) {

      await this.movesServices.getSingleLocaleMoves(
        this.usersService.currentLocale.sigla_icao
      );
    }
  }

  startEditing(move: object) {
    const dialogRef = this.dialog.open(CreateMoveDialog, {
      data: { move: { ...move } },
    });

    dialogRef.afterClosed().subscribe(async (reloadMovesList) => {
      if (reloadMovesList) {
        await this.getMoves();
      }
    });
  }

  public openRemoveDialog(move: IMove) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'o movimento',
        id: move.id_movimento,
        name: `Identificação: ${move.identificacao} - Origem: ${move.origem_icao} - Destino: ${move.destino_icao}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          const result = await this.movesServices.delete(removeId);

          if (result.error === true) {
            return this.generalService.notify(
              result.messagens.join(' - '),
              'negative'
            );
          }

          this.generalService.notify('Movimento excluído', 'positive');

          await this.getMoves();

        } catch (error: any) {
          this.generalService.notify(error.messagens.join('<br>'), 'negative');
        }
      }
    });
  }

  isNonZero(move: IMove) {
    const isNonZeroValue = (value: string | null | undefined) => value !== null && value !== undefined && value !== "0";

    this.isNonZeroBool =
      isNonZeroValue(move.tan_destino_dolar) ||
      isNonZeroValue(move.tatAdr_destino_dolar) ||
      isNonZeroValue(move.tatApp_destino_dolar) ||
      isNonZeroValue(move.tan_origem_dolar) ||
      isNonZeroValue(move.tatAdr_origem_dolar) ||
      isNonZeroValue(move.tatApp_origem_dolar);

    return this.isNonZeroBool;
  }

  readDAT(move: IMove) {
    let isNonZero = this.isNonZero(move);
    if (isNonZero) {
      this.datPdfContent?.generatePDF(move);
    } else {
      this.generalService.notify(
        "Sem dados de DAT.",
        'negative',
        6000,
        'OK'
      );
    }
  }

  public async generateDAT(move: IMove) {
    this.showLoading(move.id_movimento || "");

    // Verifica se os valores são não nulos e diferentes de "0"
    let isNonZero = this.isNonZero(move);

    if (isNonZero) {
      // Abre o diálogo de confirmação
      const dialogRef = this.dialog.open(GenerateCalcDialogComponent, {
        data: {
          title: 'ATENÇÃO!',
          question: 'DAT já gerado para este movimento ',
          warning: 'Tem certeza que deseja substitui-lo (recálculo de valores)?',
        },
      });

      // Aguarda o fechamento do diálogo e verifica se o usuário confirmou a ação
      dialogRef.afterClosed().subscribe(async (cancel: boolean) => {
        if (cancel) {
          try {
            // Aguarda a criação do DAT
            await this._datService.create(move.id_movimento || "", true);

            // Notifica o sucesso da operação
            this.generalService.notify('DAT gerado com sucesso.', 'positive');

            let moveSelected = await this.movesServices.getById(move.id_movimento || "")

            void this.datPdfContent?.generatePDF(moveSelected.result);
          } catch (error) {
            // Lide com erros de criação do DAT aqui, se necessário
            console.error("Erro ao gerar DAT:", error);
          }
        }
        this.removeLoading(move.id_movimento || "")
      });
    } else {
      try {
        // Gera o DAT pela primeira vez e aguarda a conclusão
        await this._datService.create(move.id_movimento || "", true);

        // Notifica o sucesso da operação
        this.generalService.notify('DAT gerado com sucesso.', 'positive');

        let moveSelected = await this.movesServices.getById(move.id_movimento || "")

        void this.datPdfContent?.generatePDF(moveSelected.result);
      } catch (error) {
        // Lide com erros de criação do DAT aqui, se necessário
        console.error("Erro ao gerar DAT:", error);
      } finally {
        this.removeLoading(move.id_movimento || "")
      }
    }
  }


  private removeLoading(idMove: string) {
    const loading = document.querySelector(`#loading-${idMove}`)
    const action = document.querySelector(`#action-${idMove}`)

    if (action == null || loading == null) return;

    action.removeAttribute("style");
    loading.setAttribute("style", "display:none;");
    this.disabledGeneratedDAt = false;
  }

  private showLoading(idMove: string) {
    const loading = document.querySelector(`#loading-${idMove}`)
    const action = document.querySelector(`#action-${idMove}`)

    if (action == null || loading == null) return;

    action.setAttribute("style", "display:none;");
    loading.setAttribute("style", "display:flex;");
    this.disabledGeneratedDAt = true;
  }
}
