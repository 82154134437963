import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from './general-service';
import apiService from './api-service';
import { Router } from '@angular/router';
import { LocaleClassesService } from './locale-classes-service';
import { IUser } from '../interfaces/user-interfaces';
import { LocalesService } from "./locales-service";
import { ILocale } from "../interfaces/locale-interfaces";

@Injectable()
export class UsersService {
  localeClassesService: LocaleClassesService | null = null;

  apiListResource = 'auth/Users';
  apiDeleteResource = 'auth/Users';
  apiUpdateResource = 'auth/Users';
  apiCreateResource = 'auth/Register';
  apiLoginResource = 'auth/Login';
  apiLogoutResource = 'auth/Logout';
  apiResetResource = 'auth/ResetPassword';
  apiValidateResetPassword = 'auth/ValidateResetPasswordToken';
  apiChangePasswordResource = 'auth/ChangePassword';
  apiRefreshToken = 'auth/RefreshToken'

  public searchForm = this.formBuilder.group({
    nm_usuario: '',
    ativo: '',
    organizacao: '',
    id_localidade: [{ value: '', disabled: true }],
  });

  public showLocaleForm: boolean = false;
  public showLocaleContactForm: boolean = true;

  public loading: boolean = false;
  public saving: boolean = false;
  public list: IUser[] = [];

  public paginator: any;
  public formLogin = this.formBuilder.group({
    email: ['', [Validators.required]],
    senha: ['', [Validators.required]],
  });

  user = {
    usuarioId: 0,
    nome: '',
    email: '',
    telefone: '',
    localidades: [] as number[],
    funcionalidades: [] as { id: number; read_only: boolean }[],
    DECEA: false,
    nbf: 0,
    exp: 0,
    iat: 0,
  };

  get singleLocale() {
    return !this.user.DECEA && this.user.localidades.length === 1;
  }

  get hasCobrancaAVista() {
    if (!this.currentLocale) return false;

    return this.currentLocale.localidade_cobranca_vista === "1";
  }

  public currentLocale: ILocale | null = null;
  public currentLocales: ILocale[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private _router: Router,
  ) { }

  private saveToken(token: any) {
    localStorage.setItem('@token_user', JSON.stringify(token));
  }

  public getToken() {
    const jsonString = localStorage.getItem('@token_user') ?? '{}';
    return JSON.parse(jsonString);
  }

  public async setUser(user: any) {
    this.user = {
      ...user,
      DECEA: user.DECEA == 'True',
      localidades: JSON.parse(user?.localidades),
      funcionalidades: user?.funcionalidades
        ? JSON.parse(user?.funcionalidades.replaceAll("'", '"'))
        : [],
    };
  }

  public async setUserLocale() {
    try {
      // user has only 1 locale associated with it
      if (this.user.localidades.length === 1 && !this.currentLocale) {
        const { result } = await LocalesService.getUserLocale(this.user.localidades[0]);

        this.currentLocale = { ...result };
      } else if (this.user.localidades.length > 1 && !this.currentLocales.length) {
        const result = await LocalesService.getUserLocales(this.user.localidades);

        this.currentLocales = [...result];
      }

      return Promise.resolve(true);
    } catch (error) {
      await this._router.navigate(['/login']);

      return Promise.reject(error);
    }
  }

  public clearToken() {
    this.logout(this.user.email)

    localStorage.removeItem('@token_user');

    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  }

  public async getAll() {
    try {
      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        this.apiListResource
      );

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter usuários', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async search(search: URLSearchParams) {
    try {
      this.loading = true;

      const params = { ...search };


      const { data } = await apiService.getWithParams(
        `${this.apiListResource}`,
        search
      );

      this.list = [...data['result']];
    } catch (error) {
      console.error(error);

      this.generalService.notify('Erro ao buscar localidade', 'negative');
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  public async create(postData: any) {
    try {
      this.loading = true;

      await apiService.post(this.apiCreateResource, postData);

      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else
        this.generalService.notify(
          'erro ao cadastrar novo usuario',
          'negative'
        );

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async delete(removeId: any) {
    try {
      const { data } = await apiService.delete(
        `${this.apiDeleteResource}/${removeId}`
      );

      return Promise.resolve(data);
    } catch (error) {
      this.generalService.notify('Erro ao remover usuário', 'negative');
      return Promise.reject(error);
    }
  }

  public async update(id: any, postData: any) {
    try {
      this.loading = true;

      const { data } = await apiService.put(
        `${this.apiUpdateResource}/${id}`,
        postData
      );
      return Promise.resolve(data);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('Erro ao atualizar usuário', 'negative');
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async ValidateResetPasswordToken(postData: any) {
    try {
      const { data } = await apiService.post(
        this.apiValidateResetPassword,
        postData
      );
      return Promise.resolve(data);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else
        this.generalService.notify(
          'erro ao efeturar validação token',
          'negative'
        );

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async login(postData: any) {
    try {
      this.loading = true;

      const { data } = await apiService.post(this.apiLoginResource, postData);

      this.saveToken(data['result']);

      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('erro ao efetuar o login', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async logout(email: string) {
    try {

      this.loading = true;
      const { data } = await apiService.post(this.apiLogoutResource, { email });
      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('erro ao efetuar o logout', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async resetPassword(email: string) {
    try {
      this.loading = true;

      const { data } = await apiService.post(this.apiResetResource, { email });

      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('erro ao recuperar senha', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async changePassword(postData: {
    email: string;
    senha: string;
    token: string;
  }) {
    try {
      this.loading = true;

      const { data } = await apiService.post(
        this.apiChangePasswordResource,
        postData
      );

      return Promise.resolve(true);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('erro ao recuperar senha', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async RefreshToken(postData: {
    email: string;
  }) {
    try {
      this.loading = true;

      const { data } = await apiService.post(this.apiRefreshToken, postData);

      this.saveToken(data.refreshToken);

      return Promise.resolve(true);

    } catch (error: any) {
      if (error?.response?.data?.messagens[0])
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      else this.generalService.notify('erro ao efetuar refresh token', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async clearSearch() {
    this.searchForm.setValue({
      nm_usuario: '',
      ativo: '',
      organizacao: '',
      id_localidade: '',
    });

    await this.getAll();
  }
}
