<form class="container-locale-contatc-new" [formGroup]="localesTarriffsService.formTarifaDestinatario" ngNoValidate
  (submit)="onSubmit()">
  <div class="container-new-tariff">
    <mat-form-field class="full-width">
      <mat-label>Tarifa</mat-label>
      <mat-select [formGroup]="form" [formControlName]="'tarifa'" (selectionChange)="onSelectedTariff($event)">
        @for (currentTarifa of tarifas; track currentTarifa) {
        <mat-option [value]="currentTarifa.id">{{ currentTarifa.value }}
        </mat-option>
        }
      </mat-select>
      <mat-error>Informe a tarifa</mat-error>
    </mat-form-field>
    @if (!isReadonly) {
    <button mat-flat-button color="primary" style="height: 54px; width: 400px" (click)="onAdd()" type="button">
      <mat-icon>add</mat-icon>
      Incluir nova vigência de tarifa
    </button>
    }
  </div>

  <div class="container-list-tariff-form">
    <locale-tariffs-table [readOnly]="isReadonly" />
    <locale-tariffs-recipient-form [taxName]="taxName" [readOnly]="isReadonly" />
  </div>

  <mat-grid-list cols="12">
    <mat-grid-tile rowspan="1" colspan="12">
      <div style="height: 60%" class="full-width text-center">
        <submit-button *ngIf="!localesTarriffsService.readonly" style="margin-right: 10px; width: 120px !important"
          label=" Salvar" [loading]="localesTarriffsService.saving" [icon]="'save'">
        </submit-button>

        <button type="button" style="width: 120px" mat-raised-button (click)="openCancelDialog()">
          <mat-icon>{{ !this.isReadonly ? 'close' : 'arrow_back' }}</mat-icon>
          {{ !this.isReadonly ? 'Cancelar' : 'Voltar' }}
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</form>