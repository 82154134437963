import { Injectable } from "@angular/core";
import { FormBuilder } from '@angular/forms';
import apiService from "./api-service";

@Injectable()
export class RepassesService {
  resource = 'Repasses';

  public search = this.formBuilder.group({
    sigla_icao: '',
    identificacao: '',
    matricula: '',
    operacao_desde: '',
    operacao_ate: '',
    data_referencia_de: '',
    data_referencia_ate: '',
    status_repasse: '',
    cod_repasse: '',
    tipo: '',
  });

  constructor(
    private formBuilder: FormBuilder,
  ) {
  }

  public clearSearch() {
    this.search.setValue({
      sigla_icao: '',
      identificacao: '',
      matricula: '',
      operacao_desde: '',
      operacao_ate: '',
      data_referencia_de: '',
      data_referencia_ate: '',
      status_repasse: '',
      cod_repasse: '',
      tipo: '',
    });
  }

  public async saveRepass(data: object) {
    try {
      const response = await apiService.post(this.resource, data);
      return response;

    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async delete(codRepasse: number) {
    try {
      const { data } = await apiService.delete(
        `${this.resource}/${codRepasse}`
      );

      return Promise.resolve(data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }
}
