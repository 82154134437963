@if (allowMoves) {
  <div style="padding:15px">
    <div>
      <moves-search-bar/>

      @if (!isReadonly) {
        @if (isSingleLocaleUser) {
          <button
            style="position:absolute;top:36px;right:15px"
            color="primary"
            mat-flat-button
            (click)="openCreateMoveDialog()"
          >
            <mat-icon>add</mat-icon>
            Incluir movimento
          </button>
        }
      }
    </div>

    @if (movesServices.loading || _comboService.loading) {
      <div
        class="full-width"
        style="display: flex; justify-content: center"
      >
        <mat-spinner diameter="24"/>
      </div>
    } @else {
      <moves-table/>
    }
  </div>
} @else {
  <div style="padding:20px">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>Localidade não possui cobrança à vista</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div style="margin:10px auto">
          Sua localidade não está habilitada no momento para realizar cobranças à vista.
          Em caso de dúvidas, entrar em contato com o DECEA.
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}
