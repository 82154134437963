import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[mask-phone]',
})
export class MaskPhoneDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onKeyup($event: any) {
    let value: string = this.el.nativeElement.value;
    value = value.replace(/[^0-9]*/g, '');

    const ddd = value.slice(0, 2);
    let parte1 = '';
    let parte2 = '';

    if (value.length == 11) {
      parte1 = value.slice(2, 7);
      parte2 = value.slice(7, 11);
    } else {
      parte1 = value.slice(2, 6);
      parte2 = value.slice(6, 10);
    }

    if (value.length <= 2) value = value; //`(${ddd})`;
    else if (value.length > 2 && value.length <= 6)
      value = `(${ddd}) ${parte1}`;
    else value = `(${ddd}) ${parte1}-${parte2}`;

    this.el.nativeElement.value = value;
  }
}
