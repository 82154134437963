import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

import { DialogData } from "../external-entity-dialog/external-entity-dialog.component";
import { LocaleClassFormComponent } from "../../forms/locale-class-form/locale-class-form.component";
import { LocaleClassesTableComponent } from "../../tables/locale-classes-table/locale-classes-table.component";
import { LocaleClassesService } from "../../../services/locale-classes-service";
import { IconButton } from "../../buttons/icon-button/icon-button.component";
import { LocalesService } from "../../../services/locales-service";
import {
  LocaleClassesCreatingTableComponent
} from "../../tables/locale-classes-creating-table/locale-classes-creating-table.component";
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'locale-classes-dialog',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    LocaleClassFormComponent,
    LocaleClassesTableComponent,
    MatProgressSpinner,
    IconButton,
    LocaleClassesCreatingTableComponent,
    MatIcon
  ],
  templateUrl: './locale-classes-dialog.component.html'
})

export class LocaleClassesDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LocaleClassesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    protected localeClassService: LocaleClassesService,
    protected localesService: LocalesService,
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.localeClassService.clearingForm = false;
    }, 500);
  }

  closeDialog(reloadExternalEntities = false): void {
    this.dialogRef.close(reloadExternalEntities);
  }
}
