import { Component, Inject } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';

import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogClose,
  MatDialogTitle,
  MatDialogRef,
} from '@angular/material/dialog';

import { InputTextComponent } from '../../inputs/input-text/input-text.component';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { SharedModule } from '../../../shared/modules/shared.module';

export interface DialogData {
  title: string;
  description: number;
  isHtml?: boolean;
  onConfirm?: () => void;
}

@Component({
  selector: 'general-confirm-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    InputTextComponent,
    MatGridListModule,
    SubmitButtonComponent,
    ReactiveFormsModule,
    SharedModule,
  ],
  templateUrl: './general-confirm-dialog.component.html',
  styleUrl: './general-confirm-dialog.component.scss',
})
export class GeneralConfirmDialog {
  dialogData: DialogData;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<GeneralConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.dialogData = { ...data };
  }

  public async submit() {
    if (this.data?.onConfirm != null) this.data?.onConfirm();
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
