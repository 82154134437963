import {Injectable} from "@angular/core";
import API from "./api-service";
import {FormBuilder} from "@angular/forms";

export interface IExternalEntity {
  id_entidade_externa: number,
  nr_cnpj: string;
  nm_entidade_externa: string;
}

@Injectable()
export class ExternalEntitiesService {
  public loading: boolean = true;
  public list: IExternalEntity[] = [];
  public allExternalEntities: IExternalEntity[] = [];
  private apiResource = 'EntidadesExternas';

  public searchData = this.formBuilder.group({
    nr_cnpj: '',
    nm_entidade_externa: '',
  });

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  public async getAll() {
    try {
      this.loading = true;

      const {data} = await API.get(this.apiResource);

      this.list = [...data.result];
      this.allExternalEntities = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async create(externalEntity: object) {
    try {
      const {data} = await API.post(this.apiResource, externalEntity);
      return Promise.resolve(data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }

  public async update(externalEntity: IExternalEntity) {
    try {
      const {data} = await API.put(`${this.apiResource}/${externalEntity.id_entidade_externa}`, externalEntity);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async delete(externalEntityId: number) {
    try {
      const {data} = await API.delete(`${this.apiResource}/${externalEntityId}`);
      return Promise.resolve(data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }

  public search(field: 'nr_cnpj' | 'nm_entidade_externa', keyword: string) {
    try {
      let searchResults: IExternalEntity[] = [];

      if (field === 'nr_cnpj') {
        searchResults = this.allExternalEntities.filter(
          (externalEntity) => externalEntity.nr_cnpj.includes(keyword)
        );
      }

      if (field === 'nm_entidade_externa') {
        searchResults = this.allExternalEntities.filter(
          (externalEntity) => externalEntity.nm_entidade_externa.toLowerCase().includes(keyword.toLowerCase())
        );
      }

      this.list = [...searchResults];

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async clearSearch() {
    this.searchData.setValue({
      nr_cnpj: '',
      nm_entidade_externa: '',
    })

    await this.getAll();
  }
}
