import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { GeneralService } from '../../services/general-service';
import { GroupsService } from '../../services/groups-service';
import { FormBuilder, Validators } from '@angular/forms';
import { ListCheckComponent } from '../../components/radios/list-check/list-check.component';
import { ICheck } from '../../interfaces/check-interface';
import { IAutoCompleteOption } from '../../interfaces/auto-complete-interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';
import { UsersService } from '../../services/users-service';
import { IGroup } from '../../interfaces/groups-interfaces';

@Component({
  selector: 'user-groups-page',
  standalone: true,
  imports: [ListCheckComponent, SharedModule],
  templateUrl: './user-groups-page.component.html',
  styleUrl: './user-groups-page.component.scss',
})
export class UserGroupsPageComponent implements OnInit {
  constructor(
    private _router: ActivatedRoute,
    public _groupsService: GroupsService,
    public _generalService: GeneralService,
    private _formBuilder: FormBuilder,
    private _funcionalidadesService: FuncionalidadesService,
    private _userService: UsersService,
    private formBuilder: FormBuilder
  ) { }

  form = this._formBuilder.group({
    group: [0, [Validators.required]],
  });

  listUsers: ICheck[] = [];
  listUsersGroup: ICheck[] = [];

  groupsCombo: IGroup[] = [];

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.ASSOCIACAO_DE_USUARIOS_A_GRUPO,
    false
  );

  async ngOnInit() {
    this._generalService.setExtraTitle('Usuários e Permissões - Associação de usuários a grupo');

    this._router.queryParams.subscribe((params: any) => {
      const id_grupo = Number(params['id_grupo'] ?? '0');

      this.form.patchValue({
        group: id_grupo,
      });

      if (id_grupo > 0) this.selectedGrupo();
    });

    this.groupsCombo = this._groupsService.list;

  }

  swap(type: 'add' | 'remove', all = false) {
    if (all) {
      if (type == 'add' && this.listUsers.length > 0) {
        this.listUsersGroup = [...this.listUsersGroup, ...this.listUsers].map(
          (e) => ({ ...e, check: false })
        );
        this.listUsers = [];
      }

      if (type == 'remove' && this.listUsersGroup.length > 0) {
        this.listUsers = [...this.listUsers, ...this.listUsersGroup].map(
          (e) => ({ ...e, check: false })
        );
        this.listUsersGroup = [];
      }
    } else {
      if (type == 'add' && this.listUsers.length > 0) {
        this.listUsersGroup = [
          ...this.listUsersGroup,
          ...this.listUsers.filter((i) => i.check),
        ].map((e) => ({ ...e, check: false }));
        this.listUsers = [...this.listUsers.filter((i) => !i.check)];
      }

      if (type == 'remove' && this.listUsersGroup.length > 0) {
        this.listUsers = [
          ...this.listUsers,
          ...this.listUsersGroup.filter((i) => i.check),
        ].map((e) => ({ ...e, check: false }));
        this.listUsersGroup = [...this.listUsersGroup.filter((i) => !i.check)];
      }
    }
  }

  selectedGrupo(param: any | null = null) {
    const idGroup = param?.value ?? this.form.get('group')?.value;

    this._groupsService.getUsersGroups(idGroup).then(() => {
      var users = this._groupsService?.listGroups?.usuarios ?? [];
      this.listUsers = users.map(
        (g) =>
        ({
          id: g.id_usuario,
          name: g.nm_usuario,
          check: false,
        } as ICheck)
      );

      var usersInGroup = this._groupsService?.listGroups?.usuarios_grupo ?? [];
      this.listUsersGroup = usersInGroup.map(
        (g) =>
        ({
          id: g.id_usuario,
          name: g.nm_usuario,
          check: false,
        } as ICheck)
      );
    });
  }

  async saveGroups() {
    try {
      var idsUsers: number[] = this.listUsersGroup.map((c) => c.id);
      var group = this.form.get('group')?.value ?? 0;

      if (this.form.controls['group'].value === 0) {
        this.form.controls['group'].setErrors({ invalid: true });
      } else {
        this.form.controls['group'].setErrors(null);
      }

      if (this.form.status !== 'VALID') {
        this.form.patchValue({});
        this.form.markAllAsTouched();
        return;
      }

      await this._groupsService.registerUsersGroups(
        group as number,
        idsUsers
      );

      this._generalService.notify('Grupo atualizado com sucesso', 'positive');

    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }
}
