import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Validators as ValidatorsCustom } from '../shared/validators/Validators';

import apiService from "./api-service";
import { GeneralService } from "./general-service";
import { LocalesService } from "./locales-service";
import {
  dateWithZeroHour,
  formatDateAndTime,
  getGreatestDate,
  toBrazilianDate,
  toISO8601
} from "./date-service";
import API from "./api-service";
import { ILocaleTax, ILocaleTaxToBeCreated } from "../interfaces/locale-taxes-interfaces";
import { ILocaleClassToBeCreated } from "../interfaces/locale-classes-interfaces";

const defaultControls = {
  id: 0,
  id_localidade: 0,
  percentual_taxa: ['', [Validators.required, ValidatorsCustom.min(0), ValidatorsCustom.max(100)]],
  data_vigencia: ['', Validators.required],
  propagar_taxa: false,
};

@Injectable()
export class LocaleTaxesService {
  apiResource = 'LocalidadeTaxas';
  public loading = true;
  public clearingForm = true;
  public list: ILocaleTax[] = [];
  public currentLocaleTax: ILocaleTax | null = null;
  public currentLocaleTaxToBeCreated: ILocaleTaxToBeCreated | null = null;

  // while creating locale
  public toBeCreated: ILocaleTaxToBeCreated[] = [];
  public dateToBeEdited = 0;

  public data = this.formBuilder.group({ ...defaultControls });

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private localeService: LocalesService,
  ) {
  }

  public isCurrentTax(localeTaxId: number) {
    if (!this.currentLocaleTax) return false;

    return this.currentLocaleTax.id_localidade_taxa === localeTaxId;
  }

  public isCurrentTaxToBeCreated(localeTax: ILocaleClassToBeCreated) {
    return new Date(localeTax.data_vigencia) < new Date();
  }

  public hasCurrentTaxToBeCreated() {
    if (!this.toBeCreated.length) return false;

    return !!this.toBeCreated.find(
      (localeClass) => new Date(localeClass.data_vigencia) < new Date()
    );
  }

  get currentLocaleTaxLabel() {
    if (this.localeService.locale.value.id_localidade) {
      if (!this.currentLocaleTax) return 'Não definida';

      return `${this.currentLocaleTax.percentual_taxa}% - desde ${formatDateAndTime(this.currentLocaleTax.data_vigencia)}`;
    } else {
      if (!this.currentLocaleTaxToBeCreated) return 'Não definida';

      return `${this.currentLocaleTaxToBeCreated.percentual_taxa}% - desde ${formatDateAndTime(this.currentLocaleTaxToBeCreated.data_vigencia)}`;
    }
  }

  get propagar() {
    return this.data.get('propagar_taxa')?.value ?? false;
  }

  public clearForm(reMount = true) {
    this.clearingForm = true;

    this.data = this.formBuilder.group({ ...defaultControls });

    setTimeout(() => {
      this.clearingForm = !reMount;
    }, 500);
  }

  public async getLocaleTaxes(setLoading = true) {
    try {
      this.loading = setLoading;

      if (this.localeService.locale.value.id_localidade) {
        const resource = `${this.apiResource}/Taxas/${this.localeService.locale.value.id_localidade}`;

        const { data }: { data: { result: ILocaleTax[] } } = await apiService.get(resource);

        this.list = [...data.result];

        void this.setCurrentTax();
      }

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter taxas / vigências', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async taxValidityExistsInLocale(localeTax: {
    id: number,
    id_localidade: number,
    percentual_taxa: string,
    data_vigencia: string,
  }) {
    try {
      const resource = `${this.apiResource}/Taxas/${this.localeService.locale.value.id_localidade}`;

      const { data }: { data: { result: ILocaleTax[] } } = await apiService.get(resource);

      const exists = data.result.find(
        (currentLocaleTax) =>
          currentLocaleTax.data_vigencia.includes(localeTax.data_vigencia)
          && currentLocaleTax.id_localidade_taxa !== localeTax.id
      );

      return Promise.resolve(exists);
    } catch (error) {
      this.generalService.notify('Erro ao verificar se a taxa / vigência de localidade existe', 'negative');

      return Promise.reject(error);
    }
  }

  public storeTaxesBeforeCreatingLocale() {
    const { data_vigencia } = this.data.value;

    if (!!data_vigencia) {
      const alreadyExists = this.toBeCreated.find(
        (localeClass) => toBrazilianDate(localeClass.data_vigencia) === toBrazilianDate(data_vigencia)
      );

      if (alreadyExists && !this.dateToBeEdited) {
        this.generalService.notify('A taxa / vigência já foi cadastrada para esta localidade', 'negative');
      } else {
        this.loading = true;

        // EDITING
        if (
          this.dateToBeEdited
          && this.data.value.percentual_taxa
          && this.data.value.data_vigencia
          && (this.data.value.propagar_taxa !== null && this.data.value.propagar_taxa !== undefined)
        ) {
          this.loading = true;

          const updatedList: ILocaleTaxToBeCreated[] = [];

          let index = 1;

          for (const item of this.toBeCreated) {
            if (index === this.dateToBeEdited) {
              // check if date already exists in other position (using index)
              if (
                this.toBeCreated.find(
                  (toBe, currentIndex) => {
                    return this.data.value.data_vigencia?.toString() === toBe.data_vigencia.toString()
                      && index - 1 !== currentIndex
                  }
                )
              ) {
                this.generalService.notify('A taxa / vigência já foi cadastrada para esta localidade', 'negative');

                updatedList.push(item);
              } else {
                updatedList.push({
                  percentual_taxa: this.data.value.percentual_taxa,
                  data_vigencia: this.data.value.data_vigencia,
                  propagar_taxa: this.data.value.propagar_taxa,
                });
              }
            } else {
              updatedList.push(item);
            }

            index += 1;
          }

          this.toBeCreated = [...updatedList];
        } else {
          // CREATING
          if (this.toBeCreated.find((localeTax) => localeTax.data_vigencia === this.data.value.data_vigencia)) {
            this.generalService.notify('A taxa / vigência já foi cadastrada para esta localidade', 'negative');
          } else {
            if (
              this.data.value.percentual_taxa
              && this.data.value.data_vigencia
              && (this.data.value.propagar_taxa !== null && this.data.value.propagar_taxa !== undefined)
            ) {
              this.toBeCreated.push({
                percentual_taxa: this.data.value.percentual_taxa,
                data_vigencia: this.data.value.data_vigencia,
                propagar_taxa: this.data.value.propagar_taxa,
              });
            }
          }
        }

        this.clearForm();

        void this.setCurrentTaxToBeCreated();

        setTimeout(() => {
          this.loading = false;

          this.dateToBeEdited = 0;
        }, 500);
      }
    }
  }

  public async createVarious(localeId: number) {
    for (const localeTax of this.toBeCreated) {
      try {
        void apiService.post(this.apiResource, {
          id_localidade: localeId,
          percentual_taxa: localeTax.percentual_taxa,
          data_vigencia: toISO8601(localeTax.data_vigencia),
          propagar_taxa: localeTax.propagar_taxa,
        });
      } catch (error) {
        console.error(error);

        this.generalService.notify('Erro ao criar a taxa / vigência de localidade', 'negative');
      }
    }
  }

  public async create() {
    try {
      if (this.data.value.data_vigencia) {
        await apiService.post(this.apiResource, {
          ...this.data.value,
          data_vigencia: toISO8601(this.data.value.data_vigencia),
        });

        if (this.data.value.propagar_taxa) {
          this.generalService.notify('A taxa / vigência de localidade foi criada e propagada', 'positive');
        } else {
          this.generalService.notify('A taxa / vigência de localidade foi criada', 'positive');
        }

        this.clearForm();

        setTimeout(async () => {
          await this.getLocaleTaxes();
        }, 500);
      }
    } catch (error) {
      this.generalService.notify('Erro ao criar a taxa / vigência de localidade', 'negative');

      return Promise.reject(error);
    }
  }

  public async update() {
    try {
      if (this.data.value.data_vigencia) {
        await apiService.put(`${this.apiResource}/${this.data.value.id}`, {
          ...this.data.value,
          data_vigencia: toISO8601(this.data.value.data_vigencia),
        });

        if (this.data.value.propagar_taxa) {
          this.generalService.notify('A taxa / vigência de localidade foi atualizada e propagada', 'positive');
        } else {
          this.generalService.notify('A taxa / vigência de localidade foi atualizada', 'positive');
        }

        await this.getLocaleTaxes();
      }
    } catch (error) {
      this.generalService.notify('Erro ao atualizar a taxa / vigência de localidade', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async delete(localeClassId: number, propagar: boolean = false) {
    try {
      const { data } = await API.delete(`${this.apiResource}/${localeClassId}?flagPropagation=${propagar}`);

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteFromCreating(localeClassDate: string) {
    this.loading = true;

    const updatedList: ILocaleTaxToBeCreated[] = [];

    for (const item of this.toBeCreated) {
      if (item.data_vigencia !== localeClassDate) {
        updatedList.push(item);
      }
    }

    this.toBeCreated = [...updatedList];

    this.currentLocaleTaxToBeCreated = null;

    this.setCurrentTaxToBeCreated();

    setTimeout(() => {
      this.loading = false
    }, 500);
  }

  public setCurrentTax() {
    try {
      this.currentLocaleTax = null;

      const today = dateWithZeroHour(new Date());

      const taxesFound = this.list.filter((localeTax) => {
        if (localeTax.id_localidade !== localeTax.id_localidade) return false;

        const localeClassValidity = new Date(localeTax.data_vigencia);

        return localeClassValidity <= today;
      });

      const localeTaxesAux: Date[] = [];

      for (const taxFound of taxesFound) {
        localeTaxesAux.push(new Date(taxFound.data_vigencia));
      }

      const greaterDate = getGreatestDate(localeTaxesAux);

      const greaterTaxValidity = taxesFound.find(
        (localeTax) => new Date(localeTax.data_vigencia).getTime() === greaterDate.getTime(),
      );

      if (greaterTaxValidity) {
        this.currentLocaleTax = greaterTaxValidity;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public setCurrentTaxToBeCreated() {
    try {
      this.currentLocaleTax = null;

      const today = dateWithZeroHour(new Date());

      const taxesFound = this.toBeCreated.filter((localeTax) => {
        const localeClassValidity = new Date(localeTax.data_vigencia);

        return localeClassValidity <= today;
      });

      const localeTaxesAux: Date[] = [];

      for (const taxFound of taxesFound) {
        localeTaxesAux.push(new Date(taxFound.data_vigencia));
      }

      const greaterDate = getGreatestDate(localeTaxesAux);

      const greaterTaxValidity = taxesFound.find(
        (localeTax) => new Date(localeTax.data_vigencia).getTime() === greaterDate.getTime(),
      );

      if (greaterTaxValidity) {
        this.currentLocaleTaxToBeCreated = greaterTaxValidity;
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async getCurrentLocaleTax(localeId: number) {
    try {
      const { data }: { data: { result: ILocaleTax } } = await API.get(`${this.apiResource}/Vigente/${localeId}`);

      return Promise.resolve(data.result);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
