<form
  [formGroup]="localesService.search"
  (ngSubmit)="submit()"
>
  <div style="width:1080px;height:80px">
    <div style="float:left;width:115px;margin-right:5px">
      <div class="full-width">
        @if (generalService.data['siglasicao'] && generalService.data['siglasicao'].length) {
          <select-icao
            [externalControl]="localesService.search.controls.sigla_icao"
          />
        }
      </div>
    </div>

    <div style="float:left;width:200px;margin-top:5px;margin-right:10px">
      <boolean-radio
        [externalControl]="localesService.search.controls.status"
        label="Situação da localidade"
        firstOptionLabel="Ativa"
        secondOptionLabel="Inativa"
      />
    </div>

    <div style="float:left;width:110px;margin-left:5px">
      <mat-form-field class="full-width">
        <mat-label>Classe</mat-label>
        <mat-select formControlName="classe">
          @for (currentClass of _comboService.classes; track currentClass) {
            <mat-option [value]="currentClass.id">{{ currentClass.value }}</mat-option>
          }
        </mat-select>
        <mat-error>Informe a classe</mat-error>
      </mat-form-field>
    </div>

    <div style="float:left;width:200px;margin-top:5px">
      <boolean-radio
        [externalControl]="localesService.search.controls.localidade_cobranca_vista"
        label="Cobrança à vista?"
        firstOptionLabel="Sim"
        secondOptionLabel="Não"
      />
    </div>

    <div style="float:left;width:180px;">
      <mat-form-field class="full-width">
        <mat-label>Nome da localidade</mat-label>

        <input matInput formControlName="nm_reduzido_localidade">
      </mat-form-field>
    </div>

    <div style="float:left;margin: 12px 0 0 15px">
      <div class="full-width">
        <submit-button
          label="Buscar"
          [icon]="'search'"
        />

        <clear-filter-button
          style="margin-left:10px"
          (click)="localesService.clearSearch()"
        />
      </div>
    </div>
  </div>
</form>
