import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from './general-service';
import apiService from './api-service';
import { Router } from '@angular/router';
import { LocaleClassesService } from './locale-classes-service';
import { IMenu } from '../interfaces/menu-interface';

@Injectable()
export class MenuService {
  localeClassesService: LocaleClassesService | null = null;

  apiResource = 'Menus';

  public loading: boolean = false;
  public saving: boolean = false;
  public list: IMenu[] = [];

  public paginator: any;

  constructor(private generalService: GeneralService) {}

  get subMenus() {
    if (!this.list.length) return [];

    return this.list.map((e) => e.sub_menus).flat();
  }

  //#region calls api
  public async getAll() {
    try {
      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        this.apiResource
      );

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter menu', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }
  //#endregion
}