import { Component } from '@angular/core';
import {SubmitButtonComponent} from "../submit-button/submit-button.component";

@Component({
  selector: 'clear-filter-button',
  standalone: true,
  imports: [
    SubmitButtonComponent
  ],
  templateUrl: './clear-filter-button.component.html',
  styleUrl: './clear-filter-button.component.scss'
})

export class ClearFilterButtonComponent {

}
