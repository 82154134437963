import { Component, OnInit, inject, ViewChild } from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';

import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { LocalesService } from '../../../services/locales-service';
import { GeneralService } from '../../../services/general-service';
import { ILocaleContact } from '../../../interfaces/locale-contact-interfaces';
import { MatDialog } from '@angular/material/dialog';
import { GeneralRemoveDialog } from '../../dialogs/general-remove-dialog/general-remove-dialog.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';

export interface ILocale {
  id_localidade: number;
  nm_localidade: string;
  nm_reduzido_localidade: string;
  status: number;
  tipo_fonte: string;
  administradora: string;
  resp_cont_trafego_aereo: string;
  classe: string;
  sigla_iata: string;
  sigla_icao: string;
  subordinacao: string;
  situacao_coletor_localidade: number;
  localidade_cobranca_vista: number;
  endereco_fisico: string;
  numero: string;
  complemento: string;
  latitude: string;
  longetude: string;
  uf: string;
  cidade: string;
  bairro: string;
  cep: string;
  comentarios: string;
}

@Component({
  selector: 'locale-contacts-table',
  standalone: true,
  imports: [
    MatCheckboxModule,
    MatInput,
    MatFormField,
    MatGridList,
    MatLabel,
    MatGridTile,
    MatOption,
    MatRadioGroup,
    MatRadioButton,
    MatSelect,
    IconButton,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    MatHeaderCellDef,
    MatNoDataRow,
    ReactiveFormsModule,
    MatPaginator,
    MatProgressSpinner,
    CommonModule
  ],
  templateUrl: './locale-contacts-table.component.html',
  styleUrl: './locale-contacts-table.component.scss',
})
export class LocaleContactsTableComponent implements OnInit {
  dialog = inject(MatDialog);

  displayedColumns: string[] = [
    'type_contact',
    'name_contact',
    'role',
    'email',
    'phone',
    'status',
    'actions',
  ];

  form = this.formBuilder.group({
    contato_ativo: '',
  });

  listContactsFiltered = new MatTableDataSource<ILocaleContact>(
    this.localesService.listContacts
  );

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    protected localesService: LocalesService,
    private generalService: GeneralService,
    private formBuilder: FormBuilder,
    private _funcionalidadesService: FuncionalidadesService
  ) { }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_CONTATOS,
    false
  );

  async ngOnInit() {

    await this.localesService.getAllContact(
      this.localesService.locale.value.id_localidade
    );

    this.onChangeContatoAtivo(null);
  }

  ngAfterViewInit() {
    this.localesService.paginator = this.paginator;
  }

  public edit(locale: any) {
    this.localesService.localeContact.patchValue({ ...locale });
  }

  public openRemoveDialog(localeContact: ILocaleContact) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'o contato',
        id: localeContact.id_contato,
        name: localeContact.nm_contato,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          await this.localesService.deleteContact(removeId);

          this.generalService.notify('Contato removido', 'positive');

          await this.localesService.getAllContact(localeContact.id_localidade);
        } catch (error) {
          this.generalService.notify('Erro ao remover contato', 'negative');
        }
      }
    });
  }

  onChangeContatoAtivo(event: any) {
    const isChecked = event?.checked;

    this.localesService.contato_ativo = isChecked;

    this.localesService.filterContatoAtivo();

  }

}
