import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButton } from "@angular/material/button";
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
  selector: 'submit-button',
  standalone: true,
  imports: [
    MatButton,
    MatProgressSpinner,
    MatIcon,
    CommonModule
  ],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.scss'
})

export class SubmitButtonComponent {
  @Input()label = 'Salvar';
  @Input() loading = false;
  @Input() icon = '';
  @Input() dataCy = '';
}
