import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ILocaleTariff } from '../shared/interfaces/ILocaleTariff';
import { GeneralService } from './general-service';
import apiService from './api-service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ITarifaVigencia } from '../shared/interfaces/dtos/ITarifaVigencia';
import { dateWithZeroHour, formatDateAndTime } from './date-service';

interface RecipientWithIndex {
  control: AbstractControl;
  index: number;
}
@Injectable()
export class LocalesTarriffsService {
  apiResource = 'LocalidadeTarifas';

  public showFormAdd: boolean = false;

  public loading: boolean = false;
  public saving: boolean = false;
  public list: ILocaleTariff[] = [];
  public readonly: boolean = false;

  //@ts-ignore
  public paginatorDestinatarios: MatPaginator;
  //@ts-ignore
  public listDestinatariosPaged: RecipientWithIndex[];

  //@ts-ignore
  public paginatorVigencias: MatPaginator;
  public listVigenciasDates: ITarifaVigencia[] = [];
  public listDatesUsed: Date[] = [];

  public formTarifaDestinatario: FormGroup = this.formBuilder.group({
    id_vigencia: [null],
    data_vigencia: ['', Validators.required, this.dateNotUsed.bind(this)],
    tarifa: ['', Validators.required],
    recipients: this.formBuilder.array([this.createRecipient()]),
  });

  constructor(
    private generalService: GeneralService,
    private formBuilder: FormBuilder
  ) {}

  private async dateNotUsed(control: FormControl) {
    const idTariffVigencia =
      this.formTarifaDestinatario.get('id_vigencia')?.value ?? 0;

    let listDates = [...this.listDatesUsed];

    if (idTariffVigencia) {
      const dateUsedControl = this.listVigenciasDates.filter(
        (v) => v.idTariffVigencia == idTariffVigencia
      )[0].date;
      listDates = listDates.filter(
        (d) => this.formatDate(d) != this.formatDate(dateUsedControl)
      );
    }

    const value = this.formatDate(control.value);

    const datesUsed = listDates.map(this.formatDate);
    if (datesUsed.includes(value)) {
      return { dateUsed: true };
    }
    return null;
  }

  private createRecipient() {
    return this.formBuilder.group({
      recipient: ['', Validators.required],
      percentual: ['', Validators.required],
    });
  }

  // Função para verificar se duas datas são iguais em termos de dia, mês e ano
  public isSameDate(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  public formatDate(date: Date): string {
    const dia = date.getDate().toString().padStart(2, '0');
    const mes = (date.getMonth() + 1).toString().padStart(2, '0');
    const ano = date.getFullYear();

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  }

  public addRecipient() {
    let pageIndex = this.paginatorDestinatarios?.pageIndex || 0;
    const pageSize = this.paginatorDestinatarios?.pageSize || 3;

    const recipientsArray = this.formTarifaDestinatario.get(
      'recipients'
    ) as FormArray;
    recipientsArray.push(this.createRecipient());

    this.paginateDestinatarios(
      pageIndex * pageSize,
      (pageIndex + 1) * pageSize
    );

    setTimeout(() => {
      if (this.listDestinatariosPaged?.length === pageSize)
        this.paginatorDestinatarios.nextPage();
    }, 100);
  }

  public removeRecipient(index: any) {
    const recipientsArray = this.formTarifaDestinatario.get(
      'recipients'
    ) as FormArray;

    if (index >= 0) {
      recipientsArray.removeAt(index);

      let pageIndex = this.paginatorDestinatarios.pageIndex || 0;
      const pageSize = this.paginatorDestinatarios.pageSize || 3;

      if (this.listDestinatariosPaged.length == 1) {
        pageIndex--;
      }

      this.paginateDestinatarios(
        pageIndex * pageSize,
        (pageIndex + 1) * pageSize
      );
    }
  }

  public resetForm() {
    this.formTarifaDestinatario.reset();
    const recipientsArray = this.formTarifaDestinatario.get(
      'recipients'
    ) as FormArray;

    while (recipientsArray.length !== 0) {
      recipientsArray.removeAt(0);
    }

    recipientsArray.push(this.createRecipient());
    recipientsArray.push(this.createRecipient());

    this.paginateDestinatarios(0, 3);
  }

  public setVigenciaForm(vigencia: ITarifaVigencia, readOnly = false) {
    this.resetForm();

    this.formTarifaDestinatario.patchValue({
      id_vigencia: vigencia.idTariffVigencia,
      data_vigencia: vigencia.date,
      tarifa: vigencia.idTariff,
      recipients: [],
    });

    this.readonly = vigencia.date < dateWithZeroHour(new Date());

    if (readOnly) {
      this.readonly = true;
    }

    const recipientsArray = this.formTarifaDestinatario.get(
      'recipients'
    ) as FormArray;

    while (recipientsArray.length !== 0) {
      recipientsArray.removeAt(0);
    }

    vigencia.recipients.forEach((r) => {
      recipientsArray.push(
        this.formBuilder.group({
          recipient: [{ value: r.recipient, disabled: this.readonly }],
          percentual: [r.percentual],
        })
      );
    });

    this.paginateDestinatarios(0, 3);
  }

  async loadVigencias(idTariff: number, idLocalidade: number) {
    await this.getById(idTariff, idLocalidade);

    if (this.paginatorVigencias) this.paginatorVigencias.pageIndex = 0;

    this.paginateVigenciaDate(0, 5);
  }

  paginateDestinatarios(start: number, end: number) {
    const recipientsArray = this.formTarifaDestinatario.get(
      'recipients'
    ) as FormArray;

    const listDestinatarios = new MatTableDataSource<AbstractControl>(
      recipientsArray.controls
    );

    listDestinatarios.paginator = this.paginatorDestinatarios;

    this.listDestinatariosPaged = listDestinatarios.data
      .slice(start, end)
      .map((d) => ({
        control: d,
        index: recipientsArray.controls.indexOf(d),
      }));
  }

  paginateVigenciaDate(start: number, end: number) {
    const currentDate = dateWithZeroHour(new Date());
    const listDates: ITarifaVigencia[] = this.list.map((l) => ({
      idTariffVigencia: l.id_tarifa_vig_localidades,
      idTariff: l.id_tarifa,
      date: new Date(l.data_vigencia),
      past: new Date(l.data_vigencia) < currentDate,
      current: false,
      recipients: l.destinatarios.map((d) => ({
        recipient: d.id_entidade_externa,
        percentual: d.percentual,
      })),
    }));

    // Ordenar as datas da menor para a maior
    listDates.sort((a, b) => a.date.getTime() - b.date.getTime());

    // Verificar se a data atual está na lista
    const currentDateIndex = listDates.findIndex((date) =>
      this.isSameDate(date.date, currentDate)
    );

    // Se a data atual estiver na lista, marcar como vigente
    if (currentDateIndex !== -1) {
      listDates[currentDateIndex].current = true;
    } else {
      // Se não estiver na lista, encontrar a data mais próxima anterior à data atual
      for (let i = listDates.length - 1; i >= 0; i--) {
        if (listDates[i].date < currentDate) {
          listDates[i].current = true;
          break;
        }
      }
    }

    // Exibir as datas ordenadas
    const listVigencias = new MatTableDataSource<ITarifaVigencia>(
      listDates.reverse()
    );
    listVigencias.paginator = this.paginatorVigencias;
    this.listVigenciasDates = listVigencias.data.slice(start, end);
    this.listDatesUsed = listDates.map((d) => d.date);
  }

  //api consumer
  public async create(localeTariff: ILocaleTariff) {
    try {
      this.saving = true;

      const {
        data,
      }: {
        data: {
          result: ILocaleTariff;
        };
      } = await apiService.post(this.apiResource, { ...localeTariff });

      return Promise.resolve(data.result);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      this.saving = false;
    }
  }

  public async update(localeData: object, id_tarifa_vigencia: number) {
    try {
      this.saving = true;

      const { id_localidade } = localeData as { id_localidade: string };

      const {
        data,
      }: {
        data: {
          result: ILocaleTariff;
        };
      } = await apiService.put(`${this.apiResource}/${id_tarifa_vigencia}`, {
        ...localeData,
      });

      return Promise.resolve(data.result);
    } catch (error) {
      this.generalService.notify('Erro ao criar a vigência', 'negative');

      return Promise.reject(error);
    } finally {
      this.saving = false;
    }
  }

  public async getAll(idlocalidade: any) {
    try {
      if (!idlocalidade) return Promise.resolve(false);

      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        `${this.apiResource}/localidades/${idlocalidade}`
      );

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter tarifas', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async getById(idTarifa: any, idlocalidade: any) {
    try {
      if (!idTarifa) return Promise.resolve(false);

      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        `${this.apiResource}/${idTarifa}/${idlocalidade}`
      );

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter tarifas', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async delete(idTariffVigencia: string) {
    try {
      const { data } = await apiService.delete(
        `${this.apiResource}/${idTariffVigencia}`
      );

      return Promise.resolve(data);
    } catch (error) {
      this.generalService.notify('Erro ao excluir vigência', 'negative');

      return Promise.reject(error);
    }
  }
}
