<mat-form-field class="full-width">
  <mat-label>{{ label }}</mat-label>

  <input
    matInput
    [matDatepicker]="picker"
    [formControl]="externalControl"
    [min]="blockPastDays ? today : null"
    readonly
  >

  @if(externalControl.hasError('needed')) {
    <mat-error>{{ neededValidationErrorMessage }}</mat-error>
  }

  @if(externalControl.hasError('invalid')) {
    <mat-error>{{ invalidValidationErrorMessage }}</mat-error>
  }

  <mat-datepicker-toggle
    [disabled]="disableToggle"
    matIconSuffix
    [for]="picker"
  ></mat-datepicker-toggle>

  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
