import { AfterContentInit, Component, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../../services/general-service';
import { UsersService } from '../../services/users-service';
import { SharedModule } from '../../shared/modules/shared.module';
import { jwtDecode } from 'jwt-decode';
import { Subscription, interval } from 'rxjs';
import { GeneralConfirmDialog } from '../../components/dialogs/general-confirm-dialog/general-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MenuService } from '../../services/menu-service';
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements AfterContentInit {
  protected generalService = inject(GeneralService);
  private timerSubscription?: Subscription;

  tempRestanteSegs: number = 0;

  constructor(
    protected _userService: UsersService,
    protected menuService: MenuService,
    protected _funcionalidadesService: FuncionalidadesService,
    private _router: Router,
    private _dialog: MatDialog
  ) { }

  isMenuOpen = true;
  sectionsState: Record<string, boolean> = {};
  canAccessSettings = false;

  toggleSection(section: string) {
    this.sectionsState[section] =
      this.sectionsState[section] != undefined
        ? !this.sectionsState[section]
        : false;
  }

  isSectionOpen(section: string) {
    return this.sectionsState[section] ?? true;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  dropdownAberto = false;
  toggleDropdown(event: Event) {
    event.preventDefault();
    this.dropdownAberto = !this.dropdownAberto;
  }

  fecharDropdown() {
    this.dropdownAberto = false;
  }

  close() {
    this._userService.clearToken();
  }

  ngAfterContentInit() {
    this.generalService.setItemsPerPage();
    this.calcularTempoRestante();

    this.canAccessSettings = this._funcionalidadesService.canAccess(
      FuncionalidadesConst.CONFIGURACOES_PESSOAIS
    );
  }

  get tempoExp() {
    if (this.tempRestanteSegs == 0) return '';

    const horas: number = Math.floor(this.tempRestanteSegs / 3600);
    const minutos: number = Math.floor((this.tempRestanteSegs % 3600) / 60);
    const segundos: number = this.tempRestanteSegs % 60;

    return `${horas}h ${minutos}min ${segundos}s`;
  }

  get version() {
    return this.generalService.version;
  }

  calcularTempoRestante() {
    const expTime = new Date((this._userService.user?.exp ?? 0) * 1000);
    const dataAtual = new Date();

    const diferencaMs = expTime.getTime() - dataAtual.getTime();

    this.tempRestanteSegs = Math.floor(diferencaMs / 1000);

    this.timerSubscription = interval(1000).subscribe(() => {
      this.tempRestanteSegs--;
      if (this.tempRestanteSegs <= 0) {
        this._dialog.open(GeneralConfirmDialog, {
          data: {
            title: 'Sessão expirada',
            description:
              'O tempo da sua sessão terminou, realize o login novamente.',
            onConfirm: () => {
              this._router.navigate(['/login']);
              this._userService.clearToken();
            },
          },
        });
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
        }
      }
    });
  }
}
