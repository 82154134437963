import { Component, OnInit } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { MatButton } from "@angular/material/button";
import { MatDialogActions } from "@angular/material/dialog";

import {SimpleDatepickerComponent} from "../../datepickers/simple-datepicker.component";
import { LocalesService } from "../../../services/locales-service";
import { GeneralService } from "../../../services/general-service";
import { SubmitButtonComponent } from "../../buttons/submit-button/submit-button.component";
import { LocaleClassesService } from "../../../services/locale-classes-service";
import { toISO8601 } from "../../../services/date-service";
import { MatIcon } from '@angular/material/icon';
import { ComboService } from '../../../services/combo-service';

@Component({
  selector: 'locale-class-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatGridList,
    MatGridTile,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    SimpleDatepickerComponent,
    SubmitButtonComponent,
    MatButton,
    MatDialogActions,
    MatIcon
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './locale-class-form.component.html',
})

export class LocaleClassFormComponent implements OnInit {
  saving = false;

  constructor(
    protected _comboService: ComboService,
    protected generalService: GeneralService,
    private localeService: LocalesService,
    protected localeClassService: LocaleClassesService,
  ) {
  }

  ngOnInit() {
    this.localeClassService.data.patchValue({
      id_localidade: this.localeService.locale.value.id_localidade,
    })
  }

  protected async trySubmitting() {
    if (this.localeService.locale.value.id_localidade) {
      await this.submit();
    } else {
      if (
        this.localeClassService.data.status === 'VALID'
        && this.localeClassService.data.value.data_vigencia
      ) {
        this.saving = true;

        this.localeClassService.storeClassesBeforeCreatingLocale();

        setTimeout(() => {
          this.saving = false;
        }, 500);
      }
    }
  }

  private async submit() {
    try {
      this.saving = true;

      if (
        this.localeClassService.data.status === 'VALID'
        && this.localeClassService.data.value.data_vigencia
      ) {
        // check if locale class exists at the same date...
        if (await this.localeClassService.classValidityExistsInLocale({
          id: this.localeClassService.data.value.id || 0,
          id_localidade: this.localeClassService.data.value.id_localidade || 0,
          id_classe: Number(this.localeClassService.data.value.id_classe) || 0,
          data_vigencia: toISO8601(this.localeClassService.data.value.data_vigencia),
        })) {
          this.generalService.notify('A classe / vigência já foi cadastrada para esta localidade', 'negative');
        } else if (this.localeClassService.data.value.id) {
          await this.localeClassService.update();
        } else {
          await this.localeClassService.create();
        }
      } else {
        this.saving = false;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        this.saving = false;
      }, 500);
    }
  }
}
