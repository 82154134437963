<form
  [formGroup]="externalEntitiesService.searchData"
  (submit)="startSearch()"
>
  <div class="search-external-entities-bar">
    <mat-grid-list cols="8" rowHeight="80px" gutterSize="15" style="width:800px">
      <mat-grid-tile colspan="2">
        <mat-form-field class="full-width">
          <mat-label>CNPJ</mat-label>

          <input
            data-cy="search-external-entity-cnpj-input"
            type="text"
            maxlength="18"
            matInput
            formControlName="nr_cnpj"
            (input)="handleFields()"
            (focus)="clearNonFocusedField('nr_cnpj')"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="3">
        <mat-form-field class="full-width">
          <mat-label>Nome da entidade externa</mat-label>

          <input
            data-cy="search-external-entity-name-input"
            type="text"
            matInput
            formControlName="nm_entidade_externa"
            (input)="handleFields()"
            (focus)="clearNonFocusedField('nm_entidade_externa')"
          />
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="3">
        <div class="full-width">
          <submit-button
            label="Buscar"
            data-cy="search-external-entity-submit-button"
            [icon]="'search'"
          />

          <clear-filter-button
            style="margin-left:10px"
            (click)="externalEntitiesService.clearSearch()"
          />
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</form>
