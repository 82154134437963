@if (!dataSource.data.length) {
  <mat-card>
    <mat-card-content>Nenhuma localidade encontrada.</mat-card-content>
  </mat-card>
} @else {
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z1 highlight-table">
    <ng-container matColumnDef="icao">
      <th mat-header-cell *matHeaderCellDef>Sigla ICAO</th>
      <td mat-cell *matCellDef="let locale">{{ locale.sigla_icao }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome reduzido da localidade</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.nm_reduzido_localidade }}
      </td>
    </ng-container>

    <ng-container matColumnDef="class">
      <th mat-header-cell *matHeaderCellDef>Classe</th>
      <td mat-cell *matCellDef="let locale">
        {{
          getCurrentClassLabel(
            localeClassesService.allCurrentLocales[locale.id_localidade]
          )
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="cashBilling">
      <th mat-header-cell *matHeaderCellDef>Cobrança à vista</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.localidade_cobranca_vista == "1" ? "Sim" : "Não" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="collectorStatus">
      <th mat-header-cell *matHeaderCellDef>Situação do coletor</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.situacao_coletor_localidade == "1" ? "Ativo" : "Inativo" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="responsible">
      <th mat-header-cell *matHeaderCellDef>
        Resp. pelo controle de tráfego aéreo
      </th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.nm_resp_cont_trafego_aereo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Situação</th>
      <td mat-cell *matCellDef="let locale">
        {{ locale.status ? "Ativa" : "Inativa" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Ações</th>
      <td mat-cell *matCellDef="let locale">
        @if (canActions) {
          <icon-button [tooltip]="'Editar'" [iconName]="'edit'" style="margin-right: 5px"
                       (click)="startEditing(locale)"/>

          <icon-button [tooltip]="'Excluir'" [iconName]="'delete'" (click)="openRemoveDialog(locale)"/>
        } @else {
          <icon-button [tooltip]="'Visualizar'" [iconName]="'visibility'" (click)="startEditing(locale)"/>
        }
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9" style="padding: 15px">
        Nenhuma localidade encontrada
      </td>
    </tr>
  </table>

  @if (generalService.itemsPerPage < dataSource.data.length) {
    <mat-paginator
      showFirstLastButtons
      hidePageSize
      [pageSizeOptions]="[5, 10, 20]"
      [pageSize]="generalService.itemsPerPage"
    ></mat-paginator>
  }
}
