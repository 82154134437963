import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { GeneralService } from './general-service';
import { ILocaleContact } from '../interfaces/locale-contact-interfaces';
import apiService from './api-service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LocaleClassesService } from './locale-classes-service';
import { ILocale } from '../interfaces/locale-interfaces';
import FuncionalidadesConst from '../shared/constants/FuncionalidadesConst';
import { FuncionalidadesService } from './funcionalidades-service';

const defaultLocaleControls = {
  id_localidade: 0,
  nm_localidade: ['', Validators.required],
  nm_reduzido_localidade: ['', Validators.required],
  status: ['1', Validators.required],
  tipo_fonte: '',
  localidade_cobranca_vista: '1',
  situacao_coletor_localidade: '1',
  sigla_icao: ['', [Validators.required, Validators.minLength(4)]],
  sigla_iata: '',
  endereco_fisico: ['', Validators.required],
  numero: ['', Validators.required],
  complemento: '',
  latitude: '',
  longitude: '',
  uf: ['', Validators.required],
  cidade: ['', Validators.required],
  bairro: '',
  cep: ['', Validators.required],
  comentarios: '',

  // id_classe deprecated
  // id_classe: ['', Validators.required],
  id_classe: '1',

  id_administradora: ['', Validators.required],
  id_resp_cont_trafego_aereo: [null],
  id_subordinacao: '',

  // fields names
  nm_classe: '',
  nm_administradora: '',
  nm_resp_cont_trafego_aereo: '',
  nm_subordinacao: '',

  data_ultima_atualizacao: '',
};

const defaultLocaleContactControls = {
  id_localidade: 0,
  id_contato: 0,
  nm_contato: ['', Validators.required],
  email: ['', [Validators.required, Validators.email]],
  id_tipo_contato: ['', Validators.required],
  cargo_funcao: [''],
  telefone_principal: ['', Validators.required],
  telefones_secundarios: '',
  id_entidade_externa: ['', Validators.required],
  contato_ativo: '1',
  data_inclusao: [''],
  data_ultima_atualizacao: [''],
  comentarios: '',
};

const apiResource = 'Localidades';

@Injectable()
export class LocalesService {
  localeClassesService: LocaleClassesService | null = null;

  apiResource = apiResource;
  apiResourceContact = 'LocalidadeContatos';

  public showLocaleForm: boolean = false;
  public showLocaleContactForm: boolean = true;

  public loading: boolean = false;
  public saving: boolean = false;
  public list: ILocale[] = [];

  public locale = this.formBuilder.group({ ...defaultLocaleControls });

  public localeContact = this.formBuilder.group({
    ...defaultLocaleContactControls,
  });

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_INFORMACOES_GERAIS,
    false
  );

  public listContacts: ILocaleContact[] = [];

  public localeContactFiltered = new MatTableDataSource<ILocaleContact>();
  public contato_ativo: boolean = false;
  public paginator: any;

  public search = this.formBuilder.group({
    sigla_icao: '',
    status: '',
    classe: '',
    nm_reduzido_localidade: '',
    localidade_cobranca_vista: '',
  });

  sourceTypes = [
    { value: 'ACC', viewValue: 'ACC' },
    { value: 'APP', viewValue: 'APP' },
    { value: 'TWR', viewValue: 'TWR' },
    { value: 'AFIS', viewValue: 'AFIS' },
  ];

  states = [
    { viewValue: 'Acre', value: 'AC' },
    { viewValue: 'Alagoas', value: 'AL' },
    { viewValue: 'Amapá', value: 'AP' },
    { viewValue: 'Amazonas', value: 'AM' },
    { viewValue: 'Bahia', value: 'BA' },
    { viewValue: 'Ceará', value: 'CE' },
    { viewValue: 'Distrito Federal', value: 'DF' },
    { viewValue: 'Espírito Santo', value: 'ES' },
    { viewValue: 'Goiás', value: 'GO' },
    { viewValue: 'Maranhão', value: 'MA' },
    { viewValue: 'Mato Grosso', value: 'MT' },
    { viewValue: 'Mato Grosso do Sul', value: 'MS' },
    { viewValue: 'Minas Gerais', value: 'MG' },
    { viewValue: 'Pará', value: 'PA' },
    { viewValue: 'Paraíba', value: 'PB' },
    { viewValue: 'Paraná', value: 'PR' },
    { viewValue: 'Pernambuco', value: 'PE' },
    { viewValue: 'Piauí', value: 'PI' },
    { viewValue: 'Rio de Janeiro', value: 'RJ' },
    { viewValue: 'Rio Grande do Norte', value: 'RN' },
    { viewValue: 'Rio Grande do Sul', value: 'RS' },
    { viewValue: 'Rondônia', value: 'RO' },
    { viewValue: 'Roraima', value: 'RR' },
    { viewValue: 'Santa Catarina', value: 'SC' },
    { viewValue: 'São Paulo', value: 'SP' },
    { viewValue: 'Sergipe', value: 'SE' },
    { viewValue: 'Tocantins', value: 'TO' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private generalService: GeneralService,
    private router: Router,
    private funcionalidadesService: FuncionalidadesService
  ) { }

  public injectClassesService(localeClassesService: LocaleClassesService) {
    this.localeClassesService = localeClassesService;
  }

  private resetLocalFormGroup() {
    this.locale = this.formBuilder.group({ ...defaultLocaleControls });
  }

  public resetLocaleContactsFormGroup() {
    this.localeContact = this.formBuilder.group({
      ...defaultLocaleContactControls,
    });
  }

  public openLocaleForm() {
    this.showLocaleForm = true;
  }

  public async closeLocaleForm(redirectToLocaleRoot = false) {
    if (redirectToLocaleRoot) {
      await this.router.navigate(['locales']);
    }

    this.showLocaleForm = false;
    this.resetLocalFormGroup();
    this.resetLocaleContactsFormGroup();
  }


  public async create(localeData: { [key: string]: any }) {
    try {
      this.saving = true;

      const {
        data,
      }: {
        data: {
          result: ILocale;
        };
      } = await apiService.post(this.apiResource, {
        ...localeData,
        id_subordinacao: localeData['id_subordinacao'] || null,
      });

      return Promise.resolve(data.result);
    } catch (error: any) {
      if (error?.response?.data?.messagens[0]){
        this.generalService.notify(
          error.response.data.messagens[0],
          'negative'
        );
      } else {
        this.generalService.notify('erro ao cadastrar nova localidade', 'negative');
      }
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.saving = false;
      }, 500);

    }
  }

  public async update(localeData: object) {
    try {
      this.saving = true;

      const { id_localidade } = localeData as { id_localidade: string };

      const {
        data,
      }: {
        data: {
          result: ILocale;
        };
      } = await apiService.put(`${this.apiResource}/${id_localidade}`, {
        ...localeData,
      });

      this.generalService.notify('Localidade Atualizada com sucesso', 'negative');

      return Promise.resolve(data.result);
    } catch (error) {
      this.generalService.notify('Erro ao Atualizar a localidade', 'negative');

      return Promise.reject(error);
    } finally {
      this.saving = false;
    }
  }

  public static async getUserLocale(localeId: number) {
    const { data } = await apiService.get(`${apiResource}/${localeId}`);

    return data;
  }

  public static async getUserLocales(localeIds: number[]) {
    const { data }: { data: { result: ILocale[] } } = await apiService.get(apiResource);

    return data.result.filter((locale) => localeIds.includes(locale.id_localidade));
  }

  public async getLocaleByICAO(icao: string) {
    try {
      if (this.list.length === 0) await this.getAll();

      const currentLocale = this.list.find(
        (locale) => locale.sigla_icao === icao
      );

      return Promise.resolve(currentLocale);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAll() {
    try {
      this.loading = true;

      const { data }: { data: { result: [] } } = await apiService.get(
        this.apiResource
      );

      this.list = [...data.result];

      await this.localeClassesService?.setCurrentClassesForAllLocales(
        this.list
      );

      return Promise.resolve(true);
    } catch (error) {
      this.generalService.notify('Erro ao obter localidades', 'negative');

      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  // contacts tab
  public async createContact() {
    try {
      this.saving = true;

      const result = await apiService.post('LocalidadeContatos', {
        ...this.localeContact.value,
      });

      return result.data.result;
    } catch (error) {
      this.generalService.notify('Erro ao criar a localidade', 'negative');

      return Promise.reject(error);
    } finally {
      this.saving = false;
    }
  }

  public async updateContact() {
    try {
      this.saving = true;

      const result = await apiService.put(
        `LocalidadeContatos/${this.localeContact.value.id_contato}`,
        { ...this.localeContact.value }
      );

      return Promise.resolve(result.data.result);
    } catch (error) {
      this.generalService.notify('Erro ao atualizar o contato', 'negative');

      return Promise.reject(error);
    } finally {
      this.saving = false;
    }
  }

  public async getAllContact(idlocalidade: any) {
    try {
      if (!idlocalidade) return Promise.resolve(false);

      this.loading = true;

      const { data } = await apiService.get(
        'LocalidadeContatos/Contatos/' + idlocalidade
      );

      this.listContacts = [...data.result];

      this.filterContatoAtivo();

      return this.listContacts;
    } catch (error) {
      this.generalService.notify('Erro ao obter contatos', 'negative');

      return Promise.reject(error);
    } finally {
      this.loading = false;
    }
  }

  public filterContatoAtivo() {
    if (this.contato_ativo) {
      this.localeContactFiltered = new MatTableDataSource<ILocaleContact>(
        this.listContacts.filter((c) => c.contato_ativo === '1')
      );
      this.localeContactFiltered.paginator = this.paginator;
      return;
    }

    this.localeContactFiltered = new MatTableDataSource<ILocaleContact>(
      this.listContacts
    );
    this.localeContactFiltered.paginator = this.paginator;
  }

  createNewContact() {
    this.showLocaleContactForm = false;

    setTimeout(() => {
      this.resetLocaleContactsFormGroup();
      this.showLocaleContactForm = true;
    }, 500);
  }

  public async delete(localeId: number) {
    try {
      const { data } = await apiService.delete(
        `${this.apiResource}/${localeId}`
      );
      return Promise.resolve(data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }

  public async deleteContact(contactId: string) {
    try {
      const { data } = await apiService.delete(
        `${this.apiResourceContact}/${contactId}`
      );

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async searchLocale(search: { [key: string]: string | null }) {
    try {
      this.loading = true;

      let queryString = '';

      for (const key of Object.keys(search))
        if (search[key] || String(search[key]) === '0')
          queryString += `${key}=${search[key]}&`;

      const { data } = await apiService.get(
        `${this.apiResource}?${queryString}`
      );

      this.list = [...data['result']];
      await this.localeClassesService?.setCurrentClassesForAllLocales(
        this.list
      );
    } catch (error) {
      console.error(error);

      this.generalService.notify('Erro ao buscar localidade', 'negative');
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  public async clearSearch() {
    this.search.setValue({
      sigla_icao: '',
      status: '',
      classe: '',
      nm_reduzido_localidade: '',
      localidade_cobranca_vista: '',
    });

    await this.getAll();
  }
}
