import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { UserSearchBarComponent } from '../../components/bars/user-search-bar/user-search-bar.component';
import { UsersService } from '../../services/users-service';
import { UsersTableComponent } from '../../components/tables/users-table/users-table.component';
import { GeneralService } from '../../services/general-service';

@Component({
  selector: 'users-page',
  standalone: true,
  imports: [
    UsersTableComponent,
    UserSearchBarComponent,
    SharedModule
  ],
  templateUrl: './users-page.component.html',
  styleUrl: './users-page.component.scss',
})
export class UsersPageComponent implements OnInit {
  constructor(
    public _userService: UsersService,
    public _generalService: GeneralService
  ){}

  async ngOnInit() {
    this._generalService.setExtraTitle('Usuários e Permissões - Usuários');

    await this._userService.getAll();
  }
}
