import { RouterOutlet } from '@angular/router';
import { Component, Renderer2, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})

export class AppComponent implements OnInit {
  title = 'Portal Localidades';
  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    // this.setZoom(0.75); // Define o zoom para 75%
  }

  // setZoom(level: number) {
  //   this.renderer.setStyle(document.body, 'zoom', `${level * 100}%`);
  // }
}