<form
  [formGroup]="form"
  (ngSubmit)="submit($event)"
>
  <div class="header-dialog">
    @if (!this.isReadonly) {
      @if (data && data.user?.id_usuario) {
        <h2 class="title-dialog" mat-dialog-title>Editar usuário</h2>
      } @else {
        <h2 class="title-dialog" mat-dialog-title>Cadastrar novo usuário</h2>
      }
    } @else {
      <h2 class="title-dialog" mat-dialog-title>Visualizar usuário</h2>
    }
    <button
      mat-icon-button
      type="button"
      class="close-button-dialog"
      (click)="closeDialog()"
    >
      <mat-icon type="button" class="close-icon-dialog">close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="container">
      <div class="flex-row">
        <mat-form-field style="min-width: 350px" class="full-width">
          <mat-label>Nome do usuário</mat-label>

          <input matInput formControlName="nm_usuario"/>
          <mat-error>Informe o nome do usuário</mat-error>
        </mat-form-field>

        <boolean-radio
          style="min-width: 250px"
          [externalControl]="form.controls.ativo"
          label="Situação do usuário"
          firstOptionLabel="Ativo"
          secondOptionLabel="Inativo"
        />
      </div>

      <div class="flex-row">
        <mat-form-field style="min-width: 350px" class="full-width">
          <mat-label>Telefone</mat-label>

          <input
            matInput
            mask-phone
            formControlName="telefone"
          />
          <mat-error>Informe o telefone do usuário</mat-error>
        </mat-form-field>

        <boolean-radio
          style="min-width: 250px"
          [externalControl]="form.controls.situacao"
          label="Organização do usuário"
          firstOptionLabel="DECEA/ATAN"
          secondOptionLabel="Localidade"
          (change)="changeOrganization($event)"
        />
      </div>

      @if (form.controls.situacao.value === '0') {
        <div class="swap">
          @if (!this.isReadonly) {
            <div style="min-width: 350px; max-width: 380px;">
              <list-check [title]="'Localidades'" [itens]="listLocales" [height]="300"/>
            </div>

            <div class="btns-swap">
              <button type="button" mat-mini-fab aria-label="" color="basic" (click)="swap('add')">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>

              <button type="button" mat-mini-fab aria-label="" color="basic" (click)="swap('remove')">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>

              <button type="button" mat-mini-fab aria-label="" color="basic" (click)="swap('add', true)">
                <mat-icon>keyboard_double_arrow_right</mat-icon>
              </button>

              <button type="button" mat-mini-fab aria-label="" color="basic" (click)="swap('remove', true)">
                <mat-icon>keyboard_double_arrow_left</mat-icon>
              </button>
            </div>

            <div style="min-width: 350px; max-width: 350px">
              <list-check
                title="Localidades do Usuário"
                [loading]="this._comboService.loading"
                [itens]="listLocalesUsers"
                [height]="300"
              />
            </div>
          } @else {
            <div style="min-width: 350px; max-width: 850px; width: 850px;">
              <list-check
                title="Localidades do Usuário"
                [loading]="this._comboService.loading"
                [readOnly]="isReadonly"
                [itens]="listLocalesUsers"
                [height]="300"
              />
            </div>
          }
        </div>
      }

      <mat-form-field style="min-width: 350px">
        <mat-label>Login (e-mail)</mat-label>

        <input matInput formControlName="email"/>
        <mat-error *ngIf="form.get('email')?.errors?.['required']">Informe o email</mat-error>
        <mat-error *ngIf="form.get('email')?.errors?.['email']">Email inválido</mat-error>
      </mat-form-field>

      <div class="flex-row">
        <div class="full-width">
          <input-password [label]="'Senha'" [errorMessage]="'Informe a senha'"
                          [formControl]="form.controls.senha"></input-password>
        </div>
        <div class="full-width">
          <input-password [label]="'Confirmar Senha'" [errorMessage]="'Confirme a senha'"
                          [formControl]="form.controls.confirmar_senha"></input-password>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="padding-bottom: 15px">

    <button type="button" style="margin-right:9px;width: 120px" mat-raised-button (click)="closeDialog()">
      <mat-icon>{{ !this.isReadonly ? 'close' : 'arrow_back' }}</mat-icon>
      {{ !this.isReadonly ? 'Cancelar' : 'Voltar' }}
    </button>


    @if (!this.isReadonly) {
      <submit-button label="Salvar" [loading]="_userService.loading" [icon]="'save'" style="margin-right: 10px"/>
    }

  </mat-dialog-actions>
</form>
