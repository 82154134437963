import {Component, Inject, OnInit} from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Validators as ValidatorsCustom } from '../../../shared/validators/Validators';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { SharedModule } from '../../../shared/modules/shared.module';
import { BooleanRadioComponent } from '../../radios/boolean-radio/boolean-radio.component';
import { GeneralService } from '../../../services/general-service';
import { UsersService } from '../../../services/users-service';
import { IUser } from '../../../interfaces/user-interfaces';
import { InputPasswordComponent } from '../../inputs/input-password/input-password.component';
import { distinctUntilChanged } from 'rxjs';
import { ListCheckComponent } from '../../radios/list-check/list-check.component';
import { ICheck } from '../../../interfaces/check-interface';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import { ComboService } from '../../../services/combo-service';

@Component({
  selector: 'create-user-dialog-dialog',
  standalone: true,
  imports: [
    ListCheckComponent,
    InputPasswordComponent,
    BooleanRadioComponent,
    SubmitButtonComponent,
    SharedModule,
  ],
  templateUrl: './create-user-dialog.component.html',
  styleUrl: './create-user-dialog.component.scss',
})
export class CreateUserDialog implements OnInit {
  constructor(
    protected _generalService: GeneralService,
    public _comboService: ComboService,
    protected _userService: UsersService,
    public dialogRef: MatDialogRef<IUser>,
    @Inject(MAT_DIALOG_DATA) public data: { user?: IUser },
    private _formBuilder: FormBuilder,
    private funcionalidadesService: FuncionalidadesService
  ) { }

  form = this._formBuilder.group({
    nm_usuario: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    ativo: ['', [Validators.required]],
    senha: ['', [Validators.required]],
    confirmar_senha: ['', [Validators.required]],
    telefone: ['', [Validators.required]],
    situacao: ['', [Validators.required]],
  });
  public isReadonly: boolean = false;

  listLocales: ICheck[] = [];
  listLocalesUsers: ICheck[] = [];

  ngOnInit() {
    this.isReadonly = !this.funcionalidadesService.canAccess(
      FuncionalidadesConst.MANUTENCAO_DE_USUARIOS,
      false
    );

    if (this.isReadonly) {
      this.form.disable();
    }

    this.addValidators();
    if (this.data?.user?.id_usuario) {
      this.form.patchValue({
        nm_usuario: this.data.user.nm_usuario,
        email: this.data.user.email,
        ativo: this.data.user.ativo?.toString(),
        senha: '*******',
        confirmar_senha: '*******',
        telefone: this.data.user.telefone,
        situacao: '1',
      });

      if (!this.data?.user?.DECEA) {
        this.form.patchValue({
          situacao: '0',
        });

        this.carregarLocalidadesListCheck();
      }
    }

    if (!this._userService.user.DECEA) {
      this.form.get('situacao')?.patchValue('0');
      this.form.get('situacao')?.disable();

      this.carregarLocalidadesListCheck();

    }
  }

  get localidadesCombo() {
    if (!this._comboService.localidades) return [];

    let locales = this._comboService.localidades.map(
      (l) =>
      ({
        id: l.id,
        name: `${l.complemento} - ${l.value}`,
        check: false,
      } as ICheck)
    );

    if (!this._userService.user.DECEA) {
      locales = locales.filter((x) =>
        this._userService.user.localidades.includes(x.id)
      );
    }

    return locales;
  }

  private carregarLocalidadesListCheck() {
    const locales = this.localidadesCombo;
    const localesUser = this.data?.user?.locales ?? [];

    this.listLocalesUsers = locales.filter((l) =>
      localesUser.includes(l.id)
    );
    this.listLocales = locales.filter((l) => !localesUser?.includes(l.id));
  }

  private addValidators() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      const senha = this.form.get('senha') as FormControl;
      const confirmarSenha = this.form.get('confirmar_senha') as FormControl;

      if (senha.value) {
        this.form
          .get('confirmar_senha')
          ?.addValidators([ValidatorsCustom.notEqualText(senha)]);
      }

      if (confirmarSenha.value) {
        this.form
          .get('senha')
          ?.addValidators([ValidatorsCustom.notEqualText(confirmarSenha)]);

        senha.updateValueAndValidity({ emitEvent: false });
        confirmarSenha.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  public changeOrganization(value: number) {
    if (this.data?.user?.id_usuario) {
      if (!this.data.user.DECEA) {
        const locales = this.localidadesCombo;

        const localesUser = this.data.user.locales;

        this.listLocalesUsers = locales.filter((l) =>
          localesUser.includes(l.id)
        );

        this.listLocales = locales.filter((l) => !localesUser.includes(l.id));

        return;
      }
    }

    if (value == 0) {
      this.listLocales = this.localidadesCombo;
    }
  }

  public async submit(e: Event) {
    e.preventDefault();
    try {
      this.form.get('ativo')?.markAsDirty();
      this.form.get('situacao')?.markAsDirty();

      const data = {
        ...this.form.getRawValue(),
        locales: [] as Number[],
      };

      if (data.situacao === '0') {
        if (this.listLocalesUsers.length <= 0) {
          this._generalService.notify(
            'É necessario selecionar ao menos uma localidade.',
            'negative'
          );
          return;
        } else {
          data.locales = this.listLocalesUsers.map((l) => l.id);
        }
      }

      if (this.form.status === 'VALID') {
        if (this.data?.user?.id_usuario) {
          const result = await this._userService.update(
            this.data.user.id_usuario,
            data
          );
          this._generalService.notify(
            'Usuário atualizado com sucesso',
            'positive'
          );
          this.form.patchValue({ ...result });
        } else {
          await this._userService.create(data);
          this._generalService.notify('Usuario criado com sucesso', 'positive');
        }
        this.dialogRef.close();
        await this._userService.getAll();
      }
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }

  swap(type: 'add' | 'remove', all = false) {
    if (all) {
      if (type == 'add' && this.listLocales.length > 0) {
        this.listLocalesUsers = [
          ...this.listLocalesUsers,
          ...this.listLocales,
        ].map((e) => ({ ...e, check: false }));
        this.listLocales = [];
      }

      if (type == 'remove' && this.listLocalesUsers.length > 0) {
        this.listLocales = [...this.listLocales, ...this.listLocalesUsers].map(
          (e) => ({ ...e, check: false })
        );
        this.listLocalesUsers = [];
      }
    } else {
      if (type == 'add' && this.listLocales.length > 0) {
        this.listLocalesUsers = [
          ...this.listLocalesUsers,
          ...this.listLocales.filter((i) => i.check),
        ].map((e) => ({ ...e, check: false }));
        this.listLocales = [...this.listLocales.filter((i) => !i.check)];
      }

      if (type == 'remove' && this.listLocalesUsers.length > 0) {
        this.listLocales = [
          ...this.listLocales,
          ...this.listLocalesUsers.filter((i) => i.check),
        ].map((e) => ({ ...e, check: false }));
        this.listLocalesUsers = [
          ...this.listLocalesUsers.filter((i) => !i.check),
        ];
      }
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
