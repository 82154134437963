<div style="padding: 15px">
  <form [formGroup]="form" class="select-group">
    <mat-form-field class="full-width">
      <mat-label>Grupo</mat-label>
      <mat-select formControlName="group" (selectionChange)="selectedGrupo()">
        @for (currentClass of groupsCombo; track currentClass) {
        <mat-option [value]="currentClass.id_grupo">{{
          currentClass.nm_grupo
          }}</mat-option>
        }
      </mat-select>
      <mat-error>Informe o grupo</mat-error>
    </mat-form-field>
  </form>

  @if (_groupsService.loading) {
  <div class="full-width" style="display: flex; justify-content: center; height: 500px">
    <mat-spinner diameter="24" />
  </div>
  } @else {
  <div class="swap">

    @if(!isReadonly){
    <div style="min-width: 500px">
      <list-check [title]="'Funcionalidades'" [itens]="features" [readOnly]="isReadonly" />
    </div>

    <div class="btns-swap">
      <button mat-mini-fab aria-label="" color="basic" (click)="swap('add')" [disabled]="isReadonly">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>

      <button mat-mini-fab aria-label="" color="basic" (click)="swap('remove')" [disabled]="isReadonly">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      <button mat-mini-fab aria-label="" color="basic" (click)="swap('add', true)" [disabled]="isReadonly">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>

      <button mat-mini-fab aria-label="" color="basic" (click)="swap('remove', true)" [disabled]="isReadonly">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
    </div>

    <div style="min-width: 700px">
      <list-check [chooseReadOnly]="true" [title]="'Funcionalidades do grupo'"
        [itens]="groupFeaturesService.selectedFeatures" [readOnly]="isReadonly" />
    </div>

    }@else{
    <div style="min-width: 1280px">
      <list-check [chooseReadOnly]="true" [title]="'Funcionalidades do grupo'"
        [itens]="groupFeaturesService.selectedFeatures" [readOnly]="isReadonly" />
    </div>
    }

  </div>

  <div class="container-btns">
    @if(!isReadonly){
    <button type="button" style="width: 120px" (click)="cancel()" mat-raised-button>
      <mat-icon>close</mat-icon> Cancelar
    </button>

    <button style="min-width: 110px" type="button" color="primary" (click)="saveFeatures()" mat-raised-button>
      <mat-icon>save</mat-icon> Salvar
    </button>
    }
  </div>
  }
</div>