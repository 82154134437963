import { Component } from '@angular/core';
import { SharedModule } from '../../shared/modules/shared.module';
import { FormBuilder, Validators } from '@angular/forms';
import { SubmitButtonComponent } from '../../components/buttons/submit-button/submit-button.component';
import { UsersService } from '../../services/users-service';
import { Router } from '@angular/router';
import { InputPasswordComponent } from '../../components/inputs/input-password/input-password.component';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [InputPasswordComponent, SubmitButtonComponent, SharedModule],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  constructor(
    protected _userService: UsersService,
    private _formBuilder: FormBuilder,
    private _router: Router
  ) { }

  form = this._userService.formLogin;

  public async submit(e: Event) {
    e.preventDefault();
    try {
      if (this.form.status === 'VALID') {
        const postData = this.form.getRawValue();

        await this._userService.login(postData);

        await this._router.navigate(['']);
      }
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }

  forgotPassword() {
    window.location.href = '/forgot-password';
  }

}
