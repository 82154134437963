<form
  [formGroup]="localeClassService.data"
  (submit)="trySubmitting()"
  style="margin-bottom:20px"
>
  <mat-grid-list cols="2" rowHeight="70px" gutterSize="15">
    <mat-grid-tile>
      <mat-form-field class="full-width">
        <mat-label>Classe</mat-label>

        <mat-select formControlName="id_classe">
          @for (currentClass of _comboService.classes; track currentClass) {
            <mat-option [value]="currentClass.id">{{ currentClass.value }}</mat-option>
          }
        </mat-select>
        <mat-error>Informe a classe</mat-error>
      </mat-form-field>
    </mat-grid-tile>

    <mat-grid-tile>
      <simple-datepicker
        [externalControl]="localeClassService.data.controls.data_vigencia"
      />
    </mat-grid-tile>
  </mat-grid-list>

  <div class="text-center" style="margin-top:18px">
    <submit-button
      label="Salvar"
      [loading]="saving"
      icon="save"
    />

    <button
      type="button"
      style="min-width:100px;margin-left:10px"
      mat-button mat-raised-button
      (click)="localeClassService.clearForm()"
    >
      <mat-icon>close</mat-icon>
      Cancelar
    </button>
  </div>
</form>
