import {Component, Input} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'input-text',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './input-text.component.html',
  styleUrl: './input-text.component.scss'
})

export class InputTextComponent {
  @Input()
  label: string = 'Label';

  @Input()
  name: string = '';
}
