import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { GeneralService } from '../../../services/general-service';
import { IAutoCompleteOption } from '../../../interfaces/auto-complete-interfaces';
import { LocalesTarriffsService } from '../../../services/locales-tarriffs-service';
import { SharedModule } from '../../../shared/modules/shared.module';
import { LocaleTariffsTableComponent } from '../../tables/locale-tariffs-table/locale-tariffs-table.component';
import { CancellationConfirmationDialog } from '../../dialogs/cancellation-confirmation-dialog/cancellation-confirmation-dialog.component';
import { LocalesService } from '../../../services/locales-service';
import { MatDialog } from '@angular/material/dialog';
import { LocaleTariffsRecipientFormComponent } from '../locale-tariffs-recipient-form/locale-tariffs-recipient-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { ILocaleTariff } from '../../../shared/interfaces/ILocaleTariff';
import { ITarifaVigencia } from '../../../shared/interfaces/dtos/ITarifaVigencia';
import { ComboService } from '../../../services/combo-service';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { Router } from '@angular/router';

@Component({
  selector: 'locale-tariffs-form',
  standalone: true,
  imports: [
    LocaleTariffsTableComponent,
    SubmitButtonComponent,
    LocaleTariffsRecipientFormComponent,
    SharedModule,
  ],
  templateUrl: './locale-tariffs-form.component.html',
  styleUrl: './locale-tariffs-form.component.scss',
})
export class LocaleTariffsFormComponent implements OnInit {
  tarifas: IAutoCompleteOption[] = [];

  listVigencias = new MatTableDataSource<ITarifaVigencia>();
  listVigenciasDates: ITarifaVigencia[] = [];

  taxName = 'Tarifa';

  idVigencia = 0;

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_CONFIGURACOES_TARIFARIAS,
    false
  );

  constructor(
    protected _comboService: ComboService,
    protected generalService: GeneralService,
    protected localesTarriffsService: LocalesTarriffsService,
    protected localesService: LocalesService,
    protected localesTariffsService: LocalesTarriffsService,
    protected _funcionalidadesService: FuncionalidadesService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  async ngOnInit() {
    await this.loadTarifas();

    if (this.isReadonly) {
      this.localesTarriffsService.readonly = true;
    }
  }

  async loadTarifas() {
    this.tarifas = this._comboService.tarifas || [];

    if (this.tarifas?.length <= 0) {
      await this._comboService.getCombos();
      this.tarifas = this._comboService.tarifas;
    }

    this.onSelectedTariff({ value: this.tarifas[0]?.id } as MatSelectChange);
  }

  get form(): FormGroup {
    return this.localesTariffsService.formTarifaDestinatario;
  }

  get recipients(): FormArray {
    return this.localesTariffsService.formTarifaDestinatario?.get(
      'recipients'
    ) as FormArray;
  }

  openCancelDialog(): void {

    if (this.isReadonly) {
      this.router.navigate(['locales']);
      this.localesTarriffsService.readonly = true;
    } else{
      const dialogRef = this.dialog.open(CancellationConfirmationDialog, {
        data: {
          title: 'Cancelar edição das configurações tarifárias',
          question:
            'Tem certeza que deseja cancelar a edição das configurações tarifárias?',
          warning: 'Todas as informações não salvas serão perdidas.',
        },
      });
  
      dialogRef.afterClosed().subscribe((cancel: boolean) => {
        if (cancel) {
          void this.localesService.closeLocaleForm(true);
        }
      });
    }

    
  }

  onSelectedTariff(event: MatSelectChange) {
    this.idVigencia = 0;

    const selectedValue = event.value;
    const selectedOption = this.tarifas.find(
      (tarifa) => tarifa.id === selectedValue
    );
    this.taxName = selectedOption ? selectedOption.value : '';

    this.localesTariffsService.resetForm();
    this.localesTariffsService.formTarifaDestinatario.patchValue({
      tarifa: selectedValue,
    });

    void this.localesTariffsService.loadVigencias(
      selectedValue,
      this.localesService.locale.value.id_localidade || 0
    );
  }

  onAdd() {
    const idVigencia = this.form.get('tarifa')?.value;

    this.idVigencia = 0;

    this.localesTariffsService.resetForm();

    this.localesTariffsService.readonly = false;

    this.onSelectedTariff({ value: idVigencia } as MatSelectChange);
  }

  private limparLinhaEmBranco() {
    const recipients = this.form.get('recipients') as FormArray;

    for (let index = 0; index < recipients.controls.length; index++) {
      const control = recipients.get(index.toString());

      if (
        !control?.get('recipient')?.value &&
        !control?.get('percentual')?.value
      ) {
        this.localesTariffsService.removeRecipient(index--);
      }
    }

    if (recipients.controls.length == 0) {
      this.localesTariffsService.addRecipient();
    }

    if (this.localesTariffsService.listDestinatariosPaged.length <= 3) {
      this.localesTariffsService.paginatorDestinatarios.pageIndex = 0;
      this.localesTariffsService.paginateDestinatarios(0, 3);
    }
  }

  private validSubmit(form: any, tariffRequestDTO: any): boolean {
    if (this.localesTariffsService.readonly) {
      this.generalService.notify(
        'Vigência anterior a data atual, não é possivel editar',
        'negative'
      );
      return false;
    }

    if (this.form.status !== 'VALID') {
      this.form.patchValue({});
      this.form.markAllAsTouched();
      return false;
    }

    const somaPercentuais = form.recipients
      .map((r: any) => r.percentual)
      .reduce((partialSum: number, a: number) => partialSum + a, 0);

    if (somaPercentuais != 100) {
      this.generalService.notify(
        'A soma dos percentuais não é 100%',
        'negative'
      );
      return false;
    }

    const tarifasDatas = this.localesTariffsService.listVigenciasDates
      .filter(
        (v) => v.idTariffVigencia !== tariffRequestDTO.id_tarifa_vig_localidades
      )
      .map((d) => d.date);

    if (tarifasDatas.includes(tariffRequestDTO.data_vigencia)) {
      this.generalService.notify('Vigencia já cadastrada', 'negative');
      return false;
    }

    return true;
  }

  async onSubmit() {
    try {
      this.limparLinhaEmBranco();

      const form = this.form.getRawValue();
      const idTariff = form.tarifa;
      this.idVigencia = form.id_vigencia ? form.id_vigencia : this.idVigencia;
      const idLocalidade = this.localesService.locale.value.id_localidade || 0;
      const tariffRequestDTO = {
        id_tarifa_vig_localidades: this.idVigencia,
        id_tarifa: form.tarifa,
        id_localidade: this.localesService.locale.value.id_localidade || 0,
        data_vigencia: form.data_vigencia,
        destinatarios: form.recipients.map((e: any) => ({
          id_entidade_externa: e.recipient,
          percentual: e.percentual,
        })),
      };

      if (!this.validSubmit(form, tariffRequestDTO)) return;

      if (this.idVigencia > 0) {
        await this.localesTarriffsService.update(
          tariffRequestDTO as ILocaleTariff,
          this.idVigencia
        );
        this.generalService.notify(
          'Vigência atualizada com sucesso',
          'positive'
        );
      } else {
        const retorno = await this.localesTarriffsService.create(
          tariffRequestDTO as ILocaleTariff
        );
        this.idVigencia = retorno.id_tarifa_vig_localidades;
        this.localesTariffsService.formTarifaDestinatario.patchValue({
          id_vigencia: this.idVigencia,
        });
        this.generalService.notify('Vigência criada com sucesso', 'positive');
      }

      this.localesTariffsService.loadVigencias(idTariff, idLocalidade);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  }
}
