<div style="padding: 15px">
  @if (localesService.showLocaleForm) {
    <locale-panel/>
  } @else {
    @if (canCreate) {
      <button
        style="position: absolute; top: 28px; right: 15px"
        color="primary"
        mat-flat-button
        (click)="localesService.openLocaleForm()"
      >
        <mat-icon>add</mat-icon>
        Incluir localidade
      </button>
    }

    <locale-search-bar/>

    @if (localesService.loading) {
      <div class="full-width" style="display: flex; justify-content: center">
        <mat-spinner diameter="24"/>
      </div>
    } @else {
      <locales-table/>
    }
  }
</div>
