<form [formGroup]="externalEntity" (ngSubmit)="submit()">
  <div class="header-dialog">
    @if (!this.isReadonly) {
    @if (externalEntity.value.id_entidade_externa) {
    <h2 class="title-dialog" mat-dialog-title>Editar entidade externa</h2>
    }@else {
    <h2 class="title-dialog" mat-dialog-title>Cadastrar entidade externa</h2>
    }
    }@else {
    <h2 class="title-dialog" mat-dialog-title>Visualizando entidade externa</h2>
    }
    <button type="button" mat-icon-button class="close-button-dialog" (click)="closeDialog()">
      <mat-icon type="button" class="close-icon-dialog">close</mat-icon>
    </button>
  </div>
  
  
  <!-- @if (externalEntity.value.id_entidade_externa) {
  @if (!this.isReadonly) {
  <h2 mat-dialog-title>Editar entidade externa</h2>
  } @else {
  <h2 mat-dialog-title>Visualizando entidade externa</h2>
  }
  } @else {
  <h2 mat-dialog-title>Cadastrar entidade externa</h2>
  } -->

  <mat-dialog-content style="width:800px">
    <div style="float:left;width:30%;margin-right:4%">
      <mat-form-field class="full-width">
        <mat-label>CNPJ</mat-label>

        <input data-cy="external-entity-cnpj-input" type="text" matInput maxlength="18" formControlName="nr_cnpj"
          (input)="maskCNPJ()" />

        <!-- Verifica se o CNPJ é inválido -->
        <mat-error data-cy="invalid-cnpj-warning" *ngIf="externalEntity.controls['nr_cnpj'].hasError('invalid')">
          CNPJ inválido
        </mat-error>

        <!-- Verifica se o CNPJ não existe -->
        <mat-error data-cy="nonexistent-cnpj-warning"
          *ngIf="externalEntity.controls['nr_cnpj'].hasError('nonexistent')">
          CNPJ inválido
        </mat-error>

        <!-- Verifica se o CNPJ já existe -->
        <mat-error data-cy="exists-cnpj-warning" *ngIf="externalEntity.controls['nr_cnpj'].hasError('exists')">
          CNPJ já existe
        </mat-error>

        <!-- Verifica se o CNPJ é necessário -->
        <mat-error data-cy="needed-cnpj-warning" *ngIf="externalEntity.controls['nr_cnpj'].hasError('needed')">
          Informe o CNPJ
        </mat-error>
      </mat-form-field>
    </div>

    <div style="float:left;width:66%">
      <mat-form-field class="full-width">
        <mat-label>Nome</mat-label>

        <div class="dense-input">
          <input data-cy="external-entity-name-input" type="text" width="150" maxlength="150" matInput
            formControlName="nm_entidade_externa" />
        </div>
        <mat-error data-cy="needed-name-warning">
          Informe o nome
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" style="padding-bottom: 15px">
    <button type="button" style="margin-right:9px; width: 110px;" mat-button mat-raised-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
      {{ isReadonly ? 'Voltar' : 'Cancelar' }}
    </button>

    @if (!this.isReadonly) {
    <submit-button data-cy="submit-external-entity-button" label="Salvar" [loading]="saving" [icon]="'save'"
      style="margin-right:10px;" />
    }

  </mat-dialog-actions>
</form>