import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatGridTile } from '@angular/material/grid-list';
import { MatLabel } from '@angular/material/form-field';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '../../../shared/modules/shared.module';

@Component({
  selector: 'boolean-radio',
  standalone: true,
  imports: [
    MatGridTile,
    MatLabel,
    MatRadioButton,
    MatRadioGroup,
    ReactiveFormsModule,
    SharedModule,
  ],
  templateUrl: './boolean-radio.component.html',
  styleUrl: './boolean-radio.component.scss',
})
export class BooleanRadioComponent {
  @Input() externalControl: FormControl = new FormControl(
    '',
    Validators.required
  );
  @Input() label: string = 'Label';
  @Input() firstOptionLabel: string = 'First Option Label';
  @Input() secondOptionLabel: string = 'Second Option Label';
  @Output() change = new EventEmitter<number>();
}
