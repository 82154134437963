import {Component, Input, OnInit} from '@angular/core';
import {
  FilteredAutoCompleteComponent
} from "../../auto-completes/filtered-auto-complete/filtered-auto-complete.component";
import {MatGridTile} from "@angular/material/grid-list";
import {UsersService} from "../../../services/users-service";
import {GeneralService} from "../../../services/general-service";
import {FormControl, Validators} from "@angular/forms";
import {IAutoCompleteOption} from "../../../interfaces/auto-complete-interfaces";

@Component({
  selector: 'select-icao',
  standalone: true,
  imports: [
    FilteredAutoCompleteComponent,
    MatGridTile
  ],
  templateUrl: './select-icao.component.html',
  styleUrl: './select-icao.component.scss'
})

export class SelectIcaoComponent implements OnInit {
  @Input() externalControl: FormControl = new FormControl('', [Validators.required, Validators.minLength(4)]);

  localeICAOs: IAutoCompleteOption[] = [];
  isSingleLocale = false;

  constructor(
    protected generalService: GeneralService,
    protected usersService: UsersService,
  ) {
  }

  ngOnInit() {
    // SINGLE LOCALE
    if (
      this.usersService.user.localidades.length === 1
      && this.usersService.currentLocale
    ) {
      this.isSingleLocale = true;

      const { sigla_icao } = this.usersService.currentLocale;

      this.localeICAOs = [...this.generalService.data['siglasicao'].filter(
        (icao) => icao.value === sigla_icao,
      )];

      // single ICAO
      this.externalControl.setValue(this.generalService.data['siglasicao'].find(
        (icao) => icao.value === sigla_icao
      )?.value);
    } else if (this.usersService.user.DECEA) {
      // DECEA USER
      this.localeICAOs = [...this.generalService.data['siglasicao']];
    } else if (this.usersService.user.localidades.length > 1) {
      // MULTIPLES LOCALES
      if (this.generalService.data['siglasicao']) {
        this.localeICAOs = [...this.generalService.data['siglasicao'].filter(
          (locale) => this.usersService.currentLocales.find(
            (currentLocale) => currentLocale.sigla_icao === locale.value,
          ),
        )];
      }
    }
  }
}
