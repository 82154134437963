import { Component, OnInit } from '@angular/core';

import { GeneralService } from '../../../services/general-service';
import { LocalesService } from '../../../services/locales-service';
import { ComboService } from '../../../services/combo-service';
import { SharedModule } from '../../../shared/modules/shared.module';
import { SelectIcaoComponent } from '../../selects/select-icao/select-icao.component';
import { BooleanRadioComponent } from '../../radios/boolean-radio/boolean-radio.component';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { ClearFilterButtonComponent } from '../../buttons/clear-filter-button/clear-filter-button.component';
import { SimpleDatepickerComponent } from '../../datepickers/simple-datepicker.component';
import { LogsService } from '../../../services/logs-service';
import { IComboOption } from '../../../interfaces/auto-complete-interfaces';
import { toISO8601 } from '../../../services/date-service';

export interface ILocaleClass {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'logs-search-bar',
  standalone: true,
  imports: [
    SharedModule,
    SelectIcaoComponent,
    SubmitButtonComponent,
    BooleanRadioComponent,
    ClearFilterButtonComponent,
    SimpleDatepickerComponent,
  ],
  templateUrl: './logs-search-bar.component.html',
  styleUrl: './logs-search-bar.component.scss',
})
export class LogsSearchBarComponent {
  constructor(
    protected generalService: GeneralService,
    protected _comboService: ComboService,
    protected _logsService: LogsService,
    protected localesService: LocalesService
  ) { }

  async ngAfterViewInit() {
    await this._comboService.getUsers();
  }

  funcionalidadesLog: IComboOption[] = [
    { id: 'LOGIN', value: 'Login' },
    { id: 'LOGOUT', value: 'Logout' },
    { id: 'MOVIMENTACAO_INCLUSAO', value: 'Movimentos - inclusão' },
    { id: 'MOVIMENTACAO_ALTERACAO', value: 'Movimentos - alteração' },
    { id: 'MOVIMENTACAO_EXCLUSAO', value: 'Movimentos - exclusão' },
    { id: 'MOVIMENTACAO_GERACAO_DAT', value: 'Movimentos - geração de DAT' },
    { id: 'REPASSE_INCLUSAO', value: 'Repasses - inclusão' },
    { id: 'REPASSE_EXCLUSAO', value: 'Repasses - exclusão' },
    { id: 'BAIXA_INCLUSAO', value: 'Baixas – inclusão' },
    { id: 'BAIXA_EXCLUSAO', value: 'Baixas - exclusão' },
  ];

  get searchForm() {
    return this._logsService.search;
  }
  protected async submit() {
    const dataForm = { ...this._logsService.search.value };

    if (!!dataForm.data_fim) {
      dataForm.data_fim = toISO8601(dataForm.data_fim);
    }

    if (!!dataForm.data_inicio) {
      dataForm.data_inicio = toISO8601(dataForm.data_inicio);
    }

    await this._logsService.searchLocale(dataForm);
  }
}
