<div style="padding: 15px">
  <logs-search-bar />

  @if (logsService.loading) {
  <div class="full-width" style="display: flex; justify-content: center">
    <mat-spinner diameter="24" />
  </div>
  } @else {
  <logs-table />
  }
</div>
