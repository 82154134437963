<div class="full-width">
  <mat-form-field
    [id]="inputElementId"
    class="full-width"
  >
    <mat-label>{{ label }}</mat-label>

    <input
      style="text-transform:uppercase"
      type="text"
      matInput
      [readonly]="disabled"
      [maxlength]="maxLength"
      [formControl]="externalControl"
      (input)="filterItems($event)"
      (focus)="openFilteredResults()"
      (blur)="clearFilteredResults(100)"
      [minlength]="minLength"
      [maxlength]="maxLength"
    />
    <mat-error>{{ validationErrorMessage }}</mat-error>
  </mat-form-field>

  <div
    [id]="fullOverlayElementId"
    class="filtered-auto-complete-results-full-overlay"
  ></div>

  <div
    [id]="filteredResultsElementId"
    class="filtered-auto-complete-results"
    style="box-shadow:0 0 15px rgba(0,0,0,0.3)"
  >
    @if (!filteredResults.length) {
      <div style="padding:15px">Nenhum resultado</div>
    } @else {
      @for (result of filteredResults; track result) {
        <div
          class="filtered-auto-complete-results-item"
          [ngClass]="externalControl.value === result['value'] ? 'selected': ''"
          (click)="setSelectedItem(result)"
        >
          {{ result['value'] }}
        </div>
      }
    }
  </div>
</div>
