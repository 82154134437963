<button
  [attr.data-cy]="dataCy"
  style="min-width:110px"
  type="submit"
  color="primary"
  mat-raised-button
>
  <div style="display: flex; align-items: center; justify-content: center;">
    @if (loading) {
      <mat-spinner diameter="20" class="mat-spinner-white"></mat-spinner>
    } @else {
      @if (icon) {
        <mat-icon style="font-size:17px;margin-top:7px;margin-left:-5px">{{ icon }}</mat-icon>
      }
      <span style="margin-left: 5px; height: 21px">{{ label }}</span>
    }
  </div>
</button>
