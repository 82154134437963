import { Component, signal } from '@angular/core';
import { SubmitButtonComponent } from '../../components/buttons/submit-button/submit-button.component';
import { SharedModule } from '../../shared/modules/shared.module';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UsersService } from '../../services/users-service';
import { Validators as ValidatorsCustom } from '../../shared/validators/Validators';
import { distinctUntilChanged } from 'rxjs';
import { InputPasswordComponent } from '../../components/inputs/input-password/input-password.component';
import { GeneralService } from '../../services/general-service';
import { ListCheckComponent } from '../../components/radios/list-check/list-check.component';
import { ICheck } from '../../interfaces/check-interface';
import { MatTableDataSource } from '@angular/material/table';
import { IGroup } from '../../interfaces/groups-interfaces';
import { GroupsService } from '../../services/groups-service';
import { MatBadgeModule } from '@angular/material/badge';
import { LocalesService } from '../../services/locales-service';
import { ILocale } from '../../interfaces/locale-interfaces';
import { FuncionalidadesService } from '../../services/funcionalidades-service';
import FuncionalidadesConst from '../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'app-user-settings-page',
  standalone: true,
  imports: [
    ListCheckComponent,
    InputPasswordComponent,
    SubmitButtonComponent,
    SharedModule,
    MatBadgeModule,
  ],
  templateUrl: './user-settings-page.component.html',
  styleUrl: './user-settings-page.component.scss',
})
export class UserSettingsPageComponent {
  listLocalesUsers: ICheck[] = [];

  readonly panelOpenState = signal(true);
  readonly trocaSenhapanelOpenState = signal(false);

  constructor(
    protected _userService: UsersService,
    protected _generalService: GeneralService,
    private _formBuilder: FormBuilder,
    private _groupsService: GroupsService,
    private _localesService: LocalesService,
    private _funcionalidadesService: FuncionalidadesService
  ) { }

  form = this._formBuilder.group({
    senha: ['', [Validators.required]],
    confirmar_senha: ['', [Validators.required]],
  });

  search = this._formBuilder.group({
    sigla_icao: '',
    status: '',
    classe: '',
    nm_reduzido_localidade: '',
    localidade_cobranca_vista: '',
    id_usuario: '',
  });

  displayedColumnsGroups: string[] = ['nm_grupo', 'situacao'];

  displayedColumnsLocales: string[] = ['nm_localidade', 'status'];

  hidden = false;

  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  }
  dataSourceLocales = new MatTableDataSource<ILocale>();
  dataSourceGroups = new MatTableDataSource<IGroup>();

  ngOnInit() {
    this.addValidators();
    this.getUser();
    this._generalService.setExtraTitle('Configurações Pessoais');
  }

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.CONFIGURACOES_PESSOAIS,
    false
  );


  async getUser() {
    this.search.patchValue({
      id_usuario: this._userService.user.usuarioId.toString(),
    });

    try {
      await Promise.all([
        this._localesService.searchLocale(this.search.value),
        this._groupsService.search(this.search.value),
      ]);

      this.dataSourceLocales = new MatTableDataSource<ILocale>(
        this._localesService.list
      );
      this.dataSourceGroups = new MatTableDataSource<IGroup>(
        this._groupsService.list
      );
    } catch (error) {
      console.error('Error ao efetuar busca: ', error);
    }
  }

  public async submit(e: Event) {
    e.preventDefault();
    try {
      if (this.form.status === 'VALID') {
        const postData = {
          senha: this.form.controls.senha?.value ?? '',
          confirmar_senha: this.form.controls.senha?.value ?? '',
          nm_usuario: this._userService.user.nome,
          email: this._userService.user.email,
          ativo: 1,
          telefone: this._userService.user.telefone,
          locales: this._userService.user.localidades,
        };

        await this._userService.update(
          this._userService.user.usuarioId,
          postData
        );

        this._generalService.notify(
          'Troca se senha efetuada com sucesso.',
          'positive'
        );

        setTimeout(() => {
          this._userService.clearToken();
        }, 3000);
      }
    } catch (error) {
      console.error(error);

      return Promise.reject(error);
    }
  }

  private addValidators() {
    this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      const senha = this.form.get('senha') as FormControl;
      const confirmarSenha = this.form.get('confirmar_senha') as FormControl;

      if (senha.value) {
        this.form
          .get('confirmar_senha')
          ?.addValidators([ValidatorsCustom.notEqualText(senha)]);
      }

      if (confirmarSenha.value) {
        this.form
          .get('senha')
          ?.addValidators([ValidatorsCustom.notEqualText(confirmarSenha)]);

        senha.updateValueAndValidity({ emitEvent: false });
        confirmarSenha.updateValueAndValidity({ emitEvent: false });
      }
    });
  }
}
