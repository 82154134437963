import {Component, Input} from '@angular/core';
import {MAT_DATE_LOCALE, provideNativeDateAdapter} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'simple-datepicker',
  templateUrl: './simple-datepicker.component.html',
  standalone: true,
  providers: [
    provideNativeDateAdapter(
      {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    ),
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
  ],
  imports: [MatFormFieldModule, MatInputModule, MatDatepickerModule, ReactiveFormsModule],
})

export class SimpleDatepickerComponent {
  @Input() label: string = 'Vigência';
  @Input() neededValidationErrorMessage: string = 'Informe a data';
  @Input() invalidValidationErrorMessage: string = 'Data inválida';
  @Input() externalControl: FormControl = new FormControl(
    '',
    [Validators.required, Validators.minLength(4)],
  );
  @Input() blockPastDays: boolean = true;
  @Input() disableToggle: boolean = false;

  protected today = new Date();
}
