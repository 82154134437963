import {Component} from '@angular/core';
import {MatError, MatFormField} from "@angular/material/form-field";
import {MatOption, MatSelect, MatLabel} from "@angular/material/select";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {MatCardContent} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {
  FilteredAutoCompleteComponent
} from "../../auto-completes/filtered-auto-complete/filtered-auto-complete.component";
import {MatGridList, MatGridTile} from "@angular/material/grid-list";
import {GeneralService} from "../../../services/general-service";
import {LocalesService} from "../../../services/locales-service";
import {SubmitButtonComponent} from "../../buttons/submit-button/submit-button.component";
import {ClearFilterButtonComponent} from "../../buttons/clear-filter-button/clear-filter-button.component";
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {BooleanRadioComponent} from "../../radios/boolean-radio/boolean-radio.component";
import {ComboService} from '../../../services/combo-service';
import {SelectIcaoComponent} from "../../selects/select-icao/select-icao.component";

export interface ILocaleClass {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'locale-search-bar',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    MatInput,
    MatButton,
    FilteredAutoCompleteComponent,
    MatGridList,
    MatGridTile,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    SubmitButtonComponent,
    ClearFilterButtonComponent,
    MatRadioGroup,
    MatRadioButton,
    BooleanRadioComponent,
    SelectIcaoComponent,
  ],
  templateUrl: './locale-search-bar.component.html',
})

export class LocaleSearchBarComponent {
  constructor(
    protected generalService: GeneralService,
    protected _comboService: ComboService,
    protected localesService: LocalesService,
  ) {}

  classes: ILocaleClass[] = [
    {value: 1, viewValue: 'A'},
    {value: 2, viewValue: 'B'},
    {value: 3, viewValue: 'C'},
  ];

  protected async submit() {
    await this.localesService.searchLocale(this.localesService.search.value);
  }
}
