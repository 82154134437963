<form [formGroup]="search" (ngSubmit)="submit()">
  <div class="container-group-search">
    <mat-form-field style="min-width: 300px">
      <mat-label>Nome do grupo</mat-label>

      <input matInput formControlName="nm_group" />
    </mat-form-field>

    <boolean-radio
      [externalControl]="search.controls.ativo"
      label="Situação do grupo"
      firstOptionLabel="Ativo"
      secondOptionLabel="Inativo"
    />

    <div class="buttons-search">
      <submit-button label="Buscar" [icon]="'search'" />

      <clear-filter-button style="margin-left: 10px" (click)="clearSearch()" />
    </div>

    @if(!isReadonly){
    <button
      class="btn-add-group"
      color="primary"
      type="button"
      mat-flat-button
      (click)="showModalNovoGroup()"
    >
      <mat-icon>add</mat-icon> Incluir grupo
    </button>
    }
  </div>
</form>
