import {AfterViewInit, Component, inject, ViewChild} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {IconButton} from "../../buttons/icon-button/icon-button.component";
import {MatPaginator} from "@angular/material/paginator";
import {GeneralService} from "../../../services/general-service";
import {MatCard, MatCardContent} from "@angular/material/card";
import {dateWithZeroHour, formatDateAndTime, toBrazilianDate, toUSADate} from "../../../services/date-service";
import {GeneralRemoveDialog} from "../../dialogs/general-remove-dialog/general-remove-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import {LocaleTaxesService} from "../../../services/locale-taxes-service";
import {ILocaleTax} from "../../../interfaces/locale-taxes-interfaces";
import {FuncionalidadesService} from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'locale-taxes-table',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatTable,
    MatHeaderCellDef,
    IconButton,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatPaginator,
    MatCard,
    MatCardContent,
    MatIcon
  ],
  templateUrl: './locale-taxes-table.component.html',
  styleUrl: './locale-taxes-table.component.scss'
})

export class LocaleTaxesTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['arrow', 'percentual_taxa', 'data_vigencia', 'actions'];
  dataSource = new MatTableDataSource<ILocaleTax>(this.localeTaxesService.list);

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dialog = inject(MatDialog);

  constructor(
    protected generalService: GeneralService,
    protected localeTaxesService: LocaleTaxesService,
    protected funcionalidadesService: FuncionalidadesService
  ) {
  }

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_INFORMACOES_GERAIS,
    false
  );

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public formatDate(date: string) {
    return formatDateAndTime(date);
  }

  public async startEditing(localeClassData: ILocaleTax) {
    this.localeTaxesService.data.patchValue({
      id: localeClassData.id_localidade_taxa,
      id_localidade: localeClassData.id_localidade,
      // @ts-ignore
      percentual_taxa: localeClassData.percentual_taxa,
      // @ts-ignore
      data_vigencia: new Date(localeClassData.data_vigencia),
    });
  }

  async openRemoveDialog(localeClassData: ILocaleTax) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: this.localeTaxesService.propagar
          ? 'e propagar a exclusão da taxa / vigência de localidade'
          : 'a taxa / vigência de localidade',
        id: localeClassData.id_localidade_taxa,
        name: `${localeClassData.percentual_taxa} - ${toBrazilianDate(localeClassData.data_vigencia)}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          await this.localeTaxesService.delete(removeId, this.localeTaxesService.propagar);

          this.generalService.notify('Taxa / vigência de localidade excluída', 'positive');

          await this.localeTaxesService.getLocaleTaxes();

          this.localeTaxesService.clearForm(true);
        } catch (error) {
          this.generalService.notify('Erro ao excluir taxa / vigência de localidade', 'negative');
        }
      }
    });
  }

  public isFutureLocaleClass(date: string) {
    const localeClassDate = new Date(toUSADate(formatDateAndTime(date)));

    const today = dateWithZeroHour(new Date());

    return localeClassDate >= today;
  }
}
