<form (ngSubmit)="submit()">


  <div class="header-dialog">
    <h2 class="title-dialog" mat-dialog-title>Excluir {{ data.label }}</h2>
    <button type="button" mat-icon-button class="close-button-dialog" (click)="closeDialog()">
      <mat-icon type="button" class="close-icon-dialog">close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div>
      Tem certeza que deseja excluir {{ data.label }}?
    </div>

    <div style="text-align:center;color:red;margin-top:10px">
      {{ data.name }}
    </div>
  </mat-dialog-content>
 
  <mat-dialog-actions align="end" style="padding-bottom: 15px">

    <button type="button" style="margin-right:9px;width: 120px" mat-raised-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>Cancelar
    </button>

    <submit-button label="Excluir" [loading]="removing" [icon]="'check'" style="margin-right: 10px" />

  </mat-dialog-actions>


</form>