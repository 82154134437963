import { Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-generate-calc-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogContent,
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    SubmitButtonComponent,
    IconButton,
    MatIcon
  ],
  templateUrl: './generate-calc-dialog.component.html',
  styleUrl: './generate-calc-dialog.component.scss'
})
export class GenerateCalcDialogComponent {
  title: string;
  question: string;
  warning: string;

  constructor(
    public dialogRef: MatDialogRef<GenerateCalcDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, question: string, warning: string },
  ) {
    this.title = this.data.title;
    this.question = this.data.question;
    this.warning = this.data.warning;
  }

  submit() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
