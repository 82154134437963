<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div *ngIf="loading; else contentTemplate" class="loading-container">
      <mat-spinner diameter="20" class="mat-spinner-black"></mat-spinner>
    </div>
    <ng-template #contentTemplate>
      <div class="content-itens" [style]="heigthCss">
        <mat-selection-list #listCheck>
          <mat-list-option *ngFor="let item of itens;" [color]="'primary'" (click)="selectOption(item.id)"
            [disabled]="readOnly">
            <ng-container *ngIf="chooseReadOnly; else simpleText">
              <div style="display: inline-block; min-width: 430px; font-size: 14px">
                {{ item.name }}
              </div>
              <mat-slide-toggle color="primary" [checked]="item.readOnly" (click)="setReadOnly($event, item.id)"
                [disabled]="readOnly">
                <div *ngIf="item.readOnly; else consulta">Manut. completa</div>
                <ng-template #consulta>Somente consulta</ng-template>
              </mat-slide-toggle>
            </ng-container>
            <ng-template #simpleText>
              <span style="font-size: 14px">{{ item.name }}</span>
            </ng-template>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>