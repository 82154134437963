import {IMove} from "../interfaces/move-interfaces";
import apiService from "./api-service";
import { UsersService } from "./users-service";
import apiCalculateTaxesService from "./api-calculate-taxes-service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import API from "./api-service";

interface IFlightClassification {
  grupo: number,
  tipo_cobranca: string,
  natureza: string,
}

export const A_VISTA = "À Vista";
export const A_POSTERIORI = "A Posteriori";
export const DOMESTICO = "Doméstico";
export const INTERNACIONAL = "Internacional";

export interface IRepasseData {
  cod_repasse: string,
  data_referencia_repasse: string,
  valor_repasse: string,
}

export default class MovesService {
  resource = 'Movimentos';

  public loading = true;
  public list: IMove[] = [];
  public item: IMove | null = null;
  private usersService: UsersService | null = null;

  public selectedMovesLocale: string = '';
  public selectedMoves: number[] = [];
  public newRepasseToBeGenerated: IRepasseData | null = null;
  public refreshCheckboxes = false;

  public injectUsersService(usersService: UsersService) {
    this.usersService = usersService;
  }

  public async classifyFlight(params: {
    icao_localidade: string,
    identificacao: string,
    origem: string,
    destino: string,
    data_decolagem: string,
  }) {
    try {
      const {data}: {
        data: { result: IFlightClassification }
      } = await apiCalculateTaxesService.post('Classificacao', params);

      return Promise.resolve(data.result);
    } catch (error) {
      return Promise.reject({messagens: ["Não foi possível obter a classificação da modalidade tarifária."]});
    }
  }

  public async save(moveData: object, moveId: string | null) {
    try {
      // Convert all values in moveData to uppercase
      const fieldsToUpperCase = ['ORIGEM_ICAO', 'DESTINO_ICAO']; // Substitua pelos nomes reais dos campos

      const upperCaseMoveData = Object.fromEntries(
        Object.entries(moveData).map(([key, value]) =>
          [key, fieldsToUpperCase.includes(key) && typeof value === 'string' ? value.toUpperCase() : value]
        )
      );

      // Update move
      if (moveId) {
        const { data } = await apiService.put(`${this.resource}/${moveId}`, upperCaseMoveData);
        return Promise.resolve(data);
      } else {
        // Create move
        const { data } = await apiService.post(this.resource, upperCaseMoveData);
        return Promise.resolve(data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateMovesRepasseId(codRepasse: string) {
    try {
      const {data} = await API.post(`${this.resource}/Repasse/Definir`, {
        ids_movimentos: this.selectedMoves,
        id_repasse: Number(codRepasse),
      });

      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public resetMovesRepasse() {
    this.newRepasseToBeGenerated = null;
    this.selectedMoves = [];
    this.selectedMovesLocale = '';
  }

  public async getMoves(filterGeneratedDAT = false) {
    if (!this.usersService) return false;

    // DECEA USER
    if (this.usersService.user.DECEA) {
      return await this.getAllMoves(filterGeneratedDAT);
    }

    // SINGLE LOCALE USER
    if (this.usersService.currentLocale && this.usersService.currentLocale.sigla_icao) {
      return await this.getSingleLocaleMoves(this.usersService.currentLocale.sigla_icao, filterGeneratedDAT);
    }

    // MULTIPLE LOCALES USER
    if (this.usersService.currentLocales && this.usersService.currentLocales.length) {
      return await this.getMultipleLocaleMoves(
        this.usersService.currentLocales.map((locale) => locale.sigla_icao),
        filterGeneratedDAT,
      );
    }

    return false;
  }

  public async getAllMoves(filterGeneratedDAT = false) {
    try {
      this.loading = true;

      const {data} = await apiService.get(`${this.resource}?dat=${filterGeneratedDAT}`);

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async getSingleLocaleMoves(icao: string, filterGeneratedDAT = false) {
    try {
      this.loading = true;

      const {data} = await apiService.get(`${this.resource}/Localidade?icao=${icao}&dat=${filterGeneratedDAT}`);

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async getMultipleLocaleMoves(icao: string[], filterGeneratedDAT = false) {
    try {
      this.loading = true;

      const {data} = await apiService.get(`${this.resource}/Localidades?icaos=${icao.join(',')}&dat=${filterGeneratedDAT}`);

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async search(params: { [key: string]: string }) {
    try {
      this.loading = true;

      const query = Object.keys(params).map((key) => (`${key}=${params[key]}`));

      const { data } = await apiService.get(`${this.resource}?${query.join('&')}`);

      this.list = [...data.result];

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    }
  }

  public async getById(moveId: string) {
    try {
      const { data } = await apiService.get(
        `${this.resource}/${moveId}`
      );

      return Promise.resolve(data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }

  public async delete(moveId: number) {
    try {
      const { data } = await apiService.delete(
        `${this.resource}/${moveId}`
      );

      return Promise.resolve(data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }

  public updateSelectedMoves(event: MatCheckboxChange, move: IMove) {
    // selecting an already repassed move (group all of them...)
    if (move.cod_repasse) {
      if (event.checked) {
        this.selectAllMovesFromRepass(move.cod_repasse);
      } else {
        this.deselectedAllMovesFromRepass(move.cod_repasse);
      }

      this.refreshCheckboxes = true;
    } else {
      // set and check locale
      if (this.selectedMovesLocale === '') {
        this.selectedMovesLocale = move.icao;
      } else if (this.selectedMovesLocale !== move.icao) {
        this.selectedMovesLocale = move.icao;
      }

      if (event.checked) {
        if (!this.selectedMoves.includes(Number(move.id_movimento))) {
          this.selectedMoves.push(Number(move.id_movimento));
        }
      } else {
        if (this.selectedMoves.includes(Number(move.id_movimento))) {
          this.selectedMoves.splice(
            this.selectedMoves.indexOf(Number(move.id_movimento)),
            1,
          );
        }
      }

      this.refreshCheckboxes = false;
    }
  }

  private deselectedAllMovesFromRepass(cod_repasse: string) {
    const updatedMovesId: number[] = [];

    for (const moveId of this.selectedMoves) {
      const currentMove = this.list.find((move) => Number(move.id_movimento) === moveId);

      if (currentMove && currentMove.cod_repasse !== cod_repasse) {
        updatedMovesId.push(moveId);
      }
    }

    this.selectedMoves = [...updatedMovesId];
  }

  private selectAllMovesFromRepass(cod_repasse: string) {
    const movesFromRepass = this.list.filter(
      (move) => move.cod_repasse === cod_repasse,
    );

    if (movesFromRepass.length) {
      for (const move of movesFromRepass) {
        const {id_movimento} = move;

        if (id_movimento) {
          this.selectedMoves.push(Number(id_movimento));
        }
      }
    }
  }

  public getRemoveCodRepasse() {
    for (const moveId of this.selectedMoves) {
      const currentMove = this.list.find((move) => Number(move.id_movimento) === moveId);

      if (currentMove && currentMove.cod_repasse) return currentMove.cod_repasse;
    }

    return '';
  }

  public notRepassedMoves() {
    for (const moveId of this.selectedMoves) {
      const currentMove = this.list.find((move) => Number(move.id_movimento) === moveId);

      if (currentMove && !currentMove.cod_repasse) return true;
    }

    return false;
  }

  public hasMoreThanOneRepass() {
    const codRepasses: string[] = [];

    for (const moveId of this.selectedMoves) {
      const currentMove = this.list.find((move) => Number(move.id_movimento) === moveId);

      if (currentMove && currentMove.cod_repasse && !codRepasses.includes(currentMove.cod_repasse)) {
        codRepasses.push(currentMove.cod_repasse);
      }
    }

    return codRepasses.length > 1;
  }

  public getLocaleFromSelectedMoves() {
    const locales: string[] = [];

    for (const move of this.list)
      if (this.selectedMoves.includes(Number(move.id_movimento)))
        if (!locales.includes(move.icao))
          locales.push(move.icao);

    return locales.length === 1 ? locales[0] : null;
  }

  public async hasSelectedMovesAlreadyRepassed() {
    if (this.list.length === 0) await this.getAllMoves(true);

    for (const moveId of this.selectedMoves) {
      const currentMove = this.list.find((move) => Number(move.id_movimento) === moveId);

      if (currentMove && currentMove.cod_repasse) {
        return true;
      }
    }

    return false;
  }

  public async calculateRepass() {
    try {
      const {data}: { data: { result: IRepasseData } } = await apiService.post(
        `${this.resource}/Repasse/Calcular`,
        {ids_movimentos: this.selectedMoves},
      );

      this.newRepasseToBeGenerated = {...data.result};

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
