/**
 * Converts dateString to YYYY/MM/DD
 * @param dateString
 */
export const toISO8601 = (dateString: string) => {
  return fromBRToUSAString(toBrazilianDate(dateString));
}

/**
 * Converts dateString to DD/MM/YYYY
 * @param dateString
 */
export const toBrazilianDate = (dateString: string) => {
  if (!dateString) return '';

  const date = new Date(dateString);

  // with leading zero
  const day = date.getDate().toString().padStart(2, '0');

  // with leading zero
  const month = (date.getMonth() + 1).toString().padStart(2, '0');

  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

/**
 * Converts DD/MM/YYY into MM/DD/YYYY date format.
 * @param brazilianDateString
 */
export const fromBRToUSAString = (brazilianDateString: string) => {
  if (!brazilianDateString) return '';

  const dateParts = brazilianDateString.split("/");

  return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
}

export const toUSADate = (brazilianDateString: string) => {
  const dateParts = brazilianDateString.split("/");

  return new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0], 0, 0, 0);
}

export const dateWithZeroHour = (dateString: string | Date) => {
  const date = new Date(dateString);

  // with leading zero
  const day = date.getDate().toString().padStart(2, '0');

  // with leading zero
  const month = date.getMonth();

  const year = date.getFullYear();

  return new Date(year, month, +day);
}

export const formatDateAndTime = (dateString: string) => {
  const newDate = new Date(dateString);

  const day = (newDate.getDate()).toString().padStart(2, '0');
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const year = newDate.getFullYear();

  return `${day}/${month}/${year}`;
}

export const brazilianDateToTimestamp = (brazilianDateString: string) => {
  const dateParts = brazilianDateString.split("/");

  return new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
}

export const getGreatestDate = (dates: Date[]) => {
  // @ts-ignore
  return new Date(Math.max(...dates));
}

export const formatTime = (dateString: string | Date) => {
  const date = new Date(dateString);

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};
