<mat-grid-list cols="1" rowHeight="80px" gutterSize="15">
  <mat-grid-tile>
    <button
      type="button"
      color="primary"
      mat-raised-button
      (click)="startGeneratingGRU()"
    >
      <div style="display: flex; align-items: center; justify-content: center;">
        @if (generatingGRU) {
          <mat-spinner diameter="20" class="mat-spinner-white" style="margin-right:12px"></mat-spinner> Gerando repasse
        } @else {
          <mat-icon style="font-size:17px;margin-top:7px;margin-left:-5px">cached</mat-icon>
          <span style="margin-left: 5px; height: 21px">Gerar repasse</span>
        }
      </div>
    </button>

    <button
      style="margin-left:15px"
      type="button"
      color="primary"
      mat-raised-button
      (click)="startRemovingGRU()"
    >
      <div style="display: flex; align-items: center; justify-content: center;">
        @if (removingGRU) {
          <mat-spinner diameter="20" class="mat-spinner-white" style="margin-right:12px"></mat-spinner> Excluindo repasse
        } @else {
          <mat-icon style="font-size:17px;margin-top:7px;margin-left:-5px">delete</mat-icon>
          <span style="margin-left: 5px; height: 21px">Excluir repasse</span>
        }
      </div>
    </button>
  </mat-grid-tile>
</mat-grid-list>
