import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { SubmitButtonComponent } from '../../buttons/submit-button/submit-button.component';
import { ClearFilterButtonComponent } from '../../buttons/clear-filter-button/clear-filter-button.component';
import { BooleanRadioComponent } from '../../radios/boolean-radio/boolean-radio.component';
import { SharedModule } from '../../../shared/modules/shared.module';
import { UsersService } from '../../../services/users-service';
import { CreateUserDialog } from '../../dialogs/create-user-dialog/create-user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import { ComboService } from '../../../services/combo-service';

export interface ILocaleClass {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'user-search-bar',
  standalone: true,
  imports: [
    SubmitButtonComponent,
    ClearFilterButtonComponent,
    BooleanRadioComponent,
    SharedModule,
  ],
  templateUrl: './user-search-bar.component.html',
  styleUrl: './user-search-bar.component.scss',
})
export class UserSearchBarComponent {
  constructor(
    protected _comboService: ComboService,
    protected _usersService: UsersService,
    private _dialog: MatDialog,
    private formBuilder: FormBuilder,
    private featuresService: FuncionalidadesService,
    private userService: UsersService
  ) { }

  public isReadonly = !this.featuresService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_USUARIOS,
    false
  );

  protected selectLocale(organization: number) {
    if (organization == 1) this._usersService.searchForm.get('id_localidade')?.enable();
    else {
      this._usersService.searchForm.get('id_localidade')?.patchValue('');
      this._usersService.searchForm.get('id_localidade')?.disable();
    }
  }

  protected async submit() {
    const data: any = this._usersService.searchForm.value;

    if (data.organizacao == '9999') data['decea'] = true;

    if (data.id_localidade) data['localidades'] = [data.id_localidade];

    const params = new URLSearchParams();

    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((value: string) => params.append(key, value));
      } else {
        params.append(key, data[key]);
      }
    }

    await this._usersService.search(params);
  }

  protected showModalNovoUser() {
    this._dialog.open(CreateUserDialog);
  }

  enable() {
    this._usersService.searchForm.get('organizacao')?.patchValue('1');
    this._usersService.searchForm.get('id_localidade')?.enable();
  }
}
