<div style="padding: 15px">
  <repass-search-bar />

  @if (movesServices.loading || _comboService.loading) {
  <div class="full-width" style="display: flex; justify-content: center">
    <mat-spinner diameter="24" />
  </div>
  } @else {
  <grus-table />

  @if(!this.usersService.user.DECEA){
  <grus-actions-buttons-bar />
  } }
</div>
