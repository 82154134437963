import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { environment } from '../../environments/environment';

class API {
  private readonly baseUrl: string;
  private readonly header: any;
  private readonly instance: AxiosInstance;

  constructor() {
    this.baseUrl = environment.apiUrl;
    this.header = { headers: {} as Object };

    this.instance = axios.create({
      baseURL: '',
      headers: {},
    });

    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.instance.interceptors.request.use(
      // @ts-ignore
      (config: AxiosRequestConfig) => {
        const token = JSON.parse(localStorage.getItem('@token_user') ?? '{}');
        if (token?.token) {
          // @ts-ignore
          config.headers['Authorization'] = token?.token;
        }
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }

  public async get(resource: string) {
    return await this.instance.get(`${this.baseUrl}${resource}`, this.header);
  }

  public async getWithParams(resource: string, params: any) {
    return await this.instance.get(`${this.baseUrl}${resource}`, {
      ...this.header,
      params,
    });
  }

  public async post(resource: string, data: object) {
    return await this.instance.post(
      `${this.baseUrl}${resource}`,
      data,
      this.header
    );
  }

  public async put(resource: string, data: object) {
    return await this.instance.put(
      `${this.baseUrl}${resource}`,
      data,
      this.header
    );
  }

  public async delete(resource: string) {
    return await this.instance.delete(
      `${this.baseUrl}${resource}`,
      this.header
    );
  }
}

export default new API();
