<div style="padding:15px">
  <user-search-bar/>

  @if (_userService.loading) {
    <div class="full-width" style="display:flex;justify-content:center">
      <mat-spinner diameter="24"/>
    </div>
  } @else {
    <users-table/>
  }
</div>
