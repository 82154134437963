import { Component, Input } from "@angular/core";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: 'icon-button',
  templateUrl: 'icon-button.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTooltip,
  ],
})

export class IconButton {
  @Input() iconName: string = '';
  @Input() label: string = '';
  @Input() color = 'primary';
  @Input() tooltip: string = '';
  @Input() dataCy = 'primary';
  @Input() btnCssClass = '';
  @Input() iconCssClass = '';
  @Input() disabled = false;
}
