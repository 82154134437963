import apiService from "./api-service";
import {ICheck} from "../interfaces/check-interface";

export interface IGroupFeature {
  id: number,
  idGrupo: number,
  idFuncionalidade: number,
  somenteConsulta: boolean,
}

export class GroupFeaturesService {
  selectedFeatures: ICheck[] = [];

  public async getByGroupId(groupId: number) {
    try {
      const {data} = await apiService.get(`Grupo/${groupId}/Funcionalidades`);

      return Promise.resolve(data['result']['funcionalidades'] as IGroupFeature[]);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async save(groupId: number) {
    try {
      await apiService.post(`Grupo/${groupId}/Funcionalidades`, {
        funcionalidades: this.selectedFeatures.map(
          (feature) => ({
            id_funcionalidade: feature.id,
            somente_consulta: !feature.readOnly,
          }),
        ),
      });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
