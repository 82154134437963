import {Component, inject, ViewChild, AfterViewInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

import {MatDialog} from '@angular/material/dialog';
import {GeneralService} from '../../../services/general-service';
import {MatPaginator} from '@angular/material/paginator';
import {SharedModule} from '../../../shared/modules/shared.module';
import {IGroup} from '../../../interfaces/groups-interfaces';
import {GroupsService} from '../../../services/groups-service';
import {GeneralRemoveDialog} from '../../dialogs/general-remove-dialog/general-remove-dialog.component';
import {GroupDialog} from '../../dialogs/group-dialog/group-dialog.component';
import {Router} from '@angular/router';
import {FuncionalidadesService} from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';
import {UsersService} from '../../../services/users-service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'groups-table',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './groups-table.component.html',
  styleUrl: './groups-table.component.scss',
})
export class GroupsTableComponent implements AfterViewInit {
  dialog = inject(MatDialog);

  displayedColumns: string[] = [
    'nm_grupo',
    'situacao',
    'qtd_usuarios',
    'actions',
  ];

  dataSource = new MatTableDataSource<IGroup>(this._groupsService.list);
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public isReadonly = !this._funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_GRUPOS,
    false
  );

  constructor(
    private _router: Router,
    protected _generalService: GeneralService,
    protected _groupsService: GroupsService,
    protected _funcionalidadesService: FuncionalidadesService,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _userService: UsersService
  ) {
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public editGroup(group: IGroup) {
    this._dialog.open(GroupDialog, {
      data: {group},
    });
  }

  public removeGroup(group: IGroup) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'o grupo',
        id: group.id_grupo,
        name: `${group.nm_grupo}`,
      },
    });
    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          await this._groupsService.delete(removeId);
          this._generalService.notify('Grupo removido', 'positive');
          this.recarregarGrupos()
        } catch (error) {
        }
      }
    });
  }

  public showUsers(group: IGroup) {
    this._router.navigate(['/groups/users'], {
      queryParams: {id_grupo: group.id_grupo},
    });
  }

  async recarregarGrupos() {
    if (!this._userService.user.DECEA) {
      let search = this._formBuilder.group({
        id_usuario: this._userService.user.usuarioId.toString() || '0'
      });
      await this._groupsService.search(search.value);
    } else {
      this._groupsService.getAll();
    }

  }
}
