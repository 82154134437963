import { NgModule } from '@angular/core';

import { MaskPhoneDirective } from '../directives/mask-phone.directive';
import {MaskTimeDirective} from "../directives/mask-time.directive";

@NgModule({
  declarations: [MaskPhoneDirective, MaskTimeDirective],
  exports: [MaskPhoneDirective, MaskTimeDirective],
})
export class DirectivesModule {}
