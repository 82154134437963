<div style="padding: 15px">
  <form [formGroup]="form" class="select-group">
    <mat-form-field class="full-width">
      <mat-label>Grupo</mat-label>
      <mat-select formControlName="group" (selectionChange)="selectedGrupo($event)">
        @for (currentClass of groupsCombo; track currentClass) {
        <mat-option [value]="currentClass.id_grupo">{{
          currentClass.nm_grupo
          }}</mat-option>
        }
      </mat-select>
      <mat-error>Informe o grupo</mat-error>
    </mat-form-field>
  </form>

  @if (_groupsService.loading) {
  <div class="full-width" style="display: flex; justify-content: center; height: 500px">
    <mat-spinner diameter="24" />
  </div>
  } @else {
  <div class="swap">
    @if(!this.isReadonly){
    <div style="min-width: 400px">
      <list-check [title]="'Usuários'" [itens]="listUsers" />
    </div>
    <div class="btns-swap">
      <button mat-mini-fab aria-label="" color="basic" (click)="swap('add')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>

      <button mat-mini-fab aria-label="" color="basic" (click)="swap('remove')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      <button mat-mini-fab aria-label="" color="basic" (click)="swap('add', true)">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>

      <button mat-mini-fab aria-label="" color="basic" (click)="swap('remove', true)">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
    </div>
    <div style="min-width: 400px">
      <list-check [title]="'Usuários do grupo'" [itens]="listUsersGroup" />
    </div>
    }@else {
    <div style="min-width: 880px">
      <list-check [title]="'Usuários do grupo'" [readOnly]="isReadonly" [itens]="listUsersGroup" />
    </div>
    }

  </div>
  @if(!this.isReadonly){
  <div class="container-btns">
    <button type="button" style="width: 120px" (click)="selectedGrupo()" mat-raised-button>
      <mat-icon>close</mat-icon> Cancelar
    </button>

    <button style="min-width: 110px" type="button" color="primary" (click)="saveGroups()" mat-raised-button>
      <mat-icon>save</mat-icon> Salvar
    </button>
  </div>
  }
  }
</div>