import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { GeneralService } from '../../../services/general-service';
import { IAutoCompleteOption } from '../../../interfaces/auto-complete-interfaces';
import { LocalesTarriffsService } from '../../../services/locales-tarriffs-service';
import { SharedModule } from '../../../shared/modules/shared.module';
import { DatepickerOverviewExample } from '../../datepicker/datepicker.component';
import { IconButton } from '../../buttons/icon-button/icon-button.component';
import { ComboService } from '../../../services/combo-service';

@Component({
  selector: 'locale-tariffs-recipient-form',
  standalone: true,
  imports: [DatepickerOverviewExample, SharedModule, IconButton],
  templateUrl: './locale-tariffs-recipient-form.component.html',
  styleUrl: './locale-tariffs-recipient-form.component.scss',
})
export class LocaleTariffsRecipientFormComponent implements OnInit {
  tarifas: IAutoCompleteOption[] = [];
  listEntidadesExternas: IAutoCompleteOption[] = [];

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() taxName = 'Tarifa';
  @Input() readOnly: boolean = false;

  constructor(
    protected generalService: GeneralService,
    protected _comboService: ComboService,
    protected localesTarriffsService: LocalesTarriffsService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    await this.loadTarifas();
  }

  ngAfterViewInit() {
    this.localesTarriffsService.paginatorDestinatarios = this.paginator;
  }

  get formTarifa() {
    return this.localesTarriffsService.formTarifaDestinatario;
  }
  get recipients(): FormArray {
    return this.formTarifa?.get('recipients') as FormArray;
  }

  get listEntidadesExternasUsadas() {
    const entidadesExternasUsed =
      this.recipients.controls
        ?.map((r) => r.get('recipient')?.value)
        ?.filter((r) => r) || [];

    return entidadesExternasUsed;
  }

  get listEntidadesExternasLivres() {
    return this._comboService.entidadesExternas;
  }

  async loadTarifas() {
    this.tarifas = this._comboService.tarifas;

    if (this.tarifas?.length <= 0) {
      await this._comboService.getCombos();
      this.tarifas = this._comboService.tarifas;
    }
  }

  listOptionsEntidadesExterna(index: number) {
    const idItem =
      this.recipients.get(index.toString())?.get('recipient')?.value || 0;

    const used =
      this.listEntidadesExternasUsadas?.filter((e) => e !== idItem) ?? [];

    const options = [
      ...(this.listEntidadesExternasLivres?.filter(
        (e) => !used.includes(e.id)
      ) ?? []),
    ];
    return options;
  }

  addTariffRecipient() {
    this.localesTarriffsService.addRecipient();
  }

  async removeTariffRecipient(index: any) {
    if (index) {
      if (this.recipients.length == 1) {
      }
      return this.recipients.removeAt(index);
    }
    return;
  }

  calcularTotal(): number {
    let total = 0;
    const recipientControls = this.recipients.controls;
    for (let i = 0; i < recipientControls.length; i++) {
      const percentualControl = recipientControls[i].get('percentual');
      if (percentualControl) {
        total += +percentualControl.value;
      }
    }
    return total;
  }

  checkPercentage(control: FormControl) {
    const value = control.value;
    if (value < 0 || value > 100) {
      return { invalidPercentage: true };
    }
    return null;
  }

  getIndexRecipient(recipientControl: AbstractControl): number {
    const recipients = this.localesTarriffsService.formTarifaDestinatario?.get(
      'recipients'
    ) as FormArray;

    const recipientValue = recipientControl.get('recipient')?.value;

    return (
      recipients?.controls
        ?.map((r) => r?.get('recipient')?.value)
        .lastIndexOf(recipientValue) ?? 0
    );
  }

  handlePageEvent(e: PageEvent) {
    this.localesTarriffsService.paginateDestinatarios(
      e.pageIndex * e.pageSize,
      (e.pageIndex + 1) * e.pageSize
    );
  }
}
