import { NgModule } from '@angular/core';

import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { DirectivesModule } from './directives.module';

@NgModule({
  imports: [
    MaterialModule,
    ReactiveFormsModule,
    CommonModule,
    RouterOutlet,
    RouterModule,
    RouterLink,
    DirectivesModule,
  ],
  exports: [
    MaterialModule,
    DirectivesModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    RouterLink,
  ],
})
export class SharedModule {}
