import {AfterViewInit, Component, inject, ViewChild} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from "@angular/material/table";
import {LocaleClassesService} from "../../../services/locale-classes-service";
import {ILocaleClass} from "../../../interfaces/locale-classes-interfaces";
import {IconButton} from "../../buttons/icon-button/icon-button.component";
import {MatPaginator} from "@angular/material/paginator";
import {GeneralService} from "../../../services/general-service";
import {MatCard, MatCardContent} from "@angular/material/card";
import {
  dateWithZeroHour,
  formatDateAndTime, toBrazilianDate,
  toUSADate
} from "../../../services/date-service";
import {GeneralRemoveDialog} from "../../dialogs/general-remove-dialog/general-remove-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatIcon} from "@angular/material/icon";
import { FuncionalidadesService } from '../../../services/funcionalidades-service';
import FuncionalidadesConst from '../../../shared/constants/FuncionalidadesConst';

@Component({
  selector: 'locale-classes-table',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatTable,
    MatHeaderCellDef,
    IconButton,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatPaginator,
    MatCard,
    MatCardContent,
    MatIcon
  ],
  templateUrl: './locale-classes-table.component.html',
  styleUrl: './locale-classes-table.component.scss'
})

export class LocaleClassesTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['arrow', 'id_classe', 'data_vigencia', 'actions'];
  dataSource = new MatTableDataSource<ILocaleClass>(this.localeClassesService.list);

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dialog = inject(MatDialog);

  constructor(
    protected generalService: GeneralService,
    protected localeClassesService: LocaleClassesService,
    protected funcionalidadesService: FuncionalidadesService
  ) {
  }

  public isReadonly = !this.funcionalidadesService.canAccess(
    FuncionalidadesConst.MANUTENCAO_DE_LOCALIDADES_INFORMACOES_GERAIS,
    false
  );

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  public async startEditing(localeClassData: ILocaleClass) {
    this.localeClassesService.data.patchValue({
      id: localeClassData.id_localidade_classe,
      id_localidade: localeClassData.id_localidade,
      // @ts-ignore
      id_classe: localeClassData.id_classe,
      // @ts-ignore
      data_vigencia: new Date(localeClassData.data_vigencia),
    });
  }

  async openRemoveDialog(localeClassData: ILocaleClass) {
    const dialogRef = this.dialog.open(GeneralRemoveDialog, {
      data: {
        label: 'a classe / vigência de localidade',
        id: localeClassData.id_localidade_classe,
        name: `${this.localeClassesService.getClassName(localeClassData.id_classe)} - ${toBrazilianDate(localeClassData.data_vigencia)}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (removeId) => {
      if (removeId) {
        try {
          await this.localeClassesService.delete(removeId);

          this.generalService.notify('Classe / vigência de localidade excluída', 'positive');

          await this.localeClassesService.getLocaleClasses();
        } catch (error) {
          this.generalService.notify('Erro ao excluir classe / vigência de localidade', 'negative');
        }
      }
    });
  }

  public formatDate(date: string) {
    return formatDateAndTime(date);
  }

  public isFutureLocaleClass(date: string) {
    const localeClassDate = new Date(toUSADate(formatDateAndTime(date)));

    const today = dateWithZeroHour(new Date());

    return localeClassDate >= today;
  }
}
