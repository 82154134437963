@if (!dataSource.data.length) {
<mat-card>
  <mat-card-content>Nenhum movimento encontrado.</mat-card-content>
</mat-card>
} @else {
<table mat-table class="mat-elevation-z1 highlight-table table-text-center" [dataSource]="dataSource">
  <ng-container matColumnDef="icao">
    <th mat-header-cell *matHeaderCellDef>Localidade<br />(ICAO)</th>
    <td mat-cell *matCellDef="let move">{{ move.icao }}</td>
  </ng-container>

  <ng-container matColumnDef="identificacao">
    <th mat-header-cell *matHeaderCellDef>Ident.<br />do vôo</th>
    <td mat-cell *matCellDef="let move">{{ move.identificacao }}</td>
  </ng-container>

  <ng-container matColumnDef="dados_da_aeronave">
    <th mat-header-cell *matHeaderCellDef>
      <div>Dados da aeronave</div>

      <table class="move-inside-table-data">
        <tr>
          <td style="min-width:60px !important">
            Matr.
          </td>
          <td>
            Tipo
          </td>
          <td>
            PMD
          </td>
        </tr>
      </table>
    </th>
    <td mat-cell *matCellDef="let move">
      <table class="move-inside-table-data">
        <tr>
          <td style="min-width:60px !important;">
            {{ move.matricula }}
          </td>
          <td>
            {{ move.tipo_aeronave }}
          </td>
          <td>
            {{ move.pmd }}
          </td>
        </tr>
      </table>
    </td>
  </ng-container>

  <ng-container matColumnDef="dados_arr">
    <th mat-header-cell *matHeaderCellDef>
      <div>
        <div>Dados da operação - ARR</div>

        <table class="header-table">
          <tr>
            <td>
              Pouso
            </td>
            <td>
              Hora
            </td>
            <td>
              Origem
            </td>
          </tr>
        </table>
      </div>
    </th>
    <td mat-cell *matCellDef="let move">
      <table class="move-inside-table-data">
        <tr>
          <td>
            {{ formatDate(move.data_pouso) }}
          </td>
          <td>
            {{ move.hora_pouso }}h
          </td>
          <td>
            {{ move.origem_icao }}
          </td>
        </tr>
      </table>
    </td>
  </ng-container>

  <ng-container matColumnDef="dados_dep">
    <th mat-header-cell *matHeaderCellDef>
      <div>
        <div>Dados da operação - DEP</div>

        <table class="header-table">
          <tr>
            <td>
              Decolagem
            </td>
            <td>
              Hora
            </td>
            <td>
              Destino
            </td>
          </tr>
        </table>
      </div>
    </th>
    <td mat-cell *matCellDef="let move">
      <table class="move-inside-table-data">
        <tr>
          <td>
            {{ formatDate(move.data_decolagem) }}
          </td>
          <td>
            {{ move.hora_decolagem }}h
          </td>
          <td>
            {{ move.destino_icao }}
          </td>
        </tr>
      </table>
    </td>
  </ng-container>

  <ng-container matColumnDef="modalidade">
    <th class="light-blue-background" mat-header-cell *matHeaderCellDef>
      <div>
        <div>Modalidade tarifária</div>

        <table class="move-inside-table-data-two">
          <tr>
            <td>
              Grupo
            </td>
            <td>
              Natureza
            </td>
          </tr>
        </table>
      </div>
    </th>
    <td class="light-blue-background" mat-cell *matCellDef="let move">
      <table class="move-inside-table-data-two">
        <tr>
          <td>
            {{ move.grupo ? move.grupo : '' }}
          </td>
          <td>
            {{ move.natureza ? move.natureza : '' }}
          </td>
        </tr>
      </table>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Ações</th>

    <td mat-cell *matCellDef="let move" style="width:320px">
      <div class="td-align-left {{ isSingleLocaleUser ? '' : 'padding-25-porc'}}">
        @if (!isReadonly) {
        @if (isSingleLocaleUser) {
        <div [id]="'loading-' + move.id_movimento" class="action-loading">
          <mat-spinner diameter="20" style="margin-right: 5px;"></mat-spinner>
          Aguarde - Gerando DAT
        </div>

        <div [id]="'action-' + move.id_movimento">
          @if (!move.cod_repasse) {
          <icon-button tooltip="Editar" [disabled]="disabledGeneratedDAt" iconName="edit"
            (click)="startEditing(move)"></icon-button>

          <icon-button tooltip="Deletar" [disabled]="disabledGeneratedDAt" iconName="delete"
            (click)="openRemoveDialog(move)"></icon-button>

          <button class="move-table-action-text-button" style="margin-right: 10px" color="primary" type="button"
            mat-flat-button (click)="generateDAT(move)" [disabled]="disabledGeneratedDAt">
            <mat-icon>cached</mat-icon>
            Gerar DAT
          </button>
          }

          @if (this.isNonZero(move)) {
          <button [disabled]="disabledGeneratedDAt" [style]="{ marginTop: move.cod_repasse ? '0' : '' }"
            class="move-table-action-text-button" color="custom-color" type="button" mat-flat-button
            (click)="readDAT(move)">
            <mat-icon>visibility</mat-icon>
            Ver DAT
          </button>
          }
        </div>
        } @else {
        <icon-button [disabled]="disabledGeneratedDAt" style="margin-right:10px;top: 10px !important;"
          iconName="visibility" tooltip="Visualizar Movimento" (click)="startEditing(move)" />

        @if (this.isNonZero(move)) {
        <button style="height: 30px; width: auto; font-size: 10px; flex-shrink: 0;"
          class="btn-add-group" color="custom-color" type="button" mat-flat-button (click)="readDAT(move)"
          [disabled]="disabledGeneratedDAt">
          <mat-icon>visibility</mat-icon>
          Ver DAT
        </button>
        }

        }

        } @else {
        <icon-button [disabled]="disabledGeneratedDAt" style="margin-right:10px;top: 10px !important;"
          iconName="visibility" tooltip="Visualizar Movimento" (click)="startEditing(move)" />

        @if (this.isNonZero(move)) {
        <button style="height: 20px; width: auto; font-size: 10px; flex-shrink: 0;"
          class="btn-add-group" color="custom-color" type="button" mat-flat-button (click)="readDAT(move)"
          [disabled]="disabledGeneratedDAt">
          <mat-icon>visibility</mat-icon>
          Ver DAT
        </button>
        }
        }
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9" style="padding:15px">Nenhum movimento encontrado</td>
  </tr>
</table>

@if (generalService.itemsPerPage < dataSource.data.length) { <mat-paginator showFirstLastButtons hidePageSize
  [pageSize]="generalService.itemsPerPage"></mat-paginator>
  }
  }

  <dat-arrecadacao-tarifas-pdf></dat-arrecadacao-tarifas-pdf>