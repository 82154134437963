<div class="full-width boolean-radio">
  <mat-radio-group
    class="text-center full-width"
    color="primary"
    [formControl]="externalControl"
  >
    <div class="boolean-radio-label">{{ label }}</div>

    <div>
      <mat-radio-button value="1" (change)="change.emit(1)">{{
        firstOptionLabel
      }}</mat-radio-button>
      <mat-radio-button value="0" (change)="change.emit(0)">{{
        secondOptionLabel
      }}</mat-radio-button>
    </div>
    <mat-error *ngIf="externalControl.errors && externalControl.dirty"
      >Informe uma das opções</mat-error
    >
  </mat-radio-group>
</div>
